// firebaseService.js
import { db, auth } from "./firebase"; // Adjust the import path as needed
import { doc, getDoc } from "firebase/firestore";

// Fetches user profile data
export const getUserProfileData = async () => {
  const user = auth.currentUser;
  if (user) {
    const userDoc = await getDoc(doc(db, "users", user.uid));
    if (userDoc.exists()) {
      return userDoc.data();
    }
  }
  return null;
};

// Fetches user's financial tracker data
export const getUserFinancialData = async () => {
  const user = auth.currentUser;
  if (user) {
    const financialDoc = await getDoc(doc(db, "financialTrackers", user.uid));
    if (financialDoc.exists()) {
      return financialDoc.data();
    }
  }
  return null;
};
