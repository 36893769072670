import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import employment from "../../../assets/header-images/employment.jpg";

function StudentWorkOpportunities() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={employment} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Student Employment Opportunities </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>


      <div id="base-page-introduction-container">
        
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Balancing work and school can be challenging, but finding the right
            work opportunities or side hustles can help you manage your finances
            while gaining valuable experience. These opportunities not only
            provide financial support but also allow you to develop skills that
            will be beneficial in your future career. Below are various options
            you can explore to earn money during your college years.
          </p>
          <p id="base-page-introduction-text-main">
            NOTE: Below is a selection of some of the most impactful work
            opportunities and side hustles to look out for. While this list is
            not exhaustive, it highlights key options that can significantly
            contribute to your professional growth and financial stability.
          </p>
        </div>
      </div>

      <div id="sections-container">
        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Research Opportunities</strong>
            </p>
            <p className="chapter-description">
              Participating in research projects can provide hands-on
              experience and may offer stipends or hourly wages. Check with your
              professors or university research departments for available
              opportunities.
            </p>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Work Study</strong>
            </p>
            <p className="chapter-description">
              Work-study programs allow students to work part-time jobs, often
              on campus, to help pay for college expenses. These positions are
              typically flexible and can be tailored around your class schedule.
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Federal Work-Study Program:</strong> Provides part-time
                jobs for students with financial need, allowing them to earn
                money to help pay education expenses.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>College Corps</strong>
            </p>
            <p className="chapter-description">
              College Corps offers students the chance to engage in community
              service and earn money or educational awards in return. These
              programs not only help with finances but also provide meaningful
              experiences that can enhance your resume.
            </p>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Side Hustles</strong>
            </p>
            <p className="chapter-description">
              Engaging in side hustles can be a flexible way to earn extra money
              while developing new skills. Here are some ideas to consider:
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Selling Digital Products:</strong> Create and sell
                digital products such as e-books, printables, or online courses.
                Platforms like Etsy and Gumroad can help you reach a broad
                audience.
              </li>
              <li className="bullet-point">
                <strong>Dropshipping:</strong> Start an online store without
                holding inventory. Use platforms like Shopify to set up your
                store and partner with suppliers who ship products directly to
                your customers.
              </li>
              <li className="bullet-point">
                <strong>Amazon FBA:</strong> Fulfillment by Amazon (FBA) allows
                you to sell products on Amazon, where Amazon handles storage,
                packaging, and shipping. This requires some initial investment
                but can be profitable.
              </li>
              <li className="bullet-point">
                <strong>TikTok Shop Affiliate Marketing:</strong> Promote
                products on TikTok and earn commissions through affiliate
                marketing. Join affiliate programs and use your social media
                influence to drive sales.
              </li>
              <li className="bullet-point">
                <strong>Airbnb Rental:</strong> Rent out a spare room or
                property on Airbnb. This side hustle requires more initial
                investment but can generate significant income.
              </li>
              <li className="bullet-point">
                <strong>Print On Demand (Clothing Selling):</strong> Design and
                sell custom clothing and merchandise without holding inventory.
                Use platforms like Printful or Teespring to handle printing and
                shipping.
              </li>
              <li className="bullet-point">
                <strong>Flipping Cars:</strong> Buy used cars, fix them up, and
                sell them for a profit. This side hustle requires mechanical
                skills and initial capital.
              </li>
              <li className="bullet-point">
                <strong>Learning to Trade Stocks:</strong> Invest in stocks and
                learn to trade. Use platforms like Robinhood or E*TRADE to start
                trading and grow your investments over time.
              </li>
              <li className="bullet-point">
                <strong>Create Online Content:</strong> Create engaging content
                on popular social media platforms and monetize through brand
                partnerships, sponsored posts, and TikTok’s Creator Fund.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Additional Work Opportunities</strong>
            </p>
            <p className="chapter-description">
              Here are some additional ideas to consider for earning money
              while developing new skills:
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Apple At-Home Advisor:</strong> Work from home providing
                customer support for Apple products. This position offers
                flexible hours and the ability to work remotely.
              </li>
              <li className="bullet-point">
                <strong>Sell Used Items:</strong> Sell items you no longer need
                on platforms like eBay, Facebook Marketplace, or Craigslist.
                This can help you declutter and earn extra cash.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StudentWorkOpportunities;
