import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function PrepareScholarship() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />

        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 2</p>
            <p id = "sub-scholarship-ch-header-text">Preparing for the Scholarship Search</p>
            <p id = "sub-scholarship-ch-text-two">A Strategic Approach</p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            This chapter sets the stage for your scholarship search with
            targeted goals, effective planning techniques, and research
            strategies.
          </p>
        </div>
      </div>

      <div id="whole-section-page-container">
        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Navigating Timelines and Application Periods</strong>
            </p>
            <p className="chapter-description">
              Scholarship applications typically open from August/September
              each year and run until the following June/July, with results
              announced in the summer. However, certain scholarships are
              available year-round. Start researching and deciding on
              scholarships early to maximize your chances. Additionally, use
              the dedicated Scholarship Tracker to keep track of deadlines
              and requirements.
            </p>
            <p className="chapter-description">
              <strong>Tip:</strong> Begin your search at least six months in
              advance to ensure you have ample time to prepare strong
              applications.
            </p>
            <p className="chapter-description">
              <strong>Resource:</strong> Make a copy of this Google Sheet{" "}
              <a
                className="link"
                href="https://docs.google.com/spreadsheets/d/1_g5FWjag24UN9Fp7Jz3AbrUYVP7XKn71su9fCK5-pKM/edit?usp=sharing"
              >
                Scholarship Tracker
              </a>{" "}
              to log deadlines, requirements, and progress.
            </p>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Effective Scholarship Research Strategies</strong>
            </p>
            <p className="chapter-description">
              <strong>Prioritize Local Opportunities:</strong> Maximize your
              chance of winning by applying to local scholarships before
              delving into national searches. Here's why:
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Reduced Competition:</strong> Local scholarships
                often have fewer applicants compared to national ones,
                increasing your odds of success.
              </li>
              <li className="bullet-point">
                <strong>Unique Opportunities:</strong> Many local
                organizations offer scholarships specific to your community
                or region, catering to under-represented demographics or
                niche interests.
              </li>
              <li className="bullet-point">
                <strong>Stronger Applications:</strong> Local scholarships
                might align better with your background and experiences,
                allowing you to craft more compelling applications.
              </li>
            </ul>
            <p className="chapter-description">
              <strong>How to Find Local Scholarships:</strong>
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                Seek out scholarships offered directly by your school and
                inquire with your counselor or self-research for additional
                opportunities funded by your local organizations.
              </li>
              <li className="bullet-point">
                If you are in/near a major city, there is probably a
                specific community foundation that manages many
                scholarships.
              </li>
              <li className="bullet-point">
                Check this website:{" "}
                <a
                  href="https://www.communityfoundationlocator.com"
                  className="link"
                >
                  Community Foundation Locator
                </a>
              </li>
              <li className="bullet-point">
                Search “[My City/State] Scholarships” and look through the
                first 5-10 pages to see what comes up.
              </li>
              <li className="bullet-point">
                If you are religious, check with your pastor and the
                local/state/national governing bodies of your religion.
              </li>
              <li className="bullet-point">
                Check with the bosses and websites of your and your parents'
                workplaces.
              </li>
              <li className="bullet-point">
                See if the local library has resources.
              </li>
              <li className="bullet-point">
                Once you begin college, check with your professors and your
                major's department office.
              </li>
              <li className="bullet-point">
                If you attend a research university, grants for independent
                research projects are another good way to get money for
                school - check with the Honors College, Office of
                Undergraduate Research, or similar offices.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Scholarship Websites</strong>
            </p>
            <p className="chapter-description">
              Once you've explored local options, expand your search to
              national scholarships and online resources. Here are some
              reputable websites to get you started:
            </p>

            <ul className="bullet-point-list">
              <li className="bullet-point">
                <a href="https://www.scholarshipamerica.org" className="link">
                  Scholarship America
                </a>
              </li>
              <li className="bullet-point">
                <a href="https://www.mykaleidoscope.com" className="link">
                  Kaleidoscope
                </a>
              </li>
              <li className="bullet-point">
                <a
                  href="https://bigfuture.collegeboard.org/scholarship-search"
                  className="link"
                >
                  College Board Scholarship Website
                </a>
              </li>
              <li className="bullet-point">
                <a
                  href="https://docs.google.com/spreadsheets/d/1_g5FWjag24UN9Fp7Jz3AbrUYVP7XKn71su9fCK5-pKM/edit?usp=sharing"
                  className="link"
                >
                  CollegeNav’s Top Choices Spreadsheet
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrepareScholarship;
