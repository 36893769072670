import React from "react";
import { Link } from "react-router-dom";
import "../../css/MainPage/TableOfContentPages.css";

import headerpic from "../../assets/header-images/headerpic.png";
import life1 from "../../assets/header-images/life1.jpg";


import preparing from "../../assets/header-images/preparing.jpg";
import essentialtips from "../../assets/header-images/essentialtips.jpg";
import maximizing from "../../assets/header-images/maximizing.jpg";
import gpas from "../../assets/header-images/gpa.jpg";
import steminternsihp from "../../assets/header-images/steminternsihp.jpg";
import freshmen from "../../assets/header-images/freshmen.jpg";
import resume from "../../assets/header-images/resume.jpg";

const College = () => {
  return (
    <div className="general-page">
      <div id="life-TOC-face">
        <img id="life-TOC-face-pic" src={life1} />
        <div id="TOC-face-text-container">
          <div id="TOC-text-new-face">
            <p className="TOC-header-text">College Life</p>
          </div>
        </div>
      </div>

      <div className="below-header-container">

        <div id="base-page-introduction-container">
          <div id="base-page-introduction-text-container">
            <div id="base-page-introduction-title-container">
              <h2 id="base-page-intro-title">Quick Guide</h2>
              
            </div>
            <p id="base-page-introduction-text-main">
              This page is designed to provide you with comprehensive information
              and resources to help you navigate your college experience. From
              preparing for college and excelling in your studies to utilizing
              campus benefits and securing internships, you'll find valuable
              insights and tools to support your academic and professional journey.
              Click on each category to explore detailed information and learn how
              you can benefit from these resources.
            </p>
          </div>
        </div>

        <div className="general-toc-container">
          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={preparing}
                alt="Preparing for College Life"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 1</h3>
              <h2 className="traffic-title">Preparing for College Life</h2>
              <p className="traffic-description">
                Guidance on preparing for college during the summer. Learn tips on
                joining online communities, understanding major requirements,
                exploring clubs, contacting roommates, and preparing essential
                items to ensure a smooth transition to college life.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed asdfasdf adsfasd fasdfa</span>
              <div className="service-item">
                <Link to="/college/before-college">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={essentialtips}
                alt="Essential College Tips"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 2</h3>
              <h2 className="traffic-title">Essential College Tips</h2>
              <p className="traffic-description">
                Get advice on excelling in school, managing time effectively,
                utilizing academic resources, setting SMART goals, maintaining a
                good GPA, and planning your schedule. These tips are designed to
                help you succeed academically and balance your college life.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed adsfasdf asdfasdf asdfasf</span>
              <div className="service-item">
                <Link to="/college/college-tips">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={maximizing}
                alt="Maximizing School Benefits"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 3</h3>
              <h2 className="traffic-title">Maximizing School Benefits</h2>
              <p className="traffic-description">
                Overview of the various benefits and resources available on
                campus. This includes campus facilities like libraries and sports
                centers, student services such as academic advising and
                counseling, health and wellness resources, housing options,
                diversity and inclusion initiatives, and financial perks available
                to students.
              </p>
              <div className="service-item">
                <Link to="/college/school-benefits">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={gpas}
                alt="GPA Calculator"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 4</h3>
              <h2 className="traffic-title">GPA Calculator</h2>
              <p className="traffic-description">
                A handy tool for calculating your GPA. Includes detailed
                instructions on how to input your grades and credits to accurately
                determine your grade point average, helping you stay on top of
                your academic performance.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing eddddddddddddddddddddddddddddddddddddddlit, sed</span>
              <div className="service-item">
                <Link to="/tools/gpa-calculator">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={steminternsihp}
                alt="STEM Internships Opportunities"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 5</h3>
              <h2 className="traffic-title">STEM Internships Opportunities</h2>
              <p className="traffic-description">
                A comprehensive guide to securing internships. This includes a
                step-by-step checklist, details on the application process, tips
                for crafting a standout resume and LinkedIn profile, strategies
                for effective internship searching, the importance of referrals,
                interview preparation tips, and methods for tracking your
                applications.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consepor. </span>
              <div className="service-item">
                <Link to="/college/stem-internships">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={freshmen}
                alt="Freshman and Sophomore Programs"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 6</h3>
              <h2 className="traffic-title">Freshman and Sophomore Programs</h2>
              <p className="traffic-description">
                Discover programs specifically designed for freshmen and sophomore
                students to help them build a strong foundation in their academic
                and professional journeys. These programs provide early exposure
                to research, industry practices, and professional development
                opportunities.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed</span>
              <div className="service-item">
                <Link to="/programs/freshmen-sophomore-programs">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={resume}
                alt="Crafting Your Resume"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 7</h3>
              <h2 className="traffic-title">Crafting Your Resume</h2>
              <p className="traffic-description">
                Building a strong resume is key to securing internships, scholarships, and jobs. This page offers templates, tips, and action verbs to help you create a standout resume. Highlight your skills and achievements clearly to make a strong first impression.
              </p>
              <div className="service-item">
                <Link to="/college/resume">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default College;
