import React, { useEffect, useState } from "react";
import { database } from "../../firebase/firebase";
import { ref, onValue, push, set } from "firebase/database";
import "../../css/components/comments/CommentList.css";
import CommentForm from "./CommentForm";

/**
 * CommentList Component
 * Displays a list of comments and allows users to reply to comments.
 */
const CommentList = ({ path }) => {
  const [comments, setComments] = useState([]); // Comments state
  const [replyingTo, setReplyingTo] = useState(null); // State for comment being replied to

  useEffect(() => {
    const commentsRef = ref(database, `comments/${path}`);
    onValue(commentsRef, (snapshot) => {
      const data = snapshot.val();
      const commentList = [];
      for (let key in data) {
        commentList.push({ id: key, ...data[key] }); // Populate comment list
      }
      setComments(commentList); // Set comments state
    });
  }, [path]);

  // Handle reply button click
  const handleReply = (commentId) => {
    setReplyingTo(commentId); // Set replying to commentId
  };

  // Handle reply submission
  const handleReplySubmit = (commentId, name, email, comment) => {
    const newCommentKey = push(ref(database, `comments/${path}/${commentId}/replies`)).key;
    set(ref(database, `comments/${path}/${commentId}/replies/${newCommentKey}`), {
      name,
      email,
      comment,
      timestamp: new Date().toISOString(), // Set timestamp
    });
    setReplyingTo(null); // Reset replying state
  };

  return (
    <ol className="comment-list">
      {comments.map((comment) => (
        <li key={comment.id} className="comment">
          <article className="comment__wrapper">
            <div className="comment__body">
              <header className="c-meta">
                <div className="comment__author">
                  <b className="fn">{comment.name}</b>{" "}
                  <span className="comment__date">
                    {new Date(comment.timestamp).toLocaleString()}
                  </span>
                </div>
              </header>
              <div className="comment__content">
                <p>{comment.comment}</p>
              </div>
              <div className="comment__actions">
                <button
                  className="reply-btn"
                  onClick={() => handleReply(comment.id)} // Set replying to comment.id
                >
                  Reply
                </button>
              </div>
              {replyingTo === comment.id && (
                <CommentForm
                  parentCommentId={comment.id}
                  onReplySubmit={handleReplySubmit} // Pass handleReplySubmit to CommentForm
                  path={path}
                />
              )}
              {comment.replies && (
                <ol className="replies">
                  {Object.keys(comment.replies).map((replyId) => (
                    <li key={replyId} className="comment">
                      <article className="comment__wrapper">
                        <div className="comment__body">
                          <header className="c-meta">
                            <div className="comment__author">
                              <b className="fn">
                                {comment.replies[replyId].name}
                              </b>{" "}
                              <span className="comment__date">
                                {new Date(
                                  comment.replies[replyId].timestamp
                                ).toLocaleString()}
                              </span>
                            </div>
                          </header>
                          <div className="comment__content">
                            <p>{comment.replies[replyId].comment}</p>
                          </div>
                        </div>
                      </article>
                    </li>
                  ))}
                </ol>
              )}
            </div>
          </article>
        </li>
      ))}
    </ol>
  );
};

export default CommentList;
