import React from "react";
import "../css/components/PrivacyPolicy&TermsOfUse.css";

import headerpic from "../assets/header-images/headerpic.png";
import privacy from "../assets/header-images/privacy.jpg";

const PrivacyPolicy = () => {
  const lastUpdated = "August 2, 2024"; // Update this date as needed

  return (
    <>
    <div class = "privacy-background-page">
       <div id="Ch-face">
        <img id="header-image" src={privacy} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Privacy Policy </p>
          </div>
        </div>


      </div>

      <div className="privacy-policy-container">


        <h1 className="privacy-policy-title">Privacy Policy</h1>

        <p className="privacy-policy-date">Last Updated: {lastUpdated}</p>
        <p className="privacy-policy-intro">
          At CollegeNav, we value your privacy and are committed to protecting your personal information.
          This Privacy Policy outlines how we collect, use, and safeguard your data. By using our website, you agree to the terms of this policy. If you have any questions, please contact us at{" "}
          <a href="mailto:collegenav.info@gmail.com" className="privacy-policy-email">
            collegenav.info@gmail.com
          </a>.
        </p>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">1. Information We Collect</h2>
          <ul className="privacy-policy-list">
            <li className="privacy-policy-item">
              <strong>Personal Information:</strong> When you sign up, we collect personal information such as your name, email address, password, date of birth, and any other information you choose to provide.
            </li>
            <li className="privacy-policy-item">
              <strong>Usage Data:</strong> We collect information on how you interact with our website, including IP addresses, browser types, access times, pages viewed, and referring URLs.
            </li>
            <li className="privacy-policy-item">
              <strong>Cookies:</strong> We use cookies and similar tracking technologies to enhance your experience and gather information about your use of our website.
            </li>
            <li className="privacy-policy-item">
              <strong>Device Information:</strong> We collect information about the device you use to access our website, including hardware model, operating system, and mobile network information.
            </li>
          </ul>
        </section>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">2. How We Use Your Information</h2>
          <ul className="privacy-policy-list">
            <li className="privacy-policy-item">
              <strong>To Provide and Improve Our Services:</strong> We use your information to operate, maintain, and improve the functionality of CollegeNav.
            </li>
            <li className="privacy-policy-item">
              <strong>To Personalize Your Experience:</strong> We use your information to tailor the content and services to your preferences and needs.
            </li>
            <li className="privacy-policy-item">
              <strong>To Communicate with You:</strong> We use your email to send important updates, newsletters, and promotional materials. You can opt-out at any time.
            </li>
            <li className="privacy-policy-item">
              <strong>To Ensure Security:</strong> We use your data to monitor and protect the security of our platform and users.
            </li>
            <li className="privacy-policy-item">
              <strong>To Analyze Usage:</strong> We use usage data to understand how our users interact with CollegeNav and to improve our services.
            </li>
          </ul>
        </section>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">3. Legal Basis for Processing</h2>
          <p className="privacy-policy-text">
            We process your personal information based on the following legal bases:
          </p>
          <ul className="privacy-policy-list">
            <li className="privacy-policy-item">
              <strong>Consent:</strong> By using our services, you consent to the processing of your personal information as described in this Privacy Policy.
            </li>
            <li className="privacy-policy-item">
              <strong>Contractual Necessity:</strong> We process your personal information to fulfill our contract with you.
            </li>
            <li className="privacy-policy-item">
              <strong>Legal Obligations:</strong> We process your personal information to comply with legal obligations.
            </li>
            <li className="privacy-policy-item">
              <strong>Legitimate Interests:</strong> We process your personal information to pursue our legitimate interests, such as improving our services and ensuring security.
            </li>
          </ul>
        </section>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">4. How We Share Your Information</h2>
          <ul className="privacy-policy-list">
            <li className="privacy-policy-item">
              <strong>Third-Party Service Providers:</strong> We may share your information with trusted third parties to perform services on our behalf, such as hosting, data analysis, and payment processing.
            </li>
            <li className="privacy-policy-item">
              <strong>Legal Requirements:</strong> We may disclose your information if required by law or to protect our rights and users.
            </li>
            <li className="privacy-policy-item">
              <strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your information may be transferred to the new entity.
            </li>
            <li className="privacy-policy-item">
              <strong>With Your Consent:</strong> We may share your information with third parties when we have your consent to do so.
            </li>
          </ul>
        </section>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">5. International Data Transfers</h2>
          <p className="privacy-policy-text">
            Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including personal information, to the United States and process it there.
          </p>
        </section>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">6. Your Choices and Rights</h2>
          <ul className="privacy-policy-list">
            <li className="privacy-policy-item">
              <strong>Access and Correction:</strong> You can access and update your personal information through your account settings.
            </li>
            <li className="privacy-policy-item">
              <strong>Data Retention:</strong> We retain your information as long as necessary to provide our services and comply with legal obligations.
            </li>
            <li className="privacy-policy-item">
              <strong>Opt-Out:</strong> You can opt-out of receiving promotional emails by following the unsubscribe instructions in those emails.
            </li>
            <li className="privacy-policy-item">
              <strong>Deletion:</strong> You can request the deletion of your personal information by contacting us at{" "}
              <a href="mailto:collegenav.info@gmail.com" className="privacy-policy-email">
                collegenav.info@gmail.com
              </a>.
            </li>
            <li className="privacy-policy-item">
              <strong>Restriction:</strong> You have the right to request the restriction of the processing of your personal information under certain circumstances.
            </li>
            <li className="privacy-policy-item">
              <strong>Objection:</strong> You have the right to object to the processing of your personal information based on legitimate interests.
            </li>
            <li className="privacy-policy-item">
              <strong>Data Portability:</strong> You have the right to request a copy of your personal information in a structured, machine-readable format.
            </li>
          </ul>
        </section>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">7. Security</h2>
          <p className="privacy-policy-text">
            We implement appropriate security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
          </p>
        </section>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">8. Children's Privacy</h2>
          <p className="privacy-policy-text">
            Our services are not directed to individuals under 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.
          </p>
        </section>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">9. Changes to This Privacy Policy</h2>
          <p className="privacy-policy-text">
            We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website. Your continued use of our services after any changes indicates your acceptance of the updated Privacy Policy.
          </p>
        </section>

        <section className="privacy-policy-section">
          <h2 className="privacy-policy-heading">10. Contact Us</h2>
          <p className="privacy-policy-text">
            If you have any questions or concerns about this Privacy Policy, please contact us at{" "}
            <a href="mailto:collegenav.info@gmail.com" className="privacy-policy-email">
              collegenav.info@gmail.com
            </a>.
          </p>
        </section>
      </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
