import React from "react";
import { Link } from "react-router-dom";

import "../../css/MainPage/TableOfContentPages.css";
import "../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../assets/header-images/headerpic.png";
import prep1 from "../../assets/header-images/prep1.jpg";

import Roadmap1 from "../../assets/header-images/Roadmap1.jpg";
import Paths1 from "../../assets/header-images/Paths1.jpg";
import major1 from "../../assets/header-images/major1.jpg";
import collegesearch1 from "../../assets/header-images/collegesearch1.jpg";
import hsplanning from "../../assets/header-images/hsplanning.jpg";
import ccpathway from "../../assets/header-images/ccpathway.jpg";

import achievements from "../../assets/header-images/achievements.jpg";
import navigating1 from "../../assets/header-images/navigating1.jpg";
import essaycraft1 from "../../assets/header-images/essaycraft1.jpg";
import recletter from "../../assets/header-images/recletter.jpg";
import international from "../../assets/header-images/international.jpg";

import afford1 from "../../assets/header-images/afford1.jpg";

const CollegePrep = () => {
  return (
    <div className="general-page">
      
      <div id="afford-TOC-face">
        <img id="prep-TOC-face-pic" src={prep1} />
        <div id="TOC-face-text-container">
          <div id="TOC-text-new-face">
            <p className="TOC-header-text">College Prep</p>
            
          </div>
        </div>
      </div>

      <div className="below-header-container">
        
        <div id="base-page-introduction-container">
          <div id="base-page-introduction-text-container">
            <div id="base-page-introduction-title-container">
              <h2 id="base-page-intro-title">Quick Guide</h2>
              
            </div>
            <p id="base-page-introduction-text-main">
              This section provides a comprehensive guide to help you prepare
              for college through various pathways. Whether you're a high school
              student, a community college student, or exploring different
              majors and careers, you'll find valuable information and resources
              here to support your journey. Click on each category to explore
              detailed information and take advantage of the tools and advice
              provided.
            </p>
          </div>
        </div>

        <div className="general-toc-container">
          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={Roadmap1} alt="High School Road Map" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 1</h3>
              <h2 className="traffic-title">High School Road Map</h2>
              <p className="traffic-description">
                Guidance and resources for high school students preparing for
                college. Includes steps to succeed in high school, specific
                scholarships, extracurricular activities, AP courses, direct
                college applications, academic support, test prep, college
                planning, course selection, summer programs, and time management
                & study skills.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor s, sed do eiusmod tempor. </span>
              <div className="service-item">
                <Link to="/college-prep/highschool-roadmap">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={Paths1} alt="Exploring Different Paths" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 2</h3>
              <h2 className="traffic-title">Exploring Different Paths</h2>
              <p className="traffic-description">
                Explore various pathways to college, including direct
                applications from high school, starting at a community college,
                and more. Learn about the benefits and challenges of each path
                to make an informed decision.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum , sed do eiusmod tempor. sdfsdfsdfsdfdsfssdfdsfssdfdsfsdf adfadsf adsfdasf asdfasf </span>
              <div className="service-item">
                <Link to="/college-prep/different-paths">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={major1} alt="Choosing a Major" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 3</h3>
              <h2 className="traffic-title">Choosing a Major</h2>
              <p className="traffic-description">
                Tools and resources to help you choose and explore majors.
                Includes a major quiz, exploration of various majors, career
                paths, coursework overview, and guidance on how to choose the
                correct major.
              </p>
              <span class = "hidden-box-text"> Loresed do eifsdfsdfdsfssdfdsfssdfdsfsdfasdfasdfasdfas asdf adsfasdf dafa  asdf asdf asd fasdf as asdf asdfasdf asdfasdf</span>
              <div className="service-item">
                <Link to="/college-prep/majors">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={collegesearch1} alt="College Search" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 4</h3>
              <h2 className="traffic-title">College Search</h2>
              <p className="traffic-description">
                Information on how to search for colleges, including comparisons
                between in-state vs out-of-state and public vs private
                institutions.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. sdfsdfsdfsdfdsfssdfdsfssdfdsfsdfdddasdfaasdfasdfasdfasdfasfasdfasdfaasdfasdfasdfasdfadsfasdfasdfdasdfasdfasdfasdfasdfasdfasdfasdfadddddddddddddddddddddddddddddddasdfasdfdasf. asdfasdfasdfasdfadsf asdfasdfasf asdfadsfasf adsfasfdd asdfas asdfdasf </span>
              <div className="service-item">
                <Link to="/college-prep/college-search-guide">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={hsplanning} alt="Highschool Planning" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 5</h3>
              <h2 className="traffic-title">Highschool Planning</h2>
              <p className="traffic-description">
                Guidance and resources for high school students preparing for
                college. Includes steps to succeed in high school, specific
                scholarships, extracurricular activities, AP courses, direct
                college applications, academic support, test prep, college
                planning, course selection, summer programs, and time management
                & study skills.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur sfdsfsdf. </span>
              <div className="service-item">
                <Link to="/college-prep/high-school">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={ccpathway} alt="Community College Pathways" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 6</h3>
              <h2 className="traffic-title">Community College Pathways</h2>
              <p className="traffic-description">
                Comprehensive guide for community college students planning to
                transfer to a four-year institution. Includes steps to succeed
                in community college, specific scholarships, extracurricular
                activities, transfer applications, transfer statistics, and
                benefits of starting at a community college.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur sfssdfdsfsdf asdfdas asdfasdf</span>
              <div className="service-item">
                <Link to="/college-prep/community-college">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={achievements} alt="Activities and Achievements" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 7</h3>
              <h2 className="traffic-title">Activities and Achievements</h2>
              <p className="traffic-description">
                Showcasing your activities and achievements is key to a strong
                college application. This chapter guides you on effectively
                highlighting your extracurriculars, leadership roles, and
                accomplishments to give admissions a clear view of your
                potential.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing esdf</span>
              <div className="service-item">
                <Link to="/college-prep/activities-and-achievements">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={navigating1} alt="Navigating the Application Process" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 8</h3>
              <h2 className="traffic-title">Navigating the Application Process</h2>
              <p className="traffic-description">
                Tips and guidance on the college application process. Includes
                deadlines, requirements, application tips, admission tests (SAT,
                ACT), types of applications, essay writing steps, and financial
                aid & scholarship guidance.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing esdf</span>
              <div className="service-item">
                <Link to="/college-prep/application-process">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={essaycraft1} alt="Crafting Your College Essay" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 9</h3>
              <h2 className="traffic-title">Crafting Your College Essay</h2>
              <p className="traffic-description">
                Detailed steps for writing college application essays, including
                UC and Common App essays. Tips on drafting, revising, and
                finalizing essays, as well as reusing scholarship essays.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed afdadsfasf asdfasdf asdfasf adfasdf asdfas asdfasd asdfadsf</span>
              <div className="service-item">
                <Link to="/college-prep/college-essay">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={recletter} alt="Securing Letters of Recommendation" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 10</h3>
              <h2 className="traffic-title">Securing Letters of Recommendation</h2>
              <p className="traffic-description">
                Guidance on requesting and securing strong letters of
                recommendation. Includes tips on whom to ask, how to ask, and
                how to ensure the letters support your college application.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing esdf</span>
              <div className="service-item">
                <Link to="/college-prep/letters-of-recommendation">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={international} alt="Guidance for International Students" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 11</h3>
              <h2 className="traffic-title">Guidance for International Students</h2>
              <p className="traffic-description">
                Resources and advice for international students applying to
                colleges in the US. Includes information on visa requirements,
                language tests, application processes, and cultural adaptation.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing esdf</span>
              <div className="service-item">
                <Link to="/college-prep/international-students">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={afford1} alt="Afford College" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 12</h3>
              <h2 className="traffic-title">Afford College</h2>
              <p className="traffic-description">
                Guidance on making college affordable. Includes information on
                financial aid, scholarships, work-study programs, and budgeting
                tips to manage college expenses.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed</span>
              <div className="service-item">
                <Link to="/afford-college">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default CollegePrep;
