import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import actionVerbsImage from "../../../assets/inner-content-images-and-files/actionVerbs.png";

import resume from "../../../assets/header-images/resume.jpg";

import resumeGuide from "../../../assets/inner-content-images-and-files/ResumeTemplate.pdf";
import "../../../css/SubPage/SubPage.css";

function ResumeTips() {
  return (
    <div id="newest-background">
      {/* Header Section */}
      <div id="Ch-face">
        <img id="header-image" src={resume} alt="Header" />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Crafting Your Resume </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      {/* Introduction Section */}
      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Creating a compelling resume is crucial for landing internships,
            scholarships, and job opportunities. This page provides templates,
            tips, and action verbs to help you build a standout resume. Your
            resume is often the first impression you make on potential
            employers, so it's important to make it count. Highlight your
            skills, experiences, and achievements in a clear and concise manner
            to stand out from the competition.
          </p>
        </div>
      </div>

      {/* Sections Container */}
      <div id="sections-container">

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Resume Resources</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Resume Template:</strong> Use 
                <a
                  href="https://www.overleaf.com/latex/templates/jakes-resume/syzfjbzwjncs"
                  class="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Jake's Resume Template
                </a>
                , a popular LaTeX template known for its clean, professional look and ease of customization.
              </li>
              <li class="bullet-point">
                <strong>In-depth Resume Guide:</strong> Download the comprehensive resume guide 
                <a
                  href={resumeGuide}
                  class="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                , which provides in-depth tips and examples to help you craft the perfect resume.
              </li>
              <li class="bullet-point">
                <strong>Google's X-Y-Z Formula:</strong> Follow 
                <a
                  href="https://www.inc.com/bill-murphy-jr/google-recruiters-say-these-5-resume-tips-including-x-y-z-formula-will-improve-your-odds-of-getting-hired-at-google.html"
                  class="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Resume Tips
                </a>
                , which provides insights directly from Google recruiters on how to structure your resume to maximize impact. The X-Y-Z formula is particularly useful: "Accomplished [X] as measured by [Y], by doing [Z]".
              </li>
              <li class="bullet-point">
                <strong>Reddit /resume:</strong> Follow 
                <a
                  href="https://www.reddit.com/r/resumes/"
                  class="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
                which has tons of information and other people's resume samples. Additionally, if you want, you can post your own resume to receive more advice from others.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Resume Sections in Order</strong>
            </p>
            <p class="chapter-description">
              Follow these steps to build a professional and effective resume:
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Contact Information:</strong> Include your full name, phone number, email address, and LinkedIn profile. (If you are a STEM major, add your GitHub as well)
              </li>
              <li class="bullet-point">
                <strong>Education:</strong> Include your highest degree first, followed by other relevant educational experiences. (Put GPA if 3.0 or higher, add clubs/programs participated in)
              </li>
              <li class="bullet-point">
                <strong>Work Experience:</strong> List your work experience in reverse chronological order, starting with your most recent job. Use bullet points to describe your responsibilities and achievements.
              </li>
              <li class="bullet-point">
                <strong>Skills:</strong> List both technical and soft skills that are relevant to the job you are applying for.
              </li>
              <li class="bullet-point">
                <strong>Projects:</strong> Highlight significant projects you have worked on, especially those that are relevant to the job you are applying for.
              </li>
              <li class="bullet-point">
                <strong>Certifications / Achievements:</strong> Include any relevant certifications or awards you have obtained.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Additional Tips for Resume Crafting</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Include metrics (e.g., 30% increase in ...). Use quantifiable achievements.
              </li>
              <li class="bullet-point">
                Avoid job gaps by listing your last job as "CURRENT."
              </li>
              <li class="bullet-point">
                Do not include a picture. Use consistent punctuation throughout.
              </li>
              <li class="bullet-point">
                Ensure there are no grammar or spelling mistakes. Keep bullet point sentences concise, in the range of 3-5 bullet points each.
              </li>
              <li class="bullet-point">
                Sell your experience instead of summarizing it. Use action words; avoid fluffy language.
              </li>
              <li class="bullet-point">
                Copy and analyze job requirements to identify the most frequently used keywords. Maintain a strategic level of transparency, similar to what companies do.
              </li>
              <li class="bullet-point">
                Allow potential employers to contact your last employer.
              </li>
              <li class="bullet-point">
                Use a job title that reflects your responsibilities (e.g., Full Stack Engineer) instead of your official title if it better represents your role.
              </li>
              <li class="bullet-point">
                Ensure the text is not too small or challenging to read. Bold keywords such as skills or tools used (e.g., 
                <strong>React.js</strong>, <strong>Python</strong>).
              </li>
              <li class="bullet-point">
                Use bullet points to highlight achievements with action verbs. Do not list skills or tools you are not proficient in.
              </li>
              <li class="bullet-point">
                Save your resume as FirstNameLastName.pdf, not resume.pdf.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Action Verbs for Resumes</strong>
            </p>
            <img
              id="timeline"
              src={actionVerbsImage}
              alt="Action Verbs for Resumes"
            />
            <p class="chapter-description">
              Use action verbs at the beginning of each statement describing your experiences to create a more dynamic and impactful resume. Action verbs convey confidence and a proactive attitude. Here are some categories of action verbs to consider:
            </p>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Common Resume Edits</strong>
            </p>
            <p class="chapter-description">
              When editing your resume, use these suggestions to replace common phrases with stronger, more impactful language:
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Worked on → spearheaded / facilitated
              </li>
              <li class="bullet-point">Did → Completed / Achieved</li>
              <li class="bullet-point">Got → earned / produced</li>
              <li class="bullet-point">Helped → enabled / empowered</li>
              <li class="bullet-point">Worked with → partnered with</li>
              <li class="bullet-point">
                Made → Created / designed / conceptualized
              </li>
              <li class="bullet-point">Found → Identified</li>
              <li class="bullet-point">Fixed → Restructured</li>
              <li class="bullet-point">Changed → Transformed</li>
            </ul>
          </div>
        </div>

        {/* Conclusion Section */}

{/*

        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              A well-crafted resume can significantly enhance your chances of
              securing valuable opportunities. Use the resources and tips
              provided here to create a strong resume that highlights your
              skills and experiences. Remember, your resume is a living document
              that should evolve as you gain more experience and skills.
              Regularly update it to reflect your latest accomplishments and
              ensure it remains relevant to the roles you are applying for. Good
              luck!
            </p>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default ResumeTips;
