import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import essentialtips from "../../../assets/header-images/essentialtips.jpg";

import "../../../css/SubPage/SubPage.css";

function CollegeJourney() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={essentialtips} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Essential College Tips </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Embarking on your college journey is an exciting and transformative
            experience. This page is designed to guide you through the essential
            aspects of college life, from excelling academically to building
            meaningful relationships. Whether you're navigating your first
            semester or preparing for graduation, you'll find valuable tips and
            resources to help you succeed. Explore the sections below to enhance
            your college journey and make the most of this pivotal time in your
            life.
          </p>
        </div>
      </div>

      <div id="sections-container">

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Excel In School</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Study Tips:</strong>
              </p>
              <li class="bullet-point">
                <strong>Active Learning:</strong> Engage with the material through discussions, practice problems, and teaching others. This helps reinforce concepts and improve retention.
              </li>
              <li class="bullet-point">
                <strong>Regular Review:</strong> Set aside time each week to review notes and readings. Consistent review helps reinforce learning and improve long-term memory.
              </li>
              <li class="bullet-point">
                <strong>Group Study:</strong> Collaborate with classmates to deepen understanding and share different perspectives. Group study sessions can help clarify difficult concepts and provide new insights.
              </li>
              <p class="chapter-sub-title">
                <strong>Time Management:</strong>
              </p>
              <li class="bullet-point">
                <strong>Create a Schedule:</strong> Use a planner or digital calendar to organize your time. Schedule study sessions, classes, and personal time to maintain balance.
              </li>
              <li class="bullet-point">
                <strong>Prioritize Tasks:</strong> Focus on high-priority assignments first. Prioritizing tasks helps ensure that important deadlines are met and reduces stress.
              </li>
              <li class="bullet-point">
                <strong>Break Tasks into Smaller Steps:</strong> Make large projects more manageable by dividing them into smaller tasks. This approach helps prevent feeling overwhelmed and improves productivity.
              </li>
              <li class="bullet-point">
                <strong>Use Time Management Tools:</strong> Apps like Google Calendar can help you organize both your daily and weekly tasks and schedule.
              </li>
              <p class="chapter-sub-title">
                <strong>Academic Resources:</strong>
              </p>
              <li class="bullet-point">
                <strong>Libraries:</strong> Utilize campus libraries for quiet study spaces and access to academic resources. Libraries often provide a conducive environment for focused study.
              </li>
              <li class="bullet-point">
                <strong>Tutoring Centers:</strong> Take advantage of tutoring centers for challenging subjects. Tutors can provide additional explanations and study techniques.
              </li>
              <li class="bullet-point">
                <strong>Office Hours:</strong> Attend professors' office hours to ask questions and get clarification on course material. Building relationships with professors can also provide networking opportunities whom later on you can ask for referrence letter.
              </li>
              <li class="bullet-point">
                <strong>Rate My Professors:</strong> Check reviews for potential tutors and professors to find those who match your learning style.
              </li>
              <li class="bullet-point">
                <strong>Reddit:</strong> Use Reddit communities to find study groups and course recommendations. Your own schools reddit community can benefit you alot.
              </li>
              <p class="chapter-sub-title">
                <strong>Goal Setting:</strong>
              </p>
              <li class="bullet-point">
                <strong>Track Progress:</strong> Regularly review your goals and adjust as needed. Monitoring progress helps stay on track and make necessary adjustments.
              </li>
              <li class="bullet-point">
                <strong>Celebrate Achievements:</strong> Reward yourself for reaching milestones. Celebrating achievements boosts motivation and encourages continued effort.
              </li>
              <p class="chapter-sub-title">
                <strong>Tools:</strong>
              </p>
              <li class="bullet-point">
                <strong>Chegg:</strong> Study help, textbook rentals, and homework assistance. Chegg offers resources for various subjects and homework help.
              </li>
              <li class="bullet-point">
                <strong>Quizlet:</strong> Flashcards and study games. Quizlet helps with memorization and understanding through interactive tools.
              </li>
              <li class="bullet-point">
                <strong>Math Resources:</strong> Utilize online math resources for additional practice and tutorials. Websites like Khan Academy offer comprehensive math tutorials.
              </li>
              <p class="chapter-sub-title">
                <strong>Free Book Websites:</strong>
              </p>
              <li class="bullet-point">
                <strong>ThriftBooks:</strong> Buy cheap used books. ThriftBooks offers affordable options for purchasing used textbooks.
              </li>
              <p class="chapter-sub-title">
                <strong>How to Maintain a Good GPA:</strong>
              </p>
              <li class="bullet-point">
                <strong>Attend All Classes:</strong> Regular attendance is crucial for understanding material and participating in discussions. Being present helps keep up with the course content.
              </li>
              <li class="bullet-point">
                <strong>Stay Organized:</strong> Keep track of assignments, tests, and deadlines. Organization prevents last-minute cramming and missed deadlines.
              </li>
              <li class="bullet-point">
                <strong>Seek Help When Needed:</strong> Don't hesitate to ask for help from professors, tutors, or classmates. Seeking help early can prevent falling behind.
              </li>
              <p class="chapter-sub-title">
                <strong>How to Plan Your Schedule:</strong>
              </p>
              <li class="bullet-point">
                <strong>Balance Your Courses:</strong> Mix difficult and easier courses each semester. Balancing your course load helps manage stress and workload.
              </li>
              <li class="bullet-point">
                <strong>Include Breaks:</strong> Schedule time for breaks to avoid burnout. Regular breaks improve focus and productivity.
              </li>
              <li class="bullet-point">
                <strong>Consider Your Energy Levels:</strong> Plan challenging tasks for when you're most alert. Aligning tasks with your energy levels enhances performance.
              </li>
              <p class="chapter-sub-title">
                <strong>Health and Wellbeing:</strong>
              </p>
              <li class="bullet-point">
                <strong>Exercise:</strong> Regular physical activity can improve your mental health and academic performance.
              </li>
              <li class="bullet-point">
                <strong>Nutrition:</strong> Maintain a balanced diet to stay energized and focused.
              </li>
              <li class="bullet-point">
                <strong>Mental Health:</strong> Utilize campus counseling services and take time for self-care.
              </li>
              <p class="chapter-sub-title">
                <strong>Financial Planning:</strong>
              </p>
              <li class="bullet-point">
                <strong>Budgeting:</strong> Create a budget to manage your expenses and avoid unnecessary debt.
              </li>
              <li class="bullet-point">
                <strong>Financial Aid:</strong> Explore scholarships, grants, and work-study opportunities to finance your education.
              </li>
              <p class="chapter-sub-title">
                <strong>Building Relationships:</strong>
              </p>
              <li class="bullet-point">
                <strong>Professors:</strong> Build relationships with your professors by attending office hours and engaging in class.
              </li>
              <li class="bullet-point">
                <strong>Peers:</strong> Develop friendships and study groups to enhance your college experience.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CollegeJourney;
