import React, { useState } from "react";
import { doPasswordChange } from "../../firebase/auth";
import "../../css/components/auth.css";

/**
 * ChangePassword Component
 * Allows users to change their password.
 */
const ChangePassword = () => {
  const [password, setPassword] = useState(""); // New password input
  const [confirmPassword, setConfirmPassword] = useState(""); // Confirm password input
  const [isChanging, setIsChanging] = useState(false); // Loading state
  const [message, setMessage] = useState(""); // Success message
  const [error, setError] = useState(""); // Error message

  // Validate password (at least 6 chars, 1 uppercase, 1 number, 1 special char)
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
    return passwordRegex.test(password);
  };

  // Handle form submission and password change
  const onSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    setError("");

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Validate the new password
    if (!validatePassword(password)) {
      setError(
        "Password must be at least 6 characters long, include an uppercase letter, a number, and a special character."
      );
      return;
    }

    setIsChanging(true);
    try {
      await doPasswordChange(password);
      setMessage("Password changed successfully.");
    } catch (error) {
      setError(error.message);
    } finally {
      setIsChanging(false);
    }
  };

  return (
    <main className="auth-page-change-pass">
      <div className="auth-container-change-pass">
        <div className="auth-header-change-pass">
          <div className="auth-title-change-pass">
            <h3>Change Your Password</h3>
          </div>
        </div>
        <form onSubmit={onSubmit} className="auth-form-change-pass">
          <div className="form-group-change-pass">
            <label>New Password</label>
            <input
              type="password"
              autoComplete="new-password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}  // Updates password state
            />
          </div>
          <div className="form-group-change-pass">
            <label>Confirm New Password</label>
            <input
              type="password"
              autoComplete="off"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}  // Updates confirmPassword state
            />
          </div>
          {message && <span className="success-message-change-pass">{message}</span>}
          {error && <span className="error-message-change-pass">{error}</span>}
          <button
            type="submit"
            disabled={isChanging}
            className={`submit-button-change-pass ${isChanging ? "disabled-button-change-pass" : ""}`}
          >
            {isChanging ? "Changing..." : "Change Password"}
          </button>
        </form>
      </div>
    </main>
  );
};

export default ChangePassword;
