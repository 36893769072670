import { auth } from "./firebase";
import {
  getAuth,
  updateProfile,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  updatePassword,
} from "firebase/auth";


//Create user with email and password, send email verification, then sign out.
export const doCreateUserWithEmailAndPassword = async (email, password) => {
  const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  await sendEmailVerification(userCredential.user, {
    url: `${window.location.origin}/login`, // Verification page URL
  });
  await auth.signOut(); // Sign out user until email is verified
  return userCredential;
};


//Sign in user with email and password. Check if email is verified.
export const doSignInWithEmailAndPassword = async (email, password) => {
  const userCredential = await signInWithEmailAndPassword(auth, email, password);
  if (!userCredential.user.emailVerified) {
    await auth.signOut();
    throw new Error("Please verify your email before signing in.");
  }
  return userCredential;
};


//Sign in user with Google.
export const doSignInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  const result = await signInWithPopup(auth, provider);
  return result;
};


//Sign out user.
export const doSignOut = () => {
  return auth.signOut();
};


//Send password reset email.
export const doPassWordReset = (email) => {
  return sendPasswordResetEmail(auth, email);
};


//Update user password.
export const doPasswordChange = (password) => {
  return updatePassword(auth.currentUser, password);
};


//Send email verification to the current user.
export const doSendEmailVerification = () => {
  return sendEmailVerification(auth.currentUser, {
    url: `${window.location.origin}/login`, // Verification page URL
  });
};


//Update profile information of the current user.
export const updateProfileInformation = async (displayName, photoURL) => {
  const user = auth.currentUser;
  if (user) {
    await updateProfile(user, { displayName, photoURL });
  }
};
