import React from "react";
import "../../css/Tools/Tools.css";

const VirtualTour = () => {
  return (
    <div className="tool-container">
      <h1 className="tool-title">Virtual College Tour</h1>
      <p className="tool-message">
        We currently don't offer a virtual college tour tool. However, you can
        explore colleges online by visiting YouVisit's Virtual Tour Page.
      </p>
      <a
        href="https://www.youvisit.com/virtual-reality-360-experience"
        className="tool-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to YouVisit's Virtual Tour Page
      </a>
    </div>
  );
};

export default VirtualTour;
