import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function MasteringScholarshipInterview() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />

        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 5</p>
            <p id = "sub-scholarship-ch-header-text">Mastering the Scholarship Interview</p>
            <p id = "sub-scholarship-ch-text-two">Confidence is Key</p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            This chapter equips you with the skills to navigate scholarship
            interviews and leave a lasting positive impression.
          </p>
        </div>
      </div>

      <div id="whole-section-page-container">
        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Understanding the Interview Process</strong>
            </p>
            <p className="chapter-description">
              Not all scholarships involve interviews, but those that do are
              typically behavioral interviews designed to assess your
              personality, goals, and qualifications beyond your
              application. Interviews provide a platform to showcase your
              passion, dedication, and unique qualities.
            </p>
            <strong>Key Points: </strong>

            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Purpose:</strong> Assess your fit for the
                scholarship, understand your motivations, and evaluate your
                potential.
              </li>
              <li className="bullet-point">
                <strong>Format:</strong> May include one-on-one interviews,
                panel interviews, or group interviews.
              </li>
              <li className="bullet-point">
                <strong>Preparation:</strong> Research the scholarship
                organization and understand their values and mission.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Professional Attire: The Silent Communicator</strong>
            </p>
            <p className="chapter-description">
              Dress professionally for both in-person and virtual
              interviews. Here are some tips to ensure a strong first
              impression:
            </p>

            <strong>In-Person Interview:</strong>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Business Attire:</strong> Opt for a suit, dress, or
                business casual attire.
              </li>
              <li className="bullet-point">
                <strong>Clean and Neat:</strong> Ensure your clothes are
                clean, ironed, and free of wrinkles or tears.
              </li>
            </ul>

            <strong>Virtual Interview:</strong>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Full Professional Dress:</strong> Dress
                professionally from head to toe, even if not visible on
                camera. This prepares you for unexpected situations and
                maintains a professional mindset.
              </li>
            </ul>

            <strong>General Tips:</strong>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Minimal Jewelry and Fragrances:</strong> Keep
                jewelry and fragrances minimal to avoid distractions.
              </li>
              <li className="bullet-point">
                <strong>Neutral Colors:</strong> Choose neutral colors to
                maintain a professional look.
              </li>
              <li className="bullet-point">
                <strong>Neat Hairstyle:</strong> Ensure your hair is neat
                and professionally styled.
              </li>
            </ul>

            <p className="chapter-description">
              <strong>Note:</strong> If you do not have professional attire,
              ask your college counselor. Many schools offer resources to
              help students obtain professional attire for interviews, like
              a clothing closet or voucher programs.
            </p>
            <p className="chapter-description">
              <strong>Remember:</strong> Your goal is to project
              professionalism and confidence, allowing your qualifications
              and personality to shine.
            </p>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Strategies for Interview Success</strong>
            </p>
            <p className="chapter-description">
              Preparation is key! Here are some steps to ensure interview
              success:
            </p>

            <strong>Research Common Behavioral Questions:</strong>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                Prepare responses that highlight your strengths and
                experiences.
              </li>
              <li className="bullet-point">
                <strong>Example Questions:</strong>
              </li>
              <ul className="bullet-point-list">
                <li className="bullet-point">"Tell me about yourself."</li>
                <li className="bullet-point">
                  "What are your greatest strengths/weaknesses?"
                </li>
                <li className="bullet-point">"What are your goals?"</li>
              </ul>
            </ul>

            <strong>Practice Makes Perfect:</strong>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Rehearse Responses:</strong> Practice with peers or
                mentors to refine your delivery and enhance confidence.
              </li>
              <li className="bullet-point">
                <strong>Mock Interviews:</strong> Conduct mock interviews to
                simulate the actual interview experience.
              </li>
            </ul>

            <strong>Be Authentic and Articulate:</strong>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Authenticity:</strong> Present yourself genuinely
                and confidently.
              </li>
              <li className="bullet-point">
                <strong>Articulation:</strong> Speak clearly and concisely,
                ensuring your points are well-expressed.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasteringScholarshipInterview;
