import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";

import preparing from "../../../assets/header-images/preparing.jpg";

import "../../../css/SubPage/SubPage.css";

function BeforeCollege() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={preparing} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Preparing for College Life </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Starting college is a significant milestone that brings both
            excitement and challenges. Being well-prepared can make your
            transition smoother and set you up for success. This guide provides
            practical advice and tips to help you navigate your journey from
            high school to college, ensuring you're ready for what's ahead.
          </p>
        </div>
      </div>

      <div id="sections-container">

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Things to Know Before Starting College</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Follow Your Passion:</strong>
              </p>
              <li class="bullet-point">
                <strong>Pursue Your Interests:</strong> Follow your passion, but make sure it can support you financially after you graduate. College is a time for self-discovery and personal growth.
              </li>
              <p class="chapter-sub-title">
                <strong>Plan Well:</strong>
              </p>
              <li class="bullet-point">
                <strong>Early Planning:</strong> Complete your distribution requirements early to avoid last-minute stress. This strategy will give you more flexibility in your later semesters to concentrate on your major and electives.
              </li>
              <li class="bullet-point">
                <strong>Academic Advising:</strong> Regularly meet with academic advisors to ensure you're on track with your degree requirements and explore options.
              </li>
              <p class="chapter-sub-title">
                <strong>Be Flexible:</strong>
              </p>
              <li class="bullet-point">
                <strong>Adaptability:</strong> Be open to changes and new opportunities. College life can be unpredictable, and adaptability will help you make the most of your experience.
              </li>
              <li class="bullet-point">
                <strong>Backup Plans:</strong> Have contingency plans for classes, housing, and extracurricular activities.
              </li>
              <p class="chapter-sub-title">
                <strong>Step Outside Your Comfort Zone:</strong>
              </p>
              <li class="bullet-point">
                <strong>New Activities:</strong> Regularly try new activities. Join clubs, attend events, and meet new people to broaden your horizons and enrich your college experience.
              </li>
              <li class="bullet-point">
                <strong>Networking:</strong> Take advantage of networking opportunities to build relationships with peers, professors, and professionals.
              </li>
              <p class="chapter-sub-title">
                <strong>Find an Upperclassman to Follow:</strong>
              </p>
              <li class="bullet-point">
                <strong>Guidance:</strong> Seek out an upperclassman who is working towards a similar goal as you and who can provide guidance on navigating college life, choosing courses, and balancing academics and extracurriculars.
              </li>
              <li class="bullet-point">
                <strong>New Activities:</strong> Follow their lead in trying new activities. Join clubs, attend events, and meet new people to broaden your horizons and enrich your college experience.
              </li>
              <li class="bullet-point">
                <strong>Networking:</strong> Use their advice to take advantage of networking opportunities and build relationships with peers, professors, and professionals.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>College Housing</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>On-Campus Housing:</strong>
              </p>
              <li class="bullet-point">
                <strong>Dorms:</strong> Traditional dorms with shared rooms and communal facilities.
              </li>
              <li class="bullet-point">
                <strong>Suites and Apartments:</strong> More private options with shared common areas.
              </li>
              <li class="bullet-point">
                <strong>Living-Learning Communities:</strong> Themed housing options focused on specific academic or social interests.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Off-Campus Housing:</strong>
              </p>
              <li class="bullet-point">
                <strong>Nearby Apartments:</strong> Rentals within walking or short driving distance from campus.
              </li>
              <li class="bullet-point">
                <strong>Commute Options:</strong> Consider public transportation, biking, or driving.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Finding Roommates:</strong>
              </p>
              <li class="bullet-point">
                <strong>University Reddit:</strong> Ask for roommates or housing recommendations on your university's Reddit page.
              </li>
              <li class="bullet-point">
                <strong>Roommate Matching Services:</strong> Use school-provided or independent roommate matching services.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Housing Tips:</strong>
              </p>
              <li class="bullet-point">
                <strong>Lease Agreements:</strong> Carefully read and understand lease agreements before signing.
              </li>
              <li class="bullet-point">
                <strong>Budgeting:</strong> Consider all costs, including rent, utilities, and transportation.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Join Online Communities</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Connect with Fellow Students:</strong>
              </p>
              <li class="bullet-point">
                <strong>Discord Groups:</strong> Join Discord groups for your class year to meet fellow students and stay informed about campus events.
              </li>
              <li class="bullet-point">
                <strong>Instagram:</strong> Follow class year-specific Instagram accounts for updates and networking.
              </li>
              <li class="bullet-point">
                <strong>Reddit:</strong> Participate in Reddit groups for your university to get advice and connect with peers.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Online Resources:</strong>
              </p>
              <li class="bullet-point">
                <strong>College Forums:</strong> Engage in college forums and chat groups to ask questions and share experiences.
              </li>
              <li class="bullet-point">
                <strong>Social Media:</strong> Utilize social media to join student groups and stay connected with campus activities.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Look into Major Requirements</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Academic Planning:</strong>
              </p>
              <li class="bullet-point">
                <strong>Course Requirements:</strong> If you already know what you want to study, take a look at the class requirements for that major or minor. If you’re undecided, explore different department pages to get an idea of the classes they require.
              </li>
              <li class="bullet-point">
                <strong>Four-Year Plan:</strong> Plan your schedule for the next four years, ensuring you meet all prerequisites and graduation requirements.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Action Steps:</strong>
              </p>
              <li class="bullet-point">
                <strong>Visit Academic Websites:</strong> Explore your college’s academic website for detailed information.
              </li>
              <li class="bullet-point">
                <strong>Review Course Catalog:</strong> Check the course catalog for major and minor requirements.
              </li>
              <li class="bullet-point">
                <strong>Prerequisite Courses:</strong> Note any prerequisite courses you might need.
              </li>
              <li class="bullet-point">
                <strong>Consult Advisors:</strong> Regularly consult with academic advisors to refine your academic plan.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Look into Clubs</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Research and Engagement:</strong>
              </p>
              <li class="bullet-point">
                <strong>Club Listings:</strong> Many colleges have a web page listing their popular and active clubs. Search online for your college's name followed by the words "clubs'' or "organizations." This will help you prepare for the club fair with an idea of which clubs you want to join.
              </li>
              <li class="bullet-point">
                <strong>Club Fair:</strong> Usually during the beginning of the year (Fall Semester), attend the club fair to explore different organizations and find those that match your interests.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Action Steps:</strong>
              </p>
              <li class="bullet-point">
                <strong>Research:</strong> Search for clubs and organizations related to your interests on different club Instagrams and websites.
              </li>
              <li class="bullet-point">
                <strong>Make a List:</strong> Create a list of clubs you’re interested in joining.
              </li>
              <li class="bullet-point">
                <strong>Attend Club Fair:</strong> Visit the club booths during the club fair day to gather more information.
              </li>
              <li class="bullet-point">
                <strong>Reach Out:</strong> Contact club leaders or members to learn more about their activities and get instructions on how to join.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BeforeCollege;
