import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import research from "../../../assets/header-images/research.jpg";

function ResearchOpportunities() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={research} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Research Opportunities </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>
      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Research opportunities during college are crucial for students who
            wish to deepen their understanding of their chosen field, develop
            critical thinking skills, and gain hands-on experience. Engaging in
            research can be a transformative experience that complements
            classroom learning and prepares students for future academic and
            professional pursuits.
          </p>
        </div>
      </div>

      <div id="sections-container">

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Benefits of Research Opportunities</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Developing practical skills such as data analysis, problem-solving, and technical writing.
              </li>
              <li class="bullet-point">
                Building a professional network of peers, mentors, and industry contacts.
              </li>
              <li class="bullet-point">
                Enhancing your resume and academic credentials for future job or graduate school applications.
              </li>
              <li class="bullet-point">
                Gaining a deeper understanding of your field and contributing to meaningful advancements.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Finding Research Opportunities</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Identifying Opportunities:</strong>
              </p>
              <li class="bullet-point">
                <strong>Campus Resources:</strong> Utilize resources available on campus, such as career services, academic departments, and faculty advisors. These resources often have information on available research positions and can guide you on how to apply.
              </li>
              <li class="bullet-point">
                <strong>Online Databases:</strong> Platforms like Handshake, and your university department's research portal are excellent places to search for research opportunities.
              </li>
              <li class="bullet-point">
                <strong>Networking:</strong> Attend academic conferences, join student organizations related to your field, and connect with professors and peers. Networking can often lead to discovering unadvertised research opportunities.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Approaching Professors:</strong>
              </p>
              <li class="bullet-point">
                <strong>Email Etiquette:</strong> When reaching out to professors, ensure your email is professional and concise. Introduce yourself, explain your interest in their research, and express your enthusiasm for contributing to their work.
              </li>
              <li class="bullet-point">
                <strong>Meeting Preparation:</strong> Before meeting with a professor, familiarize yourself with their research work. Prepare questions and be ready to discuss how your interests align with their projects.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Types of Research Opportunities</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>On-Campus Research:</strong>
              </p>
              <li class="bullet-point">
                <strong>Lab Assistants:</strong> Lab assistants typically help with experiments, data collection, and maintaining laboratory equipment. These positions are a great way to gain hands-on experience and learn laboratory techniques.
              </li>
              <li class="bullet-point">
                <strong>Research Assistants:</strong> Research assistants support faculty members in various tasks, such as literature reviews, data analysis, and preparing research publications. These roles provide deeper involvement in research projects.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Off-Campus Research:</strong>
              </p>
              <li class="bullet-point">
                <strong>Internships:</strong> Research internships with companies, non-profits, and government agencies offer practical experience in a professional setting. These internships often focus on applied research and can provide valuable industry insights.
              </li>
              <li class="bullet-point">
                <strong>External Labs:</strong> Opportunities in external research labs or institutions, such as national laboratories or private research firms, can broaden your research experience and expose you to different methodologies and technologies.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Application Process</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Preparation:</strong>
              </p>
              <li class="bullet-point">
                <strong>Resume:</strong> Craft a resume that highlights your academic achievements, relevant coursework, and any previous research or laboratory experience. Tailor your resume to the specific research opportunity you are applying for.
              </li>
              <li class="bullet-point">
                <strong>Cover Letter:</strong> Write a compelling cover letter that explains your interest in the research position, how your skills and experiences align with the project, and your long-term academic and career goals.
              </li>
              <li class="bullet-point">
                <strong>Personal Statement:</strong> A strong personal statement should detail your research interests, previous experiences, and why you are passionate about the specific research area. Be sure to include any relevant coursework or projects.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Recommendation Letters:</strong>
              </p>
              <li class="bullet-point">
                <strong>Who to Ask:</strong> Select recommenders who know you well and can speak to your academic abilities and research potential. Professors, research supervisors, and academic advisors are ideal choices.
              </li>
              <li class="bullet-point">
                <strong>How to Request:</strong> When requesting a recommendation letter, provide your recommender with ample time, a copy of your resume, and details about the research position you are applying for. Be sure to thank them for their support.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Interview Preparation:</strong>
              </p>
              <li class="bullet-point">
                <strong>Common Questions:</strong> Be prepared to answer questions about your previous research experiences, your interest in the specific project, and your long-term goals. You may also be asked technical questions related to the research.
              </li>
              <li class="bullet-point">
                <strong>Tips:</strong> Practice your responses to common questions, research the project and the professor’s work thoroughly, and prepare thoughtful questions to ask during the interview. Follow up with a thank-you email after the interview.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Funding and Scholarships</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Funding Sources:</strong>
              </p>
              <li class="bullet-point">
                <strong>University Grants:</strong> Many universities offer grants and funding for student research projects. Check with your academic department and the university’s research office for available grants.
              </li>
              <li class="bullet-point">
                <strong>External Funding:</strong> Numerous external organizations provide grants and fellowships for student researchers. Examples include the Fulbright Program, the Goldwater Scholarship, and grants from professional societies in your field.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Scholarships:</strong>
              </p>
              <li class="bullet-point">
                <strong>Overview:</strong> Scholarships specifically for research students can provide financial support and recognition for your work. These scholarships may be based on merit, need, or specific research interests.
              </li>
              <li class="bullet-point">
                <strong>Application Tips:</strong> Pay close attention to the scholarship requirements and deadlines. Tailor your application to highlight your research experiences and future goals. Seek feedback on your application materials from mentors or advisors. Check the Scholarship Page 
                <a class="link" href="/afford-college/scholarship">HERE</a> 
                for more scholarship guide
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Additional Tips</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Balancing Research with Academics:</strong>
              </p>
              <li class="bullet-point">
                <strong>Time Management:</strong> Effectively balance your coursework and research commitments by creating a detailed schedule and setting priorities.
              </li>
              <li class="bullet-point">
                <strong>Seek Support:</strong> Don't hesitate to seek advice from your academic advisor or research supervisor if you encounter challenges.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Maximizing Research Experience:</strong>
              </p>
              <li class="bullet-point">
                <strong>Documentation:</strong> Keep detailed records of your research activities, including data collected, methodologies used, and outcomes. This will be useful for writing reports and publications.
              </li>
              <li class="bullet-point">
                <strong>Continuous Learning:</strong> Stay updated with the latest developments in your field by reading research papers, attending seminars, and engaging in discussions with peers.
              </li>
            </ul>
          </div>
        </div>

        {/*

        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              Participating in research during your college years can
              significantly enhance your academic and professional journey. By
              actively seeking out research opportunities, preparing thoroughly,
              and making the most of available resources, you can gain
              invaluable experience and make meaningful contributions to your
              field. Whether you are interested in on-campus research,
              internships, or external lab positions, the skills and connections
              you develop will serve you well in your future endeavors.
              Remember, the key to a successful research experience is proactive
              engagement, continuous learning, and a passion for discovery.
            </p>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default ResearchOpportunities;
