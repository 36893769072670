import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import timeline from "../../../assets/inner-content-images-and-files/InternshipTimeLine.png";
import starInterview from "../../../assets/inner-content-images-and-files/starInterview.png";

import steminternsihp from "../../../assets/header-images/steminternsihp.jpg";

import "../../../css/SubPage/SubPage.css";

function StemMajorInternships() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={steminternsihp} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> STEM Internship Opportunities </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Securing an internship as a STEM major is a crucial step towards
            gaining practical experience, building your professional network,
            and enhancing your resume. Internships provide you with the
            opportunity to apply classroom knowledge to real-world challenges,
            develop new skills, and gain insight into potential career paths.
            This guide offers a comprehensive overview of the internship
            application process, from preparing your portfolio to tracking your
            applications. Whether you're a freshman or a sophomore looking for
            specific programs or a senior ready for industry experience, you'll
            find valuable resources and tips to help you succeed in securing an
            internship.
          </p>
        </div>
      </div>

   {/*   <img id="timeline" src={timeline} alt="Internship Timeline" />  */}

      <div id="sections-container">

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Preparation for Application</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Create Projects:</strong>
              </p>
              <li class="bullet-point">
                <strong>Look up YouTube tutorials:</strong> Find project ideas on YouTube (e.g., Full Stack project).
              </li>
              <li class="bullet-point">
                <strong>Avoid simple projects:</strong> Refrain from listing basic projects like Snake game or Tic Tac Toe on your resume.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Resume:</strong>
              </p>
              <li class="bullet-point">
                For resume templates and guides, please refer to the{" "}
                <Link to="/college/resume" className="link">
                  Resume Page
                </Link>
                .
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>LinkedIn:</strong>
              </p>
              <li class="bullet-point">
                <strong>Make a Professional Profile:</strong> Ensure your profile is complete and professional.{" "}
                <a
                  href="https://www.linkedin.com/business/sales/blog/profile-best-practices/17-steps-to-a-better-linkedin-profile-in-2017"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
              <li class="bullet-point">
                <strong>Connect with Everyone Related to Your Field:</strong> Connect with professionals in your field until you reach 500+ connections. (Do not spam connections)
              </li>
              <li class="bullet-point">
                <strong>Actively Connect with People on LinkedIn:</strong> Send initial message asking if they are available to meet on Zoom. Then after you get to know them, ask for referrals.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Additional Things to Do:</strong>
              </p>
              <li class="bullet-point">
                <strong>Email Professors for Research Opportunities:</strong> Seek research positions and guidance.
              </li>
              <li class="bullet-point">
                <strong>Start Doing Leetcode:</strong> Practice coding problems regularly.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Additional Resources:</strong>
              </p>
              <li class="bullet-point">
                <a
                  className="link"
                  href="https://www.reddit.com/r/cscareerquestions/"
                >
                  r/cscareerquestions
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Internship Searching</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Regular Internships:</strong>
              </p>
              <li class="bullet-point">
                <strong>All Types of Internships:</strong>{" "}
                <a
                  href="https://github.com/SimplifyJobs/Summer2025-Internships"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
              <li class="bullet-point">
                <strong>Internships Without Coding Assessments:</strong>{" "}
                <a
                  href="https://github.com/poteto/hiring-without-whiteboards"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
              <li class="bullet-point">
                <strong>Top Paid Internships:</strong> Top paid internships.{" "}
                <a
                  href="https://www.levels.fyi/internships/"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
              <li class="bullet-point">
                <strong>Startups:</strong> Explore internships at startups.{" "}
                <a
                  href="https://wellfound.com/login?after_sign_in=%2Fjobs%2Fhome"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
              <li class="bullet-point">
                <strong>Researching Internships:</strong> Use the provided resources to find internships.
                <a
                  href="https://joinhandshake.com/"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Freshmen and Sophomore Programs:</strong>
              </p>
              <li class="bullet-point">
                <strong>Fresh/Sophomore Only:</strong>{" "}
                <a
                  href="https://www.collegenav.org/programs/freshmen-sophomore-programs"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Referrals</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Why Referral:</strong>
              </p>
              <p class="chapter-description">
                Referrals can significantly boost your chances of landing an internship or job. When a current employee refers to you, it demonstrates to the employer that you are a trusted candidate, potentially making your application stand out among others. Referrals often lead to faster interview processes and can provide valuable insights about the company culture and job expectations, giving you a competitive edge. Building a strong network and seeking referrals can be a crucial step in advancing your career.
              </p>
            </ul>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Referral Request Templates:</strong>
              </p>
              <p class="chapter-description">
                <b>Note:</b> If you are trying to ask for a referral, wait for the person to send you a link to apply. Do not apply through the company website ahead of time, else they can't refer you.
              </p>
              <p class="sub-title">
                <strong>You Found Someone to Ask for Referral from a Company You Want:</strong>
              </p>
              <li class="bullet-point">
                <strong>Example 1:</strong>{" "}
                <p class="referral-text">Hello [ReferralName],</p>
                <p class="referral-text">
                  {" "}
                  Great to connect with you! My name is [YourName], and I am a third-year undergraduate student at UCLA studying computer science. I am in the UCLA ONE mentorship program and I came across your profile while looking at mentors who graduated from UCLA. I was interested in software internship positions at Google and wanted to reach out to you today to talk about any roles that you think I would be a good fit for in hopes of getting a referral. Attached below is my resume, and I would be more than happy to provide any other information that would help. I look forward to hearing from you, thank you! 
                  <p class="referral-text">Best, [YourName]</p>
                </p>
              </li>
              <li class="bullet-point">
                <strong>Example 2:</strong>{" "}
                <p class="referral-text">Hello [ReferralName],</p>
                <p class="referral-text">
                  {" "}
                  My name is [YourName], and I am a third-year computer science undergraduate student at UCLA. I was interested in the software engineering internship positions at Apple and I wanted to reach out to you today to talk about any roles that you think I would be a good fit for in hopes of getting a referral. Attached below is my resume, and I would be more than happy to provide any other information that would help or hop on a call, thank you!
                  <p class="referral-text">Best, [YourName]</p>
                </p>
              </li>
              <p class="sub-title">
                <strong>If Someone Posted They Are Hiring in Their Team:</strong>
              </p>
              <li class="bullet-point">
                <strong>Example 1:</strong>{" "}
                <p class="referral-text">Hello [ReferralName],</p>
                <p class="referral-text">
                  {" "}
                  Great to connect with you! My name is [YourName], and I am a third-year undergraduate student at UCLA studying computer science. I saw a post that your team was hiring at Apple Display Team. I would love to contribute in any way I could as it would be a great learning experience for me. Attached below is my resume that displays my past experiences and skills. I look forward to hearing from you, thanks! 
                  <p class="referral-text">Best, [YourName]</p>
                </p>
              </li>
              <li class="bullet-point">
                <strong>Example 2:</strong>{" "}
                <p class="referral-text">Hello [ReferralName],</p>
                <p class="referral-text">
                  My name is [YourName], and I am a third-year computer science undergraduate student at UCLA (go Bruins!). I saw your post that the Recommendations and ML Infra was hiring and I wanted to see if there were any positions open for interns. I would love to join and help out in the work you are doing. Attached below is my resume, and I would be more than happy to provide any other information that would help or hop on a call, thanks!
                </p>
                <p class="referral-text">Best, [YourName]</p>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Interview Prep</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Learning Resources:</strong>
              </p>
              <li class="bullet-point">
                <strong>DSA YouTube Series:</strong>{" "}
                <a
                  href="https://www.youtube.com"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
              <li class="bullet-point">
                <strong>GeeksforGeeks:</strong>{" "}
                <a
                  href="https://www.geeksforgeeks.org"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
              <li class="bullet-point">
                <strong>VisuAlgo:</strong>{" "}
                <a
                  href="https://visualgo.net"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
              <li class="bullet-point">
                <strong>HackerRank:</strong>{" "}
                <a
                  href="https://www.hackerrank.com"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
              <li class="bullet-point">
                <strong>Cracking the Coding Interview Book:</strong>{" "}
                <a
                  href="https://www.amazon.com/Cracking-Coding-Interview-Programming-Questions/dp/0984782850"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Technical Assessment Prep:</strong>
              </p>
              <li class="bullet-point">
                <strong>Tech Interview Handbook:</strong>{" "}
                <a
                  href="https://www.techinterviewhandbook.org"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tech Interview Handbook
                </a>
              </li>
              <li class="bullet-point">
                <strong>Leetcode 75 Questions:</strong>{" "}
                <a
                  href="https://leetcode.com/study-plan/leetcode-75"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Leetcode 75 Questions
                </a>
              </li>
              <li class="bullet-point">
                <strong>Neetcode 150:</strong>{" "}
                <a
                  href="https://neetcode.io/practice"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Neetcode
                </a>
              </li>
              <li class="bullet-point">
                <strong>HackerRank Interview Preparation Kit:</strong>{" "}
                <a
                  href="https://www.hackerrank.com/interview/preparation-kit"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HackerRank
                </a>
              </li>
              <li class="bullet-point">
                <strong>Cracking the Coding Interview:</strong>{" "}
                <a
                  href="https://www.amazon.com/Cracking-Coding-Interview-Programming-Questions/dp/0984782850"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Essential book for coding interviews
                </a>
              </li>
              <li class="bullet-point">
                <strong>NOTE:</strong> Do mock interviews with your friends.
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Phone Interview Prep:</strong>
              </p>
              <li class="bullet-point">
                <strong>Reddit Phone Interview Advice:</strong>{" "}
                <a
                  href="https://www.reddit.com"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Tips for Behavioral Interviews:</strong>
              </p>
              <li class="bullet-point">
                <strong>Look up on Reddit for company questions:</strong> Most company behavioral or technical interview sample questions can be found on reddit posted by previous interns.
              </li>
              <li class="bullet-point">
                <strong>Research the Company and position you’re applying for:</strong> They will almost always ask: “Why would you like to work for us?”
              </li>
              <li class="bullet-point">
                <strong>Have prepared stories/ experiences to talk about:</strong>
                <li class="bullet-point">
                  Answer questions and create opportunities to highlight your skills and work ethics.
                </li>

                <li class="bullet-point">
                  Keep each section concise: 4-5 sentences per section so that it can be covered in the given time during interviews
                </li>
                <li class="bullet-point">
                  Prepare both a detailed and a short version of your answers
                </li>
                <li class="bullet-point">
                  Use the STAR method (Situation, Task, Action, Result)
                </li>
              </li>
            </ul>

            <ol class="bullet-point-list">
              <p class="sub-title">
                <strong>EXP Behavioral Questions:</strong>
              </p>
              <li class="bullet-point">
                Tell me about a time you stepped up into a leadership role.
              </li>
              <li class="bullet-point">
                Tell me about a time you failed and what you learned from it.
              </li>
              <li class="bullet-point">
                Tell me about a time you had a disagreement/conflict with a teammate. How did you handle it?
              </li>
              <li class="bullet-point">
                Give me an example of when you had to go above and beyond your position's responsibilities to get a job done.
              </li>
              <li class="bullet-point">
                Have you come across a problem that you could not solve? How did you handle the problem?
              </li>
              <li class="bullet-point">
                Tell me about a time when you did something completely different from the plan. Why did you go that route?
              </li>
              <li class="bullet-point">Why us?</li>
            </ol>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Interview Tips</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Interview Structure</strong>
              </p>
              <li class="bullet-point">45-60 minutes total</li>
              <li class="bullet-point">Behavioral questions (5-10 min)</li>
              <li class="bullet-point">Resume, experiences</li>
              <li class="bullet-point">Soft/hard skills</li>
              <li class="bullet-point">Coding (30-45 min)</li>
              <li class="bullet-point">1-2 questions</li>
              <li class="bullet-point">Time for Questions (5 min)</li>
            </ul>

            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Insights on Interviews</strong>
              </p>
              <li class="bullet-point">
                Interviewers are not out to get you.
              </li>
              <li class="bullet-point">
                Ask questions and for hints if needed. “Can we circle back?”
              </li>
              <li class="bullet-point">
                They want to see how you think, not just your answers.
              </li>
              <li class="bullet-point">
                Be polite and remember the behavioral aspect.
              </li>
              <li class="bullet-point">
                Show them you’re someone they want to work with.
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Technical Tips</strong>
              </p>
              <li class="bullet-point">
                <strong>Don’t start coding immediately!</strong> Think through the problem and clarify ambiguities.
              </li>
              <li class="bullet-point">
                <strong>Break down the problem.</strong> Let the interviewer know your approach. Write pseudo-code and an initial solution.
              </li>
              <li class="bullet-point">
                <strong>Differentiate between sub-optimal and optimal solutions.</strong>
              </li>
              <li class="bullet-point">
                <strong>Keep talking out loud.</strong> Let interviewers know your thought process.
              </li>
              <li class="bullet-point">
                <strong>Listen to feedback.</strong> They are trying to help!
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="sub-title">
                <strong>Additional Technical Tips</strong>
              </p>
              <li class="bullet-point">
                <strong>TEST TEST TEST.</strong> Run test cases to show your solution works. Be aware of edge cases.
              </li>
              <li class="bullet-point">
                <strong>Analyze time and space complexity.</strong>{" "}
                Interviewers often ask this at the end of coding.
              </li>
              <li class="bullet-point">
                <strong>Be prepared to optimize your code.</strong>{" "}
                Interviewers may ask for improvements.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Additional Resources</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Application Tracking:</strong>{" "}
                <a
                  href="https://www.cscareers.dev/"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Link
                </a>
                , or Track your applications, deadlines and follow-ups using a structured Google Spreadsheet.
              </li>
              <li class="bullet-point">
                <strong>Tech Roadmap:</strong>{" "}
                <a
                  href="https://roadmap.sh/"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Here
                </a>{" "}
                is a link for STEM focused career roadmaps, guides and other educational content to help guide developers in picking a specific area within the tech field.
              </li>
              <li class="bullet-point">
                <strong>The Missing Semester of Your CS Education:</strong>{" "}
                <a
                  href="https://missing.csail.mit.edu/"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Here
                </a>{" "}
                is a link to classes that teach you advanced topics in Computer Science.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StemMajorInternships;
