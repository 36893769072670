import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import abroad from "../../../assets/header-images/abroad.jpg";

function StudyAbroad() {
  return (
    <div id="gpa-page-background">
      <div id="Ch-face">
        <img id="header-image" src={abroad} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Study Abroad Programs </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="table-content-container-subs">
        <div id="smaller-toc-cont-subs">
          <h1 id="new-TOC-name-subs">Coming Soon!</h1>
        </div>

        <p id="new-toc-description-subs">
          {" "}
          As co-creator Will Garcia, I'm excited to announce that a new "Study
          Abroad" page is coming soon to our website. This page will be crafted
          after my own study abroad experience, with the goal of creating a
          valuable resource to guide other students through their own
          international education journeys. Stay tuned for practical advice,
          tips, and insights that will help make the study abroad experience
          more enriching and accessible for everyone!{" "}
        </p>
      </div>

      {/*

      <div id="introduction-section">
        <div id="introduction-text-container">
          <div id="introduction-title-container">
            <h2 id="introduction-title"> Introduction </h2>
            <div id="title-underline"></div>
          </div>
          <p id="introduction-text">
            Studying abroad offers students the unique opportunity to immerse
            themselves in a different culture, gain a global perspective, and
            enhance their academic and professional experience. It can be a
            transformative journey that broadens horizons and provides
            invaluable life skills.
          </p>
        </div>
      </div>

*/}

      {/*

      <div id="sections-container">
        <div class="section">
          <div class="content-section">
            <div class="content-text">
              <p class="section-title">
                <strong>Benefits of Study Abroad</strong>
              </p>
              <ul class="bullet-list">
                <li class="bullet-item">
                  Developing a global perspective and understanding diverse
                  cultures.
                </li>
                <li class="bullet-item">
                  Enhancing your resume and academic credentials for future
                  career or graduate school opportunities.
                </li>
                <li class="bullet-item">
                  Building a network of international contacts and improving
                  language proficiency.
                </li>
                <li class="bullet-item">
                  Experiencing unique educational opportunities and different
                  teaching styles.
                </li>
                <li class="bullet-item">
                  Fostering personal growth and increasing cultural awareness.
                </li>
                <li class="bullet-item">
                  Creating lifelong memories and friendships from around the
                  world.
                </li>
              </ul>
            </div>
            
          </div>
        </div>

        <div class="section">
          <div class="content-section">
            <div class="content-text">
              <p class="section-title">
                <strong>Finding Study Abroad Programs</strong>
              </p>
              <ul class="bullet-list">
                <p class="sub-title">
                  <strong>Identifying Opportunities:</strong>
                </p>
                <li class="bullet-item">
                  <strong>University Programs:</strong> Many universities have
                  established study abroad programs. Check with your
                  university’s study abroad office or website for available
                  programs and partnerships.
                </li>
                <li class="bullet-item">
                  <strong>
                    University of California Study Abroad Program:
                  </strong>{" "}
                  <a
                    className="link"
                    href="https://uceap.universityofcalifornia.edu/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                </li>
                <li class="bullet-item">
                  <strong>Exchange Programs:</strong> Participate in exchange
                  programs that allow you to study at a partner institution
                  abroad while paying your home institution’s tuition fees.
                </li>
              </ul>
              <ul class="bullet-list">
                <p class="sub-title">
                  <strong>Researching Programs:</strong>
                </p>
                <li class="bullet-item">
                  <strong>Program Options:</strong> Explore different program
                  types, such as semester-long, year-long, summer, and quarterly
                  programs. Consider the academic focus, location, language
                  requirements, and cultural immersion aspects.
                </li>
                <li class="bullet-item">
                  <strong>Student Reviews:</strong> Read reviews and
                  testimonials from students who have previously participated in
                  the programs you are considering. This can provide insight
                  into the program’s quality and the experiences of past
                  participants.
                </li>
              </ul>
            </div>
            
          </div>
        </div>

        <div class="section">
          <div class="content-section">
            <div class="content-text">
              <p class="section-title">
                <strong>Application Process</strong>
              </p>
              <ol class="bullet-list">
                <p class="sub-title">
                  <strong>Preparation:</strong>
                </p>
                html Copy code
                <li class="bullet-item">
                  <strong>Research:</strong> Begin by researching potential
                  programs and destinations. Consider factors such as academic
                  offerings, language of instruction, cost, and cultural fit.
                </li>
                <li class="bullet-item">
                  <strong>Academic Advising:</strong> Ensure the courses you
                  plan to take abroad align with your academic plan. Consult
                  with your academic advisor to confirm credit transfer.
                </li>
                <li class="bullet-item">
                  <strong>Study Abroad Advising:</strong> Schedule sessions with
                  your university’s study abroad office to discuss your options,
                  the application process, and specific requirements.
                </li>
                <li class="bullet-item">
                  <strong>Financial Advising:</strong> Meet with financial
                  advisors to explore funding options, scholarships, and
                  budgeting for your study abroad experience.
                </li>
              </ol>

              <ul class="bullet-list">
                <p class="sub-title">
                  <strong>Application Materials:</strong>
                </p>
                <li class="bullet-item">
                  <strong>Application Form:</strong> Complete the application
                  form for your chosen study abroad program. This may include
                  personal information, academic background, and program
                  preferences.
                </li>
                <li class="bullet-item">
                  <strong>Personal Statement:</strong> Write a personal
                  statement explaining why you want to study abroad, what you
                  hope to gain from the experience, and how it fits into your
                  academic and career goals.
                </li>
                <li class="bullet-item">
                  <strong>Transcripts:</strong> Provide official transcripts
                  from your home institution.
                </li>
                <li class="bullet-item">
                  <strong>Letters of Recommendation:</strong> Obtain letters of
                  recommendation from professors or academic advisors who can
                  speak to your academic abilities and readiness for a study
                  abroad experience.
                </li>
                <li class="bullet-item">
                  <strong>Passport and Visa:</strong> Ensure you have a valid
                  passport and research the visa requirements for your
                  destination country. Begin the visa application process early
                  to avoid delays.
                </li>
              </ul>
            </div>
            
          </div>
        </div>

        <div class="section">
          <div class="content-section">
            <div class="content-text">
              <p class="section-title">
                <strong>Funding and Scholarships</strong>
              </p>
              <ul class="bullet-list">
                <p class="sub-title">
                  <strong>Funding Sources:</strong>
                </p>
                <li class="bullet-item">
                  <strong>University Scholarships:</strong> Many universities
                  offer scholarships specifically for study abroad programs.
                  Check with your study abroad office for available options.
                </li>
                <li class="bullet-item">
                  <strong>External Scholarships:</strong> Numerous organizations
                  provide scholarships for study abroad.
                </li>
                <li class="bullet-item">
                  <strong>Financial Aid:</strong> Inquire about the possibility
                  of using your existing financial aid package for study abroad
                  expenses. Speak with your university’s financial aid office
                  for details.
                </li>
              </ul>
              <ul class="bullet-list">
                <p class="sub-title">
                  <strong>Budgeting:</strong>
                </p>
                <li class="bullet-item">
                  <strong>Program Costs:</strong> Understand the full cost of
                  your study abroad program, including tuition, housing, meals,
                  travel, and personal expenses.
                </li>
                <li class="bullet-item">
                  <strong>Budget Planning:</strong> Create a budget plan to
                  manage your finances while abroad. Consider ways to save
                  money, such as choosing less expensive housing options or
                  cooking your own meals.
                </li>
              </ul>
            </div>
            
          </div>
        </div>

        <div class="section">
          <div class="content-section">
            <div class="content-text">
              <p class="section-title">
                <strong>Preparing for Departure</strong>
              </p>
              <ul class="bullet-list">
                <p class="sub-title">
                  <strong>Pre-Departure Orientation:</strong>
                </p>
                <li class="bullet-item">
                  <strong>University Sessions:</strong> Attend pre-departure
                  orientation sessions offered by your university. These
                  sessions provide important information about travel, health,
                  safety, and cultural adjustment.
                </li>
                <li class="bullet-item">
                  <strong>Online Resources:</strong> Utilize online resources
                  and guides provided by your study abroad program to prepare
                  for your journey.
                </li>
              </ul>
            </div>
            
          </div>
        </div>

        <div class="section">
          <div class="content-section">
            <div class="content-text">
              <p class="section-title">
                <strong>While Abroad</strong>
              </p>
              <ul class="bullet-list">
                <p class="sub-title">
                  <strong>Academic Success:</strong>
                </p>
                <li class="bullet-item">
                  <strong>Coursework:</strong> Stay on top of your coursework
                  and take advantage of academic resources available at your
                  host institution.
                </li>
                <li class="bullet-item">
                  <strong>Language Skills:</strong> Practice the local language
                  as much as possible to enhance your experience and improve
                  your language proficiency.
                </li>
              </ul>
              <ul class="bullet-list">
                <p class="sub-title">
                  <strong>Cultural Immersion:</strong>
                </p>
                <li class="bullet-item">
                  <strong>Engagement:</strong> Engage with the local culture by
                  participating in cultural events, exploring the community, and
                  making connections with local students.
                </li>
                <li class="bullet-item">
                  <strong>Travel:</strong> Take advantage of your time abroad to
                  travel and explore other regions or countries. Plan trips
                  during breaks or weekends.
                </li>
              </ul>
              <ul class="bullet-list">
                <p class="sub-title">
                  <strong>Support Network:</strong>
                </p>
                <li class="bullet-item">
                  <strong>Stay Connected:</strong> Maintain regular
                  communication with your family and friends back home. Keep
                  them updated on your experiences and well-being.
                </li>
                <li class="bullet-item">
                  <strong>Local Support:</strong> Know the contact information
                  for local support services, including your program
                  coordinator, host institution’s international office, and
                  local emergency services.
                </li>
              </ul>
            </div>
            
          </div>
        </div>

        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              Studying abroad can be a life-changing experience that enriches
              your academic journey and personal growth. By immersing yourself
              in a new culture, you gain valuable skills, broaden your
              perspective, and build lasting connections. Proper preparation and
              active engagement while abroad will help you maximize the benefits
              of this opportunity. Embrace the adventure, learn from every
              experience, and let your time abroad shape you into a more
              knowledgeable and globally aware individual.
            </p>
          </div>
        </div>
      </div>

*/}
    </div>
  );
}

export default StudyAbroad;
