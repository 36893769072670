import React from "react";
import { Link } from "react-router-dom";
import "../../../css/MainPage/TableOfContentPages.css";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import timeline from "../../../assets/inner-content-images-and-files/ScholarshipTimeLine.png";

import scholarship from "../../../assets/header-images/scholarship.jpg";

const Scholarship = () => {
  return (
    
    <div id ="scholar-scholar-div">

      <div id="Ch-face">
        <img id="header-image" src={scholarship} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Scholarships </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div className="scholar-below-header-container">

        <div id="base-page-introduction-container">
          <div id="base-page-introduction-text-container">
            <div id="base-page-introduction-title-container">
              <h2 id="base-page-intro-title"> Introduction </h2>
              
            </div>

            <p id="base-page-introduction-text-main">
              {" "}
              Securing scholarships can significantly ease the financial burden
              of college, allowing you to focus on your studies and personal
              growth. This guide is designed to provide you with detailed,
              step-by-step information on finding and applying for scholarships.
              Whether you're just beginning your scholarship search or looking
              to refine your application strategy, you'll find valuable tips and
              resources here to help you succeed. Explore the chapters and
              sections below to unlock your full potential and achieve your
              educational dreams.
            </p>
          </div>
        </div>



      
      
        <div className="general-toc-container">

        {/*  
          <img id="timeline" src={timeline} />
*/}



          <div class="traffic-box-new">
              <h3 class = "traffic-box-part-number"> Ch 1</h3>
              <h2 class = "traffic-title"> Understanding Scholarships: Your Key to Educational
              Freedom</h2>

              <p class = "traffic-description">1.1 - What is a Scholarship and Why is it Important? </p>
              <p class = "traffic-description">1.2 - Types of Scholarships </p>
              <p class = "traffic-description">1.3 - The Value Beyond Financial Aid </p>
              <p><span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adip. </span></p>
              <div class="service-item">
                  
              <Link to="/afford-college/scholarship/understanding-scholarship"> <button class =  "service-item-button" >Begin</button></Link>
              </div>
          </div>




          <div class="traffic-box-new">
              <h3 class = "traffic-box-part-number"> Ch 2</h3>
              <h2 class = "traffic-title"> Preparing for the Scholarship Search: A Strategic
              Approach</h2>
              <p class = "traffic-description">2.1 - Establishing Your Scholarship Goals </p>
              <p class = "traffic-description">2.2 - Navigating Timelines and Application Periods </p>
              <p class = "traffic-description">2.3 - Effective Scholarship Research Strategies </p>
              <p><span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adip. </span></p>
              <div class="service-item">
                  
              <Link to="/afford-college/scholarship/scholarship-search"> <button class =  "service-item-button" >Begin</button></Link>
              </div>
          </div>


          <div class="traffic-box-new">
              <h3 class = "traffic-box-part-number"> Ch 3</h3>
              <h2 class = "traffic-title"> Maximizing Your Chances of Success: Strategic Tips
              for Winning</h2>
              <p class = "traffic-description">3.1 - Prioritize Local Scholarships </p>
              <p class = "traffic-description">3.2 - Avoiding Common Pitfalls and Scams </p>
              <p class = "traffic-description">3.3 - Organization and Time Management </p>
              <p class = "traffic-description">3.4 - The Power of Persistence </p>
              
              <div class="service-item">
                  
              <Link to="/afford-college/scholarship/application-tips"> <button class =  "service-item-button" >Begin</button></Link>
              </div>
          </div>


          <div class="traffic-box-new">
              <h3 class = "traffic-box-part-number"> Ch 4</h3>
              <h2 class = "traffic-title"> Crafting a Standout Application: Showcase Your
              Strengths and Shine</h2>
              <p class = "traffic-description">4.1 - The "Information About Yourself" Template </p>
              <p class = "traffic-description">4.2 - Steps to Craft a Compelling Scholarship Essay </p>
              <p class = "traffic-description">4.3 - Writing Essays That Win </p>
              <p class = "traffic-description">4.4 - Securing Strong Recommendation Letters </p>
              <div class="service-item">
                  
              <Link to="/afford-college/scholarship/crafting-application"> <button class =  "service-item-button" >Begin</button></Link>
              </div>
          </div>




          <div class="traffic-box-new">
              <h3 class = "traffic-box-part-number"> Ch 5</h3>
              <h2 class = "traffic-title"> Mastering the Scholarship Interview: Confidence is
              Key</h2>
              <p class = "traffic-description">5.1 - Understanding the Interview Process </p>
              <p class = "traffic-description">5.2 - Professional Attire: The Silent Communicator </p>
              <p class = "traffic-description">5.3 - Strategies for Interview Success </p>
              <p><span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adip. </span></p>
              <div class="service-item">
                  
              <Link to="/afford-college/scholarship/scholarship-interview"> <button class =  "service-item-button" >Begin</button></Link>
              </div>
          </div>


          <div class="traffic-box-new">
              <h3 class = "traffic-box-part-number"> Ch 6</h3>
              <h2 class = "traffic-title"> Post-Award Success Strategies: How to Maximize the
              Benefits</h2>

              <p class = "traffic-description">6.1 - Expressing Gratitude </p>
              <p class = "traffic-description">6.2 - Effective Management of Scholarship Funds </p>
              <p class = "traffic-description">6.3 - Appeal for Cost of Attendance Increase </p>
              <p><span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adip. </span></p>

              
              <div class="service-item">
                  
              <Link to="/afford-college/scholarship/post-award-tips"> <button class =  "service-item-button" >Begin</button></Link>
              </div>
          </div>



          <div class="traffic-box-new">
              
              <h2 class = "traffic-title"> Our Recommended Scholarship Lists and Websites:</h2>

              <p class = "traffic-description"> First, explore local scholarships. Once you’ve exhausted local options, expand your search to national scholarships and online resources. Here is a list of scholarships we have curated, along with some reputable websites to get you started: </p>

              <p class = "traffic-description"> <a
                    href="https://docs.google.com/spreadsheets/d/1_g5FWjag24UN9Fp7Jz3AbrUYVP7XKn71su9fCK5-pKM/edit?usp=sharing"
                    class="link"
                  >
                     1 - CollegeNav’s Top Choices Spreadsheet (Year Round
                    Scholarships)
                  </a> </p>


                  <p class = "traffic-description"> <a href="https://www.scholarshipamerica.org" class="link">
                    2 - Scholarship America
                  </a></p>


                  <p class = "traffic-description"> <a href="https://www.mykaleidoscope.com" class="link">
                    3 - Kaleidoscope
                  </a> </p>


                  <p class = "traffic-description"> <a
                    href="https://bigfuture.collegeboard.org/scholarship-search"
                    class="link"
                  >
                    4 - College Board Scholarship Website
                  </a> </p>
             


              
          </div>




{/*

          <div className="general-toc-item">
            <h3 className="general-toc-item-title">
              <Link
                to="/afford-college/scholarship/understanding-scholarship"
                className="link"
              >
                Chapter 1: Understanding Scholarships: Your Key to Educational
                Freedom
              </Link>
            </h3>
            <ul className="general-list">
              <li className="general-list-item">
                1.1 - What is a Scholarship and Why is it Important?
              </li>
              <li className="general-list-item">1.2 - Types of Scholarships</li>
              <li className="general-list-item">
                1.3 - The Value Beyond Financial Aid
              </li>
            </ul>
          </div>

          <div className="general-toc-item">
            <h3 className="general-toc-item-title">
              <Link
                to="/afford-college/scholarship/scholarship-search-guide"
                className="link"
              >
                Chapter 2: Preparing for the Scholarship Search: A Strategic
                Approach
              </Link>
            </h3>
            <ul className="general-list">
              <li className="general-list-item">
                2.1 - Establishing Your Scholarship Goals
              </li>
              <li className="general-list-item">
                2.2 - Navigating Timelines and Application Periods
              </li>
              <li className="general-list-item">
                2.3 - Effective Scholarship Research Strategies
              </li>
            </ul>
          </div>

          <div className="general-toc-item">
            <h3 className="general-toc-item-title">
              <Link
                to="/afford-college/scholarship/application-tips"
                className="link"
              >
                Chapter 3: Maximizing Your Chances of Success: Strategic Tips
                for Winning
              </Link>
            </h3>
            <ul className="general-list">
              <li className="general-list-item">
                3.1 - Prioritize Local Scholarships
              </li>
              <li className="general-list-item">
                3.2 - Avoiding Common Pitfalls and Scams
              </li>
              <li className="general-list-item">
                3.3 - Organization and Time Management
              </li>
              <li className="general-list-item">
                3.4 - The Power of Persistence
              </li>
            </ul>
          </div>

          <div className="general-toc-item">
            <h3 className="general-toc-item-title">
              <Link
                to="/afford-college/scholarship/crafting-application"
                className="link"
              >
                Chapter 4: Crafting a Standout Application: Showcase Your
                Strengths and Shine
              </Link>
            </h3>
            <ul className="general-list">
              <li className="general-list-item">
                4.1 - The "Information About Yourself" Template
              </li>
              <li className="general-list-item">
                4.2 - Steps to Craft a Compelling Scholarship Essay
              </li>
              <li className="general-list-item">
                4.3 - Writing Essays That Win
              </li>
              <li className="general-list-item">
                4.4 - Securing Strong Recommendation Letters
              </li>
            </ul>
          </div>

          <div className="general-toc-item">
            <h3 className="general-toc-item-title">
              <Link
                to="/afford-college/scholarship/scholarship-interview"
                className="link"
              >
                Chapter 5: Mastering the Scholarship Interview: Confidence is
                Key
              </Link>
            </h3>
            <ul className="general-list">
              <li className="general-list-item">
                5.1 - Understanding the Interview Process
              </li>
              <li className="general-list-item">
                5.2 - Professional Attire: The Silent Communicator
              </li>
              <li className="general-list-item">
                5.3 - Strategies for Interview Success
              </li>
            </ul>
          </div>

          <div className="general-toc-item">
            <h3 className="general-toc-item-title">
              <Link
                to="/afford-college/scholarship/post-award-tips"
                className="link"
              >
                Chapter 6: Post-Award Success Strategies: How to Maximize the
                Benefits
              </Link>
            </h3>
            <ul className="general-list">
              <li className="general-list-item">6.1 - Expressing Gratitude</li>
              <li className="general-list-item">
                6.2 - Effective Management of Scholarship Funds
              </li>
              <li className="general-list-item">
                6.3 - Appeal for Cost of Attendance Increase
              </li>
            </ul>
          </div>



          */}
        </div>
      

{/*

        <div id="toc-conclusion-container">
          <div id="toc-conclusion-text-container">
            <div id="toc-conclusion-title-container">
              <h2 id="toc-conclusion-title">
                Our Recommended Scholarship Lists and Websites:{" "}
              </h2>
              <div id="toc-page-conclusion-underline"></div>
            </div>
            <p id="toc-conclusion-text-main">
              <p class="chapter-description">
                {" "}
                First, explore local scholarships. Once you’ve exhausted local
                options, expand your search to national scholarships and online
                resources. Here is a list of scholarships we have curated, along
                with some reputable websites to get you started:
              </p>

              <ul class="bullet-point-list">
                <li class="bullet-point">
                  <a
                    href="https://docs.google.com/spreadsheets/d/1_g5FWjag24UN9Fp7Jz3AbrUYVP7XKn71su9fCK5-pKM/edit?usp=sharing"
                    class="link"
                  >
                    CollegeNav’s Top Choices Spreadsheet (Year Round
                    Scholarships)<b> ❋❋❋</b>
                  </a>
                </li>
                <li class="bullet-point">
                  <a href="https://www.scholarshipamerica.org" class="link">
                    Scholarship America<b> ❋❋❋</b>
                  </a>
                </li>
                <li class="bullet-point">
                  <a href="https://www.mykaleidoscope.com" class="link">
                    Kaleidoscope<b> ❋❋❋</b>
                  </a>
                </li>
                <li class="bullet-point">
                  <a
                    href="https://bigfuture.collegeboard.org/scholarship-search"
                    class="link"
                  >
                    College Board Scholarship Website
                  </a>
                </li>
              </ul>
            </p>
          </div>
          <div id="toc-conclusion-end-decorator"></div>
        </div>

        */}

{/*
        <div id="toc-conclusion-container">
          <div id="toc-conclusion-text-container">
            <div id="toc-conclusion-title-container">
              <h2 id="toc-conclusion-title">Conclusion</h2>
              <div id="toc-page-conclusion-underline"></div>
            </div>
            <p id="toc-conclusion-text-main">
              Navigating the scholarship application process can be daunting,
              but with careful planning, persistence, and the right resources,
              you can maximize your chances of success. By leveraging the tips
              and strategies outlined in this guide, you'll be well-equipped to
              find and secure scholarships that align with your goals and needs.
              Remember to stay organized, be proactive, and continuously refine
              your application materials. With determination and effort, you can
              reduce the financial stress of college and focus on making the
              most of your educational journey. Start exploring your scholarship
              opportunities today and take a significant step toward achieving
              your academic dreams.
            </p>
          </div>
          <div id="toc-conclusion-end-decorator"></div>
        </div>

*/}

      </div>
    </div>
    
  );
};

export default Scholarship;
