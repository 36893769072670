import React from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import "./App.css";

// Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import useScrollToTop from "./components/hooks/useScrollToTop";
import SideNavbar from "./components/SideNavbar";
import SubNavContainer from "./components/SubNavContainer";
import CommentForm from "./components/comments/CommentForm";
import CommentList from "./components/comments/CommentList";
import LoginPrompt from "./components/LoginPrompt";

// Main Pages
import HomePage from "./pages/MainPage/HomePage";
import CollegePrep from "./pages/MainPage/CollegePrep";
import AffordCollege from "./pages/MainPage/AffordCollege";
import College from "./pages/MainPage/College";
import Programs from "./pages/MainPage/Programs";
import AboutUs from "./pages/MainPage/AboutUs";
import ContactUs from "./pages/MainPage/ContactUs";
import Tools from "./pages/MainPage/Tools";

// AffordCollege Subpages
import FinancialAid from "./pages/SubPage/AffordCollege/1.FinancialAid";
import Scholarship from "./pages/SubPage/AffordCollege/2.Scholarship";
import StudentWorkOpportunities from "./pages/SubPage/AffordCollege/3.StudentWorkOpportunities";

// Scholarship Guide Subpages
import UnderstandScholarship from "./pages/SubPage/AffordCollege/ScholarshipGuide/Ch1-UnderstandScholarship";
import ScholarshipSearchGuide from "./pages/SubPage/AffordCollege/ScholarshipGuide/Ch2-ScholarshipSearch";
import CraftApplication from "./pages/SubPage/AffordCollege/ScholarshipGuide/Ch4-CraftApplication";
import ScholarshipInterview from "./pages/SubPage/AffordCollege/ScholarshipGuide/Ch5-ScholarshipInterview";
import ApplicationTips from "./pages/SubPage/AffordCollege/ScholarshipGuide/Ch3-ApplicationTips";
import PostAwardTips from "./pages/SubPage/AffordCollege/ScholarshipGuide/Ch6-PostAwardTips";

// College Subpages
import BeforeCollege from "./pages/SubPage/College/1.BeforeCollege";
import CollegeTips from "./pages/SubPage/College/2.CollegeTips";
import SchoolBenefits from "./pages/SubPage/College/3.SchoolBenefits";
import StemMajorInternships from "./pages/SubPage/College/5.StemMajorInternships";
import Resume from "./pages/SubPage/College/6.Resume";

// College Prep Subpages
import HighSchoolRoadMap from "./pages/SubPage/CollegePrep/1.HighSchoolRoadMap";
import DifferentPaths from "./pages/SubPage/CollegePrep/2.DifferentPaths";
import Majors from "./pages/SubPage/CollegePrep/3.Majors";
import CollegeSearchGuide from "./pages/SubPage/CollegePrep/4.CollegeSearch";
import HighSchool from "./pages/SubPage/CollegePrep/5.HighSchool";
import CommunityCollege from "./pages/SubPage/CollegePrep/6.CommunityCollege";
import ActivitiesAndAchievements from "./pages/SubPage/CollegePrep/7.ActivitiesAndAchievements";
import ApplicationProcess from "./pages/SubPage/CollegePrep/8.ApplicationProcess";
import CollegeEssay from "./pages/SubPage/CollegePrep/9.CollegeEssay";
import LettersOfRecommendation from "./pages/SubPage/CollegePrep/10.LettersOfRecommendation";
import InternationalStudents from "./pages/SubPage/CollegePrep/11.InternationalStudents";

// Programs Subpages
import ResearchOpportunities from "./pages/SubPage/Programs/1.ResearchOpportunities";
import StudyAbroad from "./pages/SubPage/Programs/2.StudyAbroad";
import MentorshipPrograms from "./pages/SubPage/Programs/3.MentorshipPrograms";
import Conferences from "./pages/SubPage/Programs/4.Conferences";
import AcademicSTEMPrograms from "./pages/SubPage/Programs/5.AcademicStemPrograms";
import AllSTEMWorkOpportunities from "./pages/SubPage/Programs/6.AllStemWorkOpportunities";
import FreshmenSophomorePrograms from "./pages/SubPage/Programs/7.FreshmenAndSophmorePrograms";
import Apprenticeship from "./pages/SubPage/Programs/8.Apprenticeship";
import GovernmentPrograms from "./pages/SubPage/Programs/9.GovernmentPrograms";
import FreeLearningTools from "./pages/SubPage/Programs/10.FreeLearningtools";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import NotFound from "./components/NotFound";

// Tools Subpages
import Ai from "./pages/Tools/Ai";
import FinancialTracker from "./pages/Tools/FinancialTracker";
import GPACalculator from "./pages/Tools/GPACalculator";
import CollegeSearch from "./pages/Tools/CollegeSearch";
import ScholarshipSearch from "./pages/Tools/ScholarshipSearch";
import VirtualTour from "./pages/Tools/VirtualTour";
import InternationalStudentsTool from "./pages/Tools/InternationalStudentsTool";
import EarlyCareerSearch from "./pages/Tools/EarlyCareerSearch";

//FireBase Log In
import Login from "./components/auth/login";
import Signup from "./components/auth/signup";
import ResetPassword from "./components/auth/resetpassword";
import ChangePassword from "./components/auth/changepassword";

import Profile from "./pages/MainPage/Profile";
import PrivateRoute from "./components/PrivateRoute";

import { AuthProvider } from "./context/authContext";

function MainContent() {
  const location = useLocation();

  // List of valid paths where the SideNavbar should be displayed
  const validPaths = [
    "/afford-college/financial-aid",
    "/afford-college/scholarship",
    "/afford-college/student-work-opportunities",
    "/afford-college/scholarship/understanding-scholarship",
    "/afford-college/scholarship/scholarship-search-guide",
    "/afford-college/scholarship/application-tips",
    "/afford-college/scholarship/crafting-application",
    "/afford-college/scholarship/scholarship-interview",
    "/afford-college/scholarship/post-award-tips",
    "/college/before-college",
    "/college/college-tips",
    "/college/school-benefits",
    "/college/stem-internships",
    "/college/resume",
    "/college-prep/highschool-roadmap",
    "/college-prep/different-paths",
    "/college-prep/majors",
    "/college-prep/college-search-guide",
    "/college-prep/high-school",
    "/college-prep/community-college",
    "/college-prep/activities-and-achievements",
    "/college-prep/application-process",
    "/college-prep/college-essay",
    "/college-prep/letters-of-recommendation",
    "/college-prep/international-students",
    "/programs/research-opportunities",
    "/programs/study-abroad",
    "/programs/mentorship-programs",
    "/programs/tech-conferences",
    "/programs/academic-stem-programs",
    "/programs/all-stem-work-opportunities",
    "/programs/freshmen-sophomore-programs",
    "/programs/apprenticeship",
    "/programs/government-programs",
    "/programs/free-learning-tools",
  ];

  const shouldDisplaySideNavbar = validPaths.includes(location.pathname);
  const shouldDisplayComments = validPaths.includes(location.pathname);

  return (
    <div className="App">
      <Navbar />
      <div className="main-content">

{/*
        {shouldDisplaySideNavbar && <SideNavbar />}

*/}
        <div className="pages">
          <Routes>
            {/* Main Pages */}
            <Route path="/" element={<HomePage />} />
            <Route path="/college-prep" element={<CollegePrep />} />
            <Route path="/afford-college" element={<AffordCollege />} />
            <Route path="/college" element={<College />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/tools" element={<Tools />} />

            {/* Afford College */}
            <Route
              path="/afford-college/financial-aid"
              element={<FinancialAid />}
            />
            <Route
              path="/afford-college/scholarship"
              element={<Scholarship />}
            />
            <Route
              path="/afford-college/student-work-opportunities"
              element={<StudentWorkOpportunities />}
            />

            {/* Scholarship Guide */}
            <Route
              path="/afford-college/scholarship/understanding-scholarship"
              element={<UnderstandScholarship />}
            />
            <Route
              path="/afford-college/scholarship/scholarship-search-guide"
              element={<ScholarshipSearchGuide />}
            />
            <Route
              path="/afford-college/scholarship/application-tips"
              element={<ApplicationTips />}
            />
            <Route
              path="/afford-college/scholarship/crafting-application"
              element={<CraftApplication />}
            />
            <Route
              path="/afford-college/scholarship/scholarship-interview"
              element={<ScholarshipInterview />}
            />
            <Route
              path="/afford-college/scholarship/post-award-tips"
              element={<PostAwardTips />}
            />

            {/* College */}
            <Route path="/college/before-college" element={<BeforeCollege />} />
            <Route path="/college/college-tips" element={<CollegeTips />} />
            <Route
              path="/college/school-benefits"
              element={<SchoolBenefits />}
            />
            <Route
              path="/college/stem-internships"
              element={<StemMajorInternships />}
            />
            <Route path="/college/resume" element={<Resume />} />

            {/* College Prep */}
            <Route
              path="/college-prep/highschool-roadmap"
              element={<HighSchoolRoadMap />}
            />
            <Route
              path="/college-prep/different-paths"
              element={<DifferentPaths />}
            />
            <Route path="/college-prep/majors" element={<Majors />} />
            <Route
              path="/college-prep/college-search-guide"
              element={<CollegeSearchGuide />}
            />
            <Route path="/college-prep/high-school" element={<HighSchool />} />
            <Route
              path="/college-prep/community-college"
              element={<CommunityCollege />}
            />

            <Route
              path="/college-prep/activities-and-achievements"
              element={<ActivitiesAndAchievements />}
            />

            <Route
              path="/college-prep/application-process"
              element={<ApplicationProcess />}
            />
            <Route
              path="/college-prep/college-essay"
              element={<CollegeEssay />}
            />
            <Route
              path="/college-prep/letters-of-recommendation"
              element={<LettersOfRecommendation />}
            />
            <Route
              path="/college-prep/international-students"
              element={<InternationalStudents />}
            />

            {/* Programs */}
            <Route
              path="/programs/research-opportunities"
              element={<ResearchOpportunities />}
            />
            <Route path="/programs/study-abroad" element={<StudyAbroad />} />
            <Route
              path="/programs/mentorship-programs"
              element={<MentorshipPrograms />}
            />
            <Route
              path="/programs/tech-conferences"
              element={<Conferences />}
            />
            <Route
              path="/programs/academic-stem-programs"
              element={<AcademicSTEMPrograms />}
            />
            <Route
              path="/programs/all-stem-work-opportunities"
              element={<AllSTEMWorkOpportunities />}
            />
            <Route
              path="/programs/freshmen-sophomore-programs"
              element={<FreshmenSophomorePrograms />}
            />
            <Route
              path="/programs/apprenticeship"
              element={<Apprenticeship />}
            />
            <Route
              path="/programs/government-programs"
              element={<GovernmentPrograms />}
            />
            <Route
              path="/programs/free-learning-tools"
              element={<FreeLearningTools />}
            />

            {/* Tools */}
            <Route path="/tools/ai" element={<Ai />} />
            <Route
              path="/tools/financial-tracker"
              element={<FinancialTracker />}
            />
            <Route path="/tools/gpa-calculator" element={<GPACalculator />} />
            <Route path="/tools/college-search" element={<CollegeSearch />} />
            <Route
              path="/tools/scholarship-search"
              element={<ScholarshipSearch />}
            />
            <Route path="/tools/virtual-tour" element={<VirtualTour />} />
            <Route
              path="/tools/international-students-tool"
              element={<InternationalStudentsTool />}
            />
            <Route
              path="/tools/early-career-search"
              element={<EarlyCareerSearch />}
            />

            {/* Policies */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />

            {/* Other Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/login-prompt" element={<LoginPrompt />} />

            {/* Private Routes */}
            <Route element={<PrivateRoute />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/change-password" element={<ChangePassword />} />
            </Route>

            {/* Catch-All Route */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
      {shouldDisplayComments && (
        <div className="comments-section">
          <CommentList path={location.pathname.replace(/\//g, "_")} />
          <CommentForm path={location.pathname.replace(/\//g, "_")} />
        </div>
      )}
      <Footer />
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <AuthProvider>
        <MainContent />
      </AuthProvider>
    </BrowserRouter>
  );
}

const ScrollToTop = () => {
  useScrollToTop();
  return null;
};

export default App;
