import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function MaximizeScholarship() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />

        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 3</p>
            <p id = "sub-scholarship-ch-header-text">Maximizing Your Chances of Success</p>
            <p id = "sub-scholarship-ch-text-two">Strategic Tips for Winning</p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
           
          </div>

          <p id="base-page-introduction-text-main">
            This chapter explores strategies to significantly boost your
            application success rate and avoid common pitfalls.
          </p>
        </div>
      </div>

      <div id="whole-section-page-container">
        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Application Tips</strong>
            </p>
            <ul className="bullet-point-list">
              <p className="chapter-description">
                <strong>Start Locally:</strong>
              </p>
              <li className="bullet-point">
                Apply to local scholarships first to reduce competition and
                increase your chances of success.
              </li>
              <p className="chapter-description">
                <strong>Avoid Rushing and Spamming:</strong>
              </p>
              <li className="bullet-point">
                After applying to multiple scholarships, you may feel
                fatigued. Take some time to recharge, but make sure there
                are no deadlines coming up.
              </li>
              <li className="bullet-point">
                Never rush through applications; quality matters. The more
                polished your application, the greater your likelihood of
                success.
              </li>
              <p className="chapter-description">
                <strong>Application Tips:</strong>
              </p>
              <li className="bullet-point">
                <strong>If You Fit the Requirement, Just Apply:</strong>
                Don’t second-guess; if you meet the criteria, submit your
                application.
              </li>
              <li className="bullet-point">
                <strong>Turn in Scholarships Early:</strong> Start looking
                for spring scholarships now!
              </li>
              <li className="bullet-point">
                <strong>Don’t underestimate scholarships with unknown value:</strong> Don't underestimate scholarships that don't list how much they're worth. You might think some are worth $500 and it could actually be worth $10k renewable.
              </li>
              <li className="bullet-point">
                <strong>Prioritize ones you have the highest chances:</strong> Apply to as many as possible, but remember that it's not possible to apply to all of them. You only have so much time, so prioritize it wisely and focus on ones you actually have a serious shot at winning.
              </li>
              <li className="bullet-point">
                <strong>Complete FAFSA Early:</strong> Fill out your FAFSA as soon as it opens in January, because lots of other scholarships will ask for it.
              </li>
              <li className="bullet-point">
                <strong>Draft and Refine Your Essay:</strong> Draft your
                essays and get at least two people at school to look over
                them. Read it out loud. Make a draft that's longer than the
                essay supposed to be, and find bad parts to take out after
                finishing the draft.
              </li>
              <li className="bullet-point">
                <strong>Be Detailed in Your Essay:</strong> Be sure to
                include something in your essays about how the career you're
                going into will help the community. If you're undecided,
                just pick one to write about because they know that over 50%
                of students switch majors anyway. Obviously don't apply for
                scholarships specifically for a major you have no interest
                in, but if you're just deciding between a couple careers
                write about the one that is most in-tune with the
                scholarship.
              </li>
              <li className="bullet-point">
                <strong>Quality over Quantity:</strong> Focus on crafting
                strong applications for relevant scholarships rather than
                applying to a massive number of irrelevant ones.
              </li>
              <li className="bullet-point">
                <strong>Read Application Requirements Carefully:</strong>
                Ensure you meet all eligibility criteria before applying.
                Don't waste time on scholarships you can't qualify for.
              </li>
              <li className="bullet-point">
                <strong>Avoid Scholarship Scams:</strong> Be wary of
                websites that request application fees, guarantee
                scholarships, or no essay scholarship. Legitimate
                scholarships are free to apply for.
              </li>
              <li className="bullet-point">
                <strong>Additional Tips:</strong> Visit{" "}
                <a
                  className="link"
                  href="https://www.dvc.edu/student-services/financial-aid/scholarships/scholarship-tips.html"
                >
                  DVC Scholarship Tips
                </a>{" "}
                for more insights.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Avoiding Common Pitfalls and Scams</strong>
            </p>
            <p className="chapter-description">
              Be aware of scholarship scams that prey on students' financial
              anxieties.
            </p>
            <p className="chapter-description">
              <strong>Red Flags to Watch Out For:</strong>
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                Avoid "no essay" scholarships due to high competition.
              </li>
              <li className="bullet-point">
                Be wary of sketchy or untrustworthy websites.
              </li>
              <li className="bullet-point">
                Avoid scholarships requiring application fees.
              </li>
              <li className="bullet-point">
                Avoid scholarships with unclear eligibility and application
                processes.
              </li>
            </ul>
            <p className="chapter-description">
              <strong>Websites that didn't work for us:</strong>
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <a className="link" href="https://www.scholarships.com/">
                  Scholarships.com
                </a>
              </li>
              <li className="bullet-point">
                <a className="link" href="https://www.fastweb.com/">
                  Fastweb
                </a>
              </li>
              <li className="bullet-point">
                <a className="link" href="https://www.goingmerry.com/">
                  Going Merry
                </a>
              </li>
              <li className="bullet-point">
                <a className="link" href="https://www.scholarshipowl.com/">
                  Scholarship Owl
                </a>
              </li>
              <li className="bullet-point">
                <a className="link" href="https://bold.org/">
                  Bold.org
                </a>
              </li>
            </ul>
            <p className="chapter-description">
              <strong>Note:</strong> While these sites are popular, they may
              not yield significant results. Consider them after exploring
              suggested sources and providing scholarships.
            </p>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Organization and Time Management</strong>
            </p>

            <p className="chapter-description">
              <strong>
                Stay organized throughout the process! Here are some tips to
                ensure efficient time management:
              </strong>
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Create a spreadsheets:</strong> Use this template{" "}
                <a
                  className="link"
                  href="https://docs.google.com/spreadsheets/d/1_g5FWjag24UN9Fp7Jz3AbrUYVP7XKn71su9fCK5-pKM/edit?usp=sharing"
                >
                  Scholarship Tracker
                </a>{" "}
                to manage deadlines, requirements, and application progress.
              </li>
              <li className="bullet-point">
                <strong>Set Calendar Reminders:</strong> Set calendar
                reminders for application deadlines and scholarship events.
              </li>
              <li className="bullet-point">
                <strong>Prioritize Early Applications:</strong> Many
                scholarships have rolling deadlines, meaning they are
                awarded as applications are received. Applying early
                increases your chances.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>The Power of Persistence</strong>
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Keep Applying:</strong> Don’t get discouraged by
                rejections. Persistence is key.
              </li>
              <li className="bullet-point">
                <strong>Learn and Refine:</strong> Use each application as a
                learning experience to improve your materials.
              </li>
              <li className="bullet-point">
                <strong>Stay Motivated:</strong> Continuously refine your
                approach and keep your goals in sight.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaximizeScholarship;
