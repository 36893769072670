import React from "react";
import "../../css/Tools/Tools.css";

const InternationalStudentsTool = () => {
  return (
    <div className="tool-container">
      <h1 className="tool-title">International Students Tool</h1>
      <p className="tool-message">
        If you are an international student and want to find out more about studying in the U.S., you can explore colleges online by visiting International Students Website.
      </p>
      <a
        href="https://www.internationalstudent.com/"
        className="tool-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to International Students Website
      </a>
    </div>
  );
};

export default InternationalStudentsTool;
