import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import LinkedInIconAboutUs from "../../../assets/Icons/LinkedInIconAboutUs.png";

import international from "../../../assets/header-images/international.jpg";

function InternationalStudents() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={international} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Guidance for International Students </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            {" "}
            This chapter is dedicated to addressing the unique challenges and
            opportunities faced by international students. From understanding
            visa requirements to adjusting to new cultural environments, this
            guide aims to provide comprehensive support for international
            students aspiring to study abroad.{" "}
          </p>

          <p></p>

          <p id="introduction-text">
            <b>Note:</b> If you are interested in learning more as an
            international student
            <a className="link" href="https://www.internationalstudent.com/">
              {" "}
              click here{" "}
            </a>
            for more information.
          </p>
        </div>
      </div>
{/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">
              {" "}
              Chapter 11: Navigating the College Application Process for
              International Students
            </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">



      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Understanding Visa Requirements and Regulations</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Student Visas:</strong>
    </p>

    <li className="bullet-point">
      <strong>F-1 Visa:</strong> This visa is required for academic studies
      at accredited U.S. institutions, including universities and colleges.
      It's also necessary for students enrolling in English language
      programs. The F-1 allows for full-time academic study in degree or
      certificate programs.
    </li>
    <li className="bullet-point">
      <strong>M-1 Visa:</strong> The M-1 visa is for students pursuing
      vocational or technical training. It's valid for non-academic programs
      and is not transferable to academic study. Students on an M-1 visa
      must ensure their training program is directly related to the field
      they wish to work in.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Visa Application Process:</strong>
    </p>

    <li className="bullet-point">
      <strong>Secure Admission:</strong> Before applying for a student visa,
      you must first be accepted into a SEVP-certified U.S. school.
      Acceptance is the first step in obtaining the necessary documents for
      your visa.
    </li>
    <li className="bullet-point">
      <strong>Receive Form I-20:</strong> Once accepted, the school will
      provide you with Form I-20, which is your "Certificate of Eligibility
      for Nonimmigrant Student Status." You will need this form for your
      visa application and interview.
    </li>
    <li className="bullet-point">
      <strong>Pay SEVIS Fee:</strong> After receiving Form I-20, you must
      pay the SEVIS (Student and Exchange Visitor Information System) fee
      through the I-901 form. This fee supports the maintenance of your
      student records in SEVIS.
    </li>
    <li className="bullet-point">
      <strong>Complete DS-160:</strong> Fill out the online nonimmigrant visa
      application (Form DS-160), which includes personal, academic, and
      travel details.
    </li>
    <li className="bullet-point">
      <strong>Schedule a Visa Interview:</strong> Schedule your visa
      interview at the nearest U.S. Embassy or Consulate. Interviews are
      required for visa approval.
    </li>
    <li className="bullet-point">
      <strong>Prepare Documents:</strong> Gather all the necessary
      documentation for your visa interview, including Form I-20, DS-160
      confirmation page, passport, visa fee receipt, proof of financial
      support, and academic records.
    </li>
    <li className="bullet-point">
      <strong>Attend Visa Interview:</strong> Be prepared to explain your
      reasons for studying in the U.S., how you will finance your education,
      and demonstrate that you have strong ties to your home country and
      intend to return after completing your studies.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Tips for a Successful Visa Interview:</strong>
    </p>

    <li className="bullet-point">
      <strong>Be Honest:</strong> Ensure that all your responses are
      truthful and consistent with the information in your application. Any
      inconsistencies could result in denial of the visa.
    </li>
    <li className="bullet-point">
      <strong>Be Prepared:</strong> Know your application inside and out.
      Bring all the required documents and understand the details of your
      academic program and financial plan.
    </li>
    <li className="bullet-point">
      <strong>Be Confident:</strong> Clearly explain why you have chosen to
      study in the U.S., how it will benefit your future career, and how you
      plan to utilize your education once you return to your home country.
    </li>
  </ul>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>English Language Proficiency</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Testing Requirements</strong>
    </p>

    <li className="bullet-point">
      <strong>TOEFL (Test of English as a Foreign Language):</strong> This
      is the most widely accepted English language test by U.S. colleges and
      universities. The test evaluates reading, listening, speaking, and
      writing skills in English. Most institutions require a score between
      61 and 100+, with higher-ranked schools typically expecting higher
      scores. Some programs might have specific score requirements for each
      section of the test.
    </li>
    <li className="bullet-point">
      <strong>IELTS (International English Language Testing System):</strong>
      Increasingly recognized by U.S. institutions, the IELTS also measures
      English proficiency across listening, reading, writing, and speaking.
      Scores typically range from 6.0 to 7.5, depending on the institution.
      Many schools now accept the IELTS in addition to or instead of the
      TOEFL.
    </li>
    <li className="bullet-point">
      <strong>PTE (Pearson Test of English):</strong> A newer option for
      proving English proficiency, the PTE is increasingly accepted by U.S.
      institutions. It is entirely computer-based and assesses the four key
      language skills. Scores required typically range from 50 to 70,
      depending on the institution and program of study.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Preparation Tips</strong>
    </p>

    <li className="bullet-point">
      <strong>Practice Tests:</strong> Take regular practice tests to
      familiarize yourself with the structure, timing, and types of
      questions you'll encounter on the TOEFL, IELTS, or PTE. These can help
      identify areas that need improvement and build confidence for the real
      test day.
    </li>
    <li className="bullet-point">
      <strong>Language Courses:</strong> Enroll in English language courses
      either online or in-person. These courses can provide structured
      learning and direct feedback from instructors on areas where you need
      to improve, such as grammar, vocabulary, or pronunciation.
    </li>
    <li className="bullet-point">
      <strong>Language Labs and Tutoring:</strong> Utilize resources such as
      language labs and tutoring services, which are often provided by
      colleges or local community centers. These resources can help with
      improving specific language skills, such as listening comprehension or
      academic writing, and can be particularly useful if you need extra
      help in preparing for standardized tests.
    </li>
  </ul>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Financial Planning and Scholarships</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Cost Considerations:</strong>
    </p>

    <li className="bullet-point">
      <strong>Tuition Fees:</strong> Tuition can vary significantly between
      institutions. Private universities generally have higher fees than
      public ones, and tuition for international students is often higher
      than for domestic students. Be sure to review the full costs for each
      school you’re considering.
    </li>
    <li className="bullet-point">
      <strong>Living Expenses:</strong> Include not just housing and food,
      but also utilities, transportation, and other personal costs. Cities
      like New York or San Francisco will have higher living costs than
      smaller towns or rural areas, so factor this into your planning.
    </li>
    <li className="bullet-point">
      <strong>Health Insurance:</strong> International students are usually
      required to have health insurance. Costs vary based on the provider
      and the level of coverage, so research plans that fit your needs and
      budget.
    </li>
    <li className="bullet-point">
      <strong>Travel Expenses:</strong> In addition to flights, visa fees,
      and other travel costs, factor in the cost of trips home for holidays
      or emergencies, as well as local transportation costs while studying.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Scholarship Opportunities:</strong>
    </p>

    <li className="bullet-point">
      <strong>Government Scholarships:</strong> Many countries offer
      scholarships to help their citizens study abroad. Research government
      programs in your home country, as they can sometimes cover tuition,
      living expenses, and even travel costs.
    </li>
    <li className="bullet-point">
      <strong>College Scholarships:</strong> Many U.S. colleges and
      universities provide scholarships specifically for international
      students. Check each school's financial aid page to see what is
      available, and take note of the eligibility requirements and
      application deadlines.
    </li>
    <li className="bullet-point">
      <strong>Private Scholarships:</strong> Organizations like Fulbright,
      Rotary International, and the Ford Foundation offer scholarships for
      international students. These scholarships can be highly competitive,
      so apply early and thoroughly review the application requirements.
    </li>
    <li className="bullet-point">
      <strong>Work-Study Programs:</strong> Some schools allow international
      students to participate in work-study programs, where you can work
      part-time on campus to help finance your education. Be sure to check
      your eligibility and the application process, as these programs may
      have limited availability for international students.
    </li>
  </ul>
</div>
       

<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Cultural Adjustment</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Adjusting to New Educational Systems:</strong>
    </p>

    <li className="bullet-point">
      <strong>Classroom Culture:</strong> Be prepared for a more dynamic
      and participatory learning environment. U.S. classrooms often
      encourage active student engagement, where discussions, questions,
      and collaboration play a big role.
    </li>
    <li className="bullet-point">
      <strong>Teaching Styles:</strong> Expect a variety of teaching methods,
      including lectures, group projects, and interactive assignments. Some
      courses may emphasize hands-on learning through case studies and labs.
    </li>
    <li className="bullet-point">
      <strong>Student Responsibilities:</strong> Managing your time well is
      crucial. You'll need to stay on top of assignments, participate in
      discussions, and seek out help from professors or peers when necessary.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Social Integration:</strong>
    </p>

    <li className="bullet-point">
      <strong>Making Friends:</strong> One of the easiest ways to meet new
      people is by joining campus clubs, attending social events, or
      engaging in study groups. Universities often have many opportunities
      to connect with students who share similar interests.
    </li>
    <li className="bullet-point">
      <strong>Understanding Cultural Nuances:</strong> Take the time to learn
      about U.S. cultural customs, gestures, and conversational norms. This
      will help you feel more comfortable in social settings and avoid any
      unintentional misunderstandings.
    </li>
    <li className="bullet-point">
      <strong>Getting Involved:</strong> Participating in extracurricular
      activities like student organizations, volunteer work, or internships
      can help you build friendships, develop leadership skills, and feel
      more integrated into campus life.
    </li>
    <li className="bullet-point">
      <strong>Support Services:</strong> Make use of campus resources such as
      international student offices, counseling centers, and academic
      advisors to help with your adjustment. These services are designed to
      assist international students in navigating academic and cultural
      transitions.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Health and Well-being:</strong>
    </p>

    <li className="bullet-point">
      <strong>Mental Health Resources:</strong> Many colleges provide free or
      low-cost counseling services to support students' mental health. If
      you're feeling overwhelmed or homesick, don't hesitate to reach out
      for help.
    </li>
    <li className="bullet-point">
      <strong>Physical Health Services:</strong> Most campuses have student
      health centers where you can access medical care, advice on staying
      healthy, and vaccinations. Familiarize yourself with the services
      available to you.
    </li>
    <li className="bullet-point">
      <strong>Stay Connected:</strong> Maintaining regular contact with
      family and friends from home can help ease the transition and provide
      emotional support during challenging times.
    </li>
  </ul>
</div>
   
<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>5 Things to Know Before Coming to the US</strong>
    </p>
  </div>
  <p>
    Connect with this individual on LinkedIn for additional insights and
    guidance for international students:
    <a className="footer-category-pic" href="https://www.linkedin.com/in/sohansethi/">
      <img
        className="linkedin-image"
        src={LinkedInIconAboutUs}
        alt="LinkedIn"
      />
    </a>
  </p>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>1. Landing in the US is just the start of your struggles:</strong>
    </p>
    <li className="bullet-point">
      The real challenges often begin after your arrival. Adapting to a new
      culture, navigating the complexities of legal and academic systems, and
      dealing with homesickness can be more demanding than expected.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>2. Let alone America, currently no country is the land of opportunities:</strong>
    </p>
    <li className="bullet-point">
      Job prospects for international students are highly competitive, and
      securing employment can be one of the most difficult and stressful
      aspects of studying abroad.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>3. Education loans will become a burden you can't easily rid of:</strong>
    </p>
    <li className="bullet-point">
      With high interest rates on education loans and relatively low starting
      salaries, repaying student loans can quickly become overwhelming,
      leading to long-term financial strain.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>4. There will come days that will be the loneliest time of your life:</strong>
    </p>
    <li className="bullet-point">
      International students often face periods of loneliness, depression, and
      anxiety, especially when dealing with academic pressure, being far from
      family, and adjusting to an unfamiliar environment.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>5. Life will be very unpredictable, and you'll feel lost between two worlds:</strong>
    </p>
    <li className="bullet-point">
      Visa regulations, job uncertainty, and the constant balancing act
      between life back home and life in the US can create an ongoing sense of
      instability, making it difficult to feel fully settled in either place.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>However, on the other side:</strong>
    </p>
    <li className="bullet-point">
      Despite the challenges, life in the US can provide exposure to diverse
      cultures, new opportunities, and transformative experiences. It offers
      a chance to grow, build a future of freedom, and explore paths to
      personal and professional success in a way that can be deeply rewarding.
    </li>
  </ul>
</div>

       
<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Resources</strong>
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>1. Job Search Portals for Internships and Full-Time Jobs:</strong>
    </p>
    <li className="bullet-point">
      <a className="link" href="https://lnkd.in/eJkwtXpS" target="_blank">
        Handshake
      </a>
    </li>
    <li className="bullet-point">
      <a className="link" href="https://www.indeed.com" target="_blank">
        Indeed
      </a>
    </li>
    <li className="bullet-point">
      <a className="link" href="https://www.builtin.com" target="_blank">
        BuiltIn
      </a>
    </li>
    <p>
      These websites are essential for job and internship searches, in
      addition to LinkedIn. They offer numerous opportunities across
      industries, with Handshake often catering specifically to students
      and recent graduates. BuiltIn focuses on tech jobs, while Indeed
      provides a broad range of listings.
    </p>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>2. Websites to Find Jobs with H1-B Sponsorship:</strong>
    </p>
    <li className="bullet-point">
      <a className="link" href="https://h1bgrader.com/" target="_blank">
        H1BGrader
      </a>
    </li>
    <li className="bullet-point">
      <a className="link" href="https://h1bdata.info/" target="_blank">
        H1BData
      </a>
    </li>
    <p>
      For international students, securing job opportunities that provide
      H1-B visa sponsorship can be challenging. These websites help
      identify companies with a history of sponsoring H1-B visas, enabling
      you to align your job search with organizations that are open to
      hiring international candidates.
    </p>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>3. Networking:</strong>
    </p>
    <li className="bullet-point">
      <a className="link" href="https://www.meetup.com" target="_blank">
        Meetup
      </a>
    </li>
    <p>
      Networking is key to job success, especially for international
      students. Using Meetup, you can attend events and meet professionals
      in your field. These events help build connections, gain referrals,
      and discover job leads that may not be advertised online.
    </p>
  </ul>
</div>

{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 11: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Studying abroad as an international student presents unique
              challenges, but with careful planning and utilization of available
              resources, you can navigate the process successfully. From
              securing a visa to adapting to a new cultural environment, this
              guide provides the essential information you need to make your
              transition smoother. Embrace the opportunities and experiences
              that come with studying in a new country, and take advantage of
              the support systems available to help you succeed academically and
              personally.
            </p>
          </div>
        </div>

*/}
      </div>
    </div>
  );
}

export default InternationalStudents;
