import React, { useState, useEffect } from "react";
import { useAuth } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import "../../css/MainPage/Profile.css";
import { auth, storage, db } from "../../firebase/firebase";
import { updateProfileInformation } from "../../firebase/auth";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { getUserProfileData } from "../../firebase/firebaseService"; // Import the service

const Profile = () => {
  const { userLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    // Initial form data state
    name: "",
    email: "",
    profilePicture: "",
    dateOfBirth: "",
    university: "",
    graduationYear: "",
    educationalStatus: "",
    major: "",
    careerGoal: "",
    gender: "",
    race: "",
    location: { zipCode: "", city: "", state: "" },
    bio: "",
    linkedIn: "",
    demographics: [],
    gpa: "",
    testScores: [{ testName: "", score: "" }],
    activities: [{ activityName: "", description: "" }],
    householdIncome: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (userLoggedIn) {
      const fetchUserData = async () => {
        try {
          const userData = await getUserProfileData();
          if (userData) {
            setFormData((prevState) => ({
              ...prevState,
              ...userData,
              testScores: userData.testScores || [{ testName: "", score: "" }],
              activities: userData.activities || [
                { activityName: "", description: "" },
              ],
            }));
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      };

      fetchUserData();
    }
  }, [userLoggedIn]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      demographics: checked
        ? [...prevState.demographics, value]
        : prevState.demographics.filter((item) => item !== value),
    }));
  };

  const handleProfileUpdate = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error("No user is logged in");

      await updateProfileInformation(formData.name, formData.profilePicture);

      await setDoc(doc(db, "users", currentUser.uid), formData);

      setErrorMessage("");
      console.log("Profile updated successfully");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  const handlePasswordChange = () => {
    navigate("/change-password");
  };

  const handleProfilePictureUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const currentUser = auth.currentUser; // Use auth.currentUser to get the logged-in user
    if (!currentUser) {
      setErrorMessage("No user is logged in");
      return;
    }

    const storageRef = ref(
      storage,
      `profilePictures/${currentUser.uid}/${file.name}`
    );
    try {
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setFormData((prevState) => ({
        ...prevState,
        profilePicture: downloadURL,
      }));
    } catch (error) {
      console.error("Error uploading profile picture: ", error);
      setErrorMessage("Failed to upload profile picture");
    }
  };

  const handleBoxClick = (option) => {
    setFormData((prevState) => {
      const newDemographics = prevState.demographics.includes(option)
        ? prevState.demographics.filter((item) => item !== option)
        : [...prevState.demographics, option];

      return {
        ...prevState,
        demographics: newDemographics,
      };
    });
  };

  const handleTestScoreChange = (index, e) => {
    const { name, value } = e.target;
    const newTestScores = formData.testScores.map((score, i) =>
      i === index ? { ...score, [name]: value } : score
    );
    setFormData((prevState) => ({
      ...prevState,
      testScores: newTestScores,
    }));
  };

  const addTestScore = () => {
    setFormData((prevState) => ({
      ...prevState,
      testScores: [...prevState.testScores, { testName: "", score: "" }],
    }));
  };

  const removeTestScore = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      testScores: prevState.testScores.filter((_, i) => i !== index),
    }));
  };

  const handleZipCodeChange = async (e) => {
    const zipCode = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      location: { ...prevState.location, zipCode },
    }));

    if (zipCode.length === 5) {
      try {
        const response = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
        if (response.ok) {
          const data = await response.json();
          const { "place name": city, "state abbreviation": state } =
            data.places[0];
          setFormData((prevState) => ({
            ...prevState,
            location: { zipCode, city, state },
          }));
        }
      } catch (error) {
        console.error("Error fetching location data: ", error);
      }
    }
  };

  const handleActivityChange = (index, e) => {
    const { name, value } = e.target;
    const newActivities = formData.activities.map((activity, i) =>
      i === index ? { ...activity, [name]: value } : activity
    );
    setFormData((prevState) => ({
      ...prevState,
      activities: newActivities,
    }));
  };

  const addActivity = () => {
    setFormData((prevState) => ({
      ...prevState,
      activities: [
        ...prevState.activities,
        { activityName: "", description: "" },
      ],
    }));
  };

  const removeActivity = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      activities: prevState.activities.filter((_, i) => i !== index),
    }));
  };

  if (!userLoggedIn) {
    return (
      <div className="profile-message">Please log in to view your profile.</div>
    );
  }

  return (
    <div className="profile-container">
      <div className="profile">
        <h2>Your Profile</h2>
        <div className="profile-content">
          <div className="profile-section">
            <h3>
              Welcome back, <span className="user-name">{formData.name}</span>
            </h3>
            <div className="profile-picture">
              {formData.profilePicture && (
                <img src={formData.profilePicture} alt="Profile" />
              )}
              <input
                type="file"
                id="profile-picture-upload"
                onChange={handleProfilePictureUpload}
              />
              <label htmlFor="profile-picture-upload" className="upload-button">
                Upload New Picture
              </label>
            </div>
            <form className="profile-form">
              <h3>Basic Information</h3>
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="dateOfBirth">Date of Birth:</label>
                <input
                  type="date"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label htmlFor="educationalStatus">Educational Status:</label>
                <select
                  id="educationalStatus"
                  name="educationalStatus"
                  value={formData.educationalStatus}
                  onChange={handleInputChange}
                >
                  <option value="">Select...</option>
                  <option value="High School Student">
                    High School Student
                  </option>
                  <option value="Community College Student">
                    Community College Student
                  </option>
                  <option value="Undergrad Student">Undergrad Student</option>
                  <option value="Graduate Student">Graduate Student</option>
                  <option value="None">None of the above</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="bio">Bio:</label>
                <textarea
                  id="bio"
                  name="bio"
                  value={formData.bio}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="linkedIn">LinkedIn:</label>
                <input
                  type="url"
                  id="linkedIn"
                  name="linkedIn"
                  value={formData.linkedIn}
                  onChange={handleInputChange}
                />
              </div>

              <h3>Demographics</h3>
              <div className="form-group">
                <label htmlFor="gender">Gender:</label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Select...</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="race">Ethnicity:</label>
                <select
                  id="race"
                  name="race"
                  value={formData.race}
                  onChange={handleInputChange}
                >
                  <option value="">Select...</option>
                  <option value="White/Caucasian">White/Caucasian</option>
                  <option value="Black/African American">
                    Black/African American
                  </option>
                  <option value="Hispanic/Latino">Hispanic/Latino</option>
                  <option value="Asian">Asian</option>
                  <option value="Pacific Islander">Pacific Islander</option>
                  <option value="Native American">Native American</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="zipCode">Zip Code:</label>
                <input
                  type="text"
                  id="zipCode"
                  name="zipCode"
                  value={formData.location.zipCode}
                  onChange={handleZipCodeChange}
                />
              </div>
              {formData.location.city && formData.location.state && (
                <div className="form-group">
                  <label>Location:</label>
                  <p>
                    {formData.location.city}, {formData.location.state}{" "}
                    {formData.location.zipCode}
                  </p>
                </div>
              )}
              <div className="form-group-buttons">
                <label>Additional Demographics:</label>
                <div className="box-group">
                  {[
                    "First Generation Student",
                    "Immigrant Student",
                    "International Student",
                    "Low-Income Student",
                  ].map((option) => (
                    <div
                      key={option}
                      className={`box-option ${
                        formData.demographics.includes(option) ? "selected" : ""
                      }`}
                      onClick={() => handleBoxClick(option)}
                    >
                      {option}
                    </div>
                  ))}
                </div>
              </div>

              <h3>Education</h3>
              <div className="form-group">
                <label htmlFor="major">Major/Field of Study:</label>
                <input
                  type="text"
                  id="major"
                  name="major"
                  value={formData.major}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="university">University Name:</label>
                <input
                  type="text"
                  id="university"
                  name="university"
                  value={formData.university}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="graduationYear">Graduation Year:</label>
                <select
                  id="graduationYear"
                  name="graduationYear"
                  value={formData.graduationYear}
                  onChange={handleInputChange}
                >
                  <option value="">Select...</option>
                  {Array.from(
                    { length: 71 },
                    (_, i) => new Date().getFullYear() - 54 + i
                  ).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="gpa">
                  High School GPA/Community College GPA:
                </label>
                <input
                  type="text"
                  id="gpa"
                  name="gpa"
                  value={formData.gpa}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group-buttons">
                <label htmlFor="testScores">
                  Test Scores (SAT, AP Scores, IB Exams):
                </label>
                {Array.isArray(formData.testScores) &&
                  formData.testScores.map((testScore, index) => (
                    <div key={index} className="test-score">
                      <input
                        type="text"
                        name="testName"
                        placeholder="Test Name"
                        value={testScore.testName}
                        onChange={(e) => handleTestScoreChange(index, e)}
                      />
                      <input
                        type="text"
                        name="score"
                        placeholder="Score"
                        value={testScore.score}
                        onChange={(e) => handleTestScoreChange(index, e)}
                      />
                      <button
                        type="button"
                        className="remove-button"
                        onClick={() => removeTestScore(index)}
                      >
                        Remove
                      </button>


                      <button
                  type="button"
                  className="add-test-score-button"
                  onClick={addTestScore}
                >
                  Add Test Score
                </button>
                    </div>
                  ))}
                
              </div>
              <div className="form-group">
                <label htmlFor="careerGoal">Career Goal:</label>
                <input
                  type="text"
                  id="careerGoal"
                  name="careerGoal"
                  value={formData.careerGoal}
                  onChange={handleInputChange}
                />
              </div>

              <h3>Activities</h3>
              <div className="form-group-buttons">
                <label htmlFor="activities">
                  Your Activities/Extracurriculars:
                </label>
                {Array.isArray(formData.activities) &&
                  formData.activities.map((activity, index) => (
                    <div key={index} className="activity">
                      <input
                        type="text"
                        name="activityName"
                        placeholder="Activity Name"
                        value={activity.activityName}
                        onChange={(e) => handleActivityChange(index, e)}
                      />
                      <textarea
                        name="description"
                        placeholder="Description"
                        value={activity.description}
                        onChange={(e) => handleActivityChange(index, e)}
                      />

                      
                      <button
                        type="button"
                        className="remove-button"
                        onClick={() => removeActivity(index)}
                      >
                        Remove
                      </button>
                      
                      <button
                  type="button"
                  className="add-activity-button"
                  onClick={addActivity}
                >
                  Add Activity
                </button>
                
                    </div>

                    
                  ))}
                
              </div>

              {errorMessage && (
                <div className="error-message">{errorMessage}</div>
              )}
              <div className="button-group">
                <button type="button" onClick={handleProfileUpdate}>
                  Update Profile
                </button>
                <button type="button" onClick={handlePasswordChange}>
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
