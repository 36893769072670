import React, { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import {
  doCreateUserWithEmailAndPassword,
  doSignInWithGoogle,
} from "../../firebase/auth";
import "../../css/components/auth.css";

/**
 * Signup Component
 * Allows users to create a new account with email/password or Google.
 */
const Signup = () => {
  const [email, setEmail] = useState(""); // Email input state
  const [password, setPassword] = useState(""); // Password input state
  const [confirmPassword, setConfirmPassword] = useState(""); // Confirm password input state
  const [isRegistering, setIsRegistering] = useState(false); // Registering state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const [verificationMessage, setVerificationMessage] = useState(""); // Verification message state

  const { userLoggedIn } = useAuth(); // Get userLoggedIn state

  // Validate password (at least 6 chars, 1 uppercase, 1 number, 1 special char)
  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{6,}$/;
    return passwordRegex.test(password);
  };

  // Handle form submission for email/password signup
  const onSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (!isRegistering) {
      setIsRegistering(true); // Set registering state
      if (password !== confirmPassword) {
        setErrorMessage("Passwords do not match");
        setIsRegistering(false);
        return;
      }
      if (!validatePassword(password)) {
        setErrorMessage(
          "Password must be at least 6 characters long, include an uppercase letter, a number, and a special character."
        );
        setIsRegistering(false);
        return;
      }
      try {
        await doCreateUserWithEmailAndPassword(email, password); // Create user with email/password
        setVerificationMessage(
          "A verification email has been sent. Please check your email before logging in."
        );
        setIsRegistering(false);
      } catch (error) {
        setErrorMessage(error.message);
        setIsRegistering(false);
      }
    }
  };

  // Handle Google sign in
  const onGoogleSignIn = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (!isRegistering) {
      setIsRegistering(true); // Set registering state
      try {
        await doSignInWithGoogle(); // Sign in with Google
      } catch (error) {
        setErrorMessage(error.message);
        setIsRegistering(false);
      }
    }
  };

  return (
    <>
      {userLoggedIn && <Navigate to={"/profile"} replace={true} />}{" "}
      {/* Redirect if logged in */}
      <main className="auth-page">
        <div className="auth-container-original">
          <div className="auth-header-original">
            <div className="auth-title-original">
              <h3>Create a New Account</h3> {/* Page title */}
            </div>
          </div>
          <form onSubmit={onSubmit} className="auth-form-original">
            <div className="form-group-original">
              <label>Email</label>
              <input
                type="email"
                autoComplete="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Update email state
              />
            </div>

            <div className="form-group-original">
              <label>Password</label>
              <input
                type="password"
                autoComplete="new-password"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)} // Update password state
              />
            </div>

            <div className="form-group-original">
              <label>Confirm Password</label>
              <input
                type="password"
                autoComplete="off"
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)} // Update confirm password state
              />
            </div>

            {errorMessage && (
              <span className="error-message-original">{errorMessage}</span> // Display error message
            )}

            <button
              type="submit"
              disabled={isRegistering}
              className={`submit-button-original ${
                isRegistering ? "disabled-button-original" : ""
              }`}
            >
              {isRegistering ? "Signing Up..." : "Sign Up"} {/* Button text */}
            </button>
            <p className="auth-footer-agree-original">
              By signing up, you agree to our{" "}
              <Link to={"/terms-of-service"} className="link">
                terms of service
              </Link>{" "}
              and{" "}
              <Link to={"/privacy-policy"} className="link">
                privacy policy
              </Link>
              .
            </p>
          </form>
          <p className="auth-footer-original">
            Already have an account?{" "}
            <Link to={"/login"} className="link">
              Continue
            </Link>
          </p>
          <div className="separator-original">
            <div className="separator-line-original"></div>
            <div className="separator-text-original">OR</div>
            <div className="separator-line-original"></div>
          </div>
          <button
            disabled={isRegistering}
            onClick={onGoogleSignIn}
            className={`google-button-original ${
              isRegistering ? "disabled-button-original" : ""
            }`}
          >
            <svg
              className="google-icon-original"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_17_40)">
                <path
                  d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z"
                  fill="#4285F4"
                />
                <path
                  d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z"
                  fill="#34A853"
                />
                <path
                  d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z"
                  fill="#FBBC04"
                />
                <path
                  d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z"
                  fill="#EA4335"
                />
              </g>
              <defs>
                <clipPath id="clip0_17_40">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
            {isRegistering ? "Signing Up..." : "Continue with Google"}{" "}
            {/* Button text */}
          </button>
          {verificationMessage && (
            <div className="verification-message-original">
              {verificationMessage}
            </div> // Display verification message
          )}
        </div>
      </main>
    </>
  );
};

export default Signup;
