import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import navigating1 from "../../../assets/header-images/navigating1.jpg";

function ApplicationProcess() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={navigating1} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Navigating the Application Process </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            Navigating the college application process can be complex and
            overwhelming. This guide provides a step-by-step overview to help
            you manage the application timeline, understand common platforms,
            and ensure all components of your application are strong and
            well-organized. By following these guidelines, you can streamline
            the process and increase your chances of acceptance to your desired
            colleges.
          </p>
        </div>
      </div>
      {/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title"> Chapter 8: The Application Process</h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">


      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Timeline for College Applications</strong>
    </p>
    <p class="chapter-description">
      The college application process typically begins in your junior year and concludes with application submissions during your senior year. By following a well-planned timeline, you can stay on track and ensure you're prepared for each step. Here’s a more detailed breakdown:
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Junior Year</strong>
    </p>
    <li class="bullet-point">
      <strong>Research Colleges:</strong> Begin researching colleges that align with your academic goals, personal preferences, and career interests. Attend college fairs and meet with admissions representatives to gather important information and start considering your options.
    </li>
    <li class="bullet-point">
      <strong>Standardized Tests:</strong> Take standardized tests such as the SAT or ACT if required by your target colleges. Use this year to prepare and take the tests early, giving yourself time for retakes if needed.
    </li>
    <li class="bullet-point">
      <strong>College Visits:</strong> Schedule visits to potential colleges to get a firsthand look at the campus environment, facilities, and culture. This will help you assess whether the school feels like a good fit.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Summer Before Senior Year</strong>
    </p>
    <li class="bullet-point">
      <strong>Finalize College List:</strong> By the end of summer, narrow down your college list to a manageable number of schools that you plan to apply to. Be sure to include a balance of safety, target, and reach schools.
    </li>
    <li class="bullet-point">
      <strong>Request Transcripts:</strong> Start the process of requesting your academic transcripts to be sent to your prospective colleges. Doing this early ensures there are no delays later in the process.
    </li>
    <li class="bullet-point">
      <strong>Brainstorm Essay Topics:</strong> Begin brainstorming and drafting your college essays. Use this time to reflect on personal experiences that can make your application stand out.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Senior Year Fall</strong>
    </p>
    <li class="bullet-point">
      <strong>Application Essays:</strong> Dedicate time to refining and polishing your application essays. Be sure to seek feedback from teachers or mentors to ensure your essays are compelling and well-written.
    </li>
    <li class="bullet-point">
      <strong>Recommendation Letters:</strong> Request recommendation letters from teachers, counselors, or mentors who know you well. Be sure to give them plenty of time to write thoughtful letters that highlight your strengths.
    </li>
    <li class="bullet-point">
      <strong>Submit Applications:</strong> Submit your college applications by the early deadlines if you're applying through Early Action or Early Decision. Stay organized and double-check each application for accuracy.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Spring Semester</strong>
    </p>
    <li class="bullet-point">
      <strong>Follow Up on Applications:</strong> Track the status of your applications, ensuring that all required materials have been received by each college. Follow up on any outstanding items as necessary.
    </li>
    <li class="bullet-point">
      <strong>Compare Financial Aid Offers:</strong> Once you begin receiving acceptance letters, review and compare the financial aid packages offered by different schools. This can play a significant role in your final decision.
    </li>
    <li class="bullet-point">
      <strong>Make Enrollment Decision:</strong> After weighing your options, decide which college you will attend and notify the school of your decision by the enrollment deadline. Celebrate your hard work and look forward to the exciting journey ahead!
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Common Application Platforms</strong>
    </p>
    <p class="chapter-description">
      Several platforms are available to help streamline the college application process, making it easier to apply to multiple schools efficiently. Understanding these platforms can save you time and effort:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>The Common Application (Common App):</strong> The Common App is widely accepted by many colleges, allowing you to submit one application to multiple institutions. While this simplifies the process, it's essential to personalize each application by tailoring essays and supplemental materials to the specific colleges you're applying to. Make sure to meet individual school requirements even when using the Common App.
    </li>
    <li class="bullet-point">
      <strong>University-Specific Applications:</strong> Some colleges require their own unique applications that are not part of the Common App. Pay close attention to deadlines, requirements, and essay prompts for these schools to ensure you meet all necessary criteria. Be prepared to navigate different platforms and submit multiple applications if needed.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Components of a College Application</strong>
    </p>
    <p class="chapter-description">
      Each component of a college application serves a unique purpose and together, they present a comprehensive picture of who you are to admissions committees. Understanding how each piece contributes can help you build a well-rounded and compelling application:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Academic Transcripts:</strong> Your academic transcripts are one of the most important parts of your application, reflecting not only your grades but also the rigor of your coursework. Admissions committees will evaluate your academic performance over time and how you've challenged yourself in key subjects.
    </li>
    <li class="bullet-point">
      <strong>Standardized Test Scores (if required):</strong> While not all colleges require standardized test scores, strong SAT or ACT scores (if applicable) can help bolster your application. Keep in mind that many schools are moving toward test-optional policies, so focus on what best represents your academic abilities.
    </li>
    <li class="bullet-point">
      <strong>Personal Statement/Essays:</strong> Your essays are a chance to tell your personal story. Use them to highlight your passions, unique experiences, and what motivates you. A well-crafted essay can provide deeper insight into who you are beyond your grades and test scores.
    </li>
    <li class="bullet-point">
      <strong>Extracurricular Activities:</strong> Admissions committees look for well-rounded students who have pursued interests outside the classroom. Highlight your involvement in clubs, sports, community service, and leadership roles to show your potential for contributing to campus life.
    </li>
    <li class="bullet-point">
      <strong>Letters of Recommendation:</strong> Choose teachers, counselors, or mentors who know you well and can speak to both your academic abilities and personal character. Strong recommendations can provide additional context for your achievements and help differentiate you from other applicants.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Tracking Your Applications</strong>
    </p>
    <p class="chapter-description">
      Staying organized is crucial during the college application process. By creating a spreadsheet or Google Doc to track key details for each college, you can ensure nothing falls through the cracks. Here's what to include in your tracking document:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Application Deadlines:</strong> Record the early action, early decision, and regular decision deadlines for each college. Keeping track of these deadlines ensures you submit all materials on time and avoid missing any important dates.
    </li>
    <li class="bullet-point">
      <strong>Submission Dates:</strong> Log the exact dates when you submit each application. This will help you stay on top of your progress and ensure all applications are submitted before the deadline.
    </li>
    <li class="bullet-point">
      <strong>Interview Dates (if applicable):</strong> If a college requires or offers interviews, schedule and prepare in advance. Keeping track of these dates allows you to plan ahead and be fully prepared for the interview process.
    </li>
    <li class="bullet-point">
      <strong>Financial Aid Deadlines:</strong> Track the deadlines for financial aid forms such as the FAFSA, CSS Profile, and any college-specific financial aid applications. Submitting these forms on time can greatly impact your financial aid package.
    </li>
    <li class="bullet-point">
      <strong>Acceptance and Rejection Notices:</strong> Keep a log of the responses you receive from each college. This helps you track your overall progress and make informed decisions as acceptance letters start to come in.
    </li>
    <li class="bullet-point">
      <strong>Scholarship Applications:</strong> Track the submission dates and results for any scholarship applications you submit. This ensures you stay on top of potential funding opportunities and follow up on deadlines.
    </li>
  </ul>
</div>


        {/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 8: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Navigating the college application process can be overwhelming,
              but staying organized and adhering to a well-planned timeline can
              help you manage it effectively. Utilize common application
              platforms, understand each component of the application, and track
              your progress diligently. By following these steps, you can
              present a strong, comprehensive profile to admissions committees
              and increase your chances of acceptance to your desired colleges.
            </p>
          </div>
        </div>
*/}
      </div>
    </div>
  );
}

export default ApplicationProcess;
