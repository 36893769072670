import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import understanding from "../../../assets/header-images/understanding.jpg";

function FinancialAid() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={understanding} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Understanding Financial Aid </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            Understanding and securing financial aid is essential for many
            students to make higher education accessible and affordable. This
            guide covers various types of financial aid, application processes,
            and tips for managing student loan debt. Government programs,
            scholarships, grants, and work-study opportunities are designed to
            support students in achieving their academic goals while minimizing
            financial burdens.
          </p>
        </div>
      </div>

      <div id="whole-section-page-container">
        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Types of Financial Aid</strong>
            </p>
            <p className="chapter-description">
              Financial aid comes in several forms, each with its own
              application process and eligibility criteria. Here’s a
              breakdown of the main types:
            </p>
          </div>

          <ul className="bullet-point-list">
            <p className="chapter-sub-title">
              <strong>Grants:</strong> Need-based financial aid from federal
              and state governments or colleges. Grants do not need to be
              repaid.
            </p>
            <li className="bullet-point">
              <strong>Federal Pell Grant:</strong> Available to
              undergraduate students with financial need.
            </li>
            <li className="bullet-point">
              <strong>State-Specific Pell Grants:</strong> Check your
              state’s higher education website for available grants.
            </li>
            <li className="bullet-point">
              <strong>
                Federal Supplemental Educational Opportunity Grant (FSEOG):
              </strong>{" "}
              Additional grant for students with exceptional financial need.
            </li>
          </ul>

          <ul className="bullet-point-list">
            <p className="chapter-sub-title">
              <strong>Scholarships:</strong> Merit-based or need-based
              awards from colleges, private organizations, or corporations.
              Scholarships usually do not need to be repaid.
            </p>
            <li className="bullet-point">
              <strong>Academic Scholarships:</strong> Based on academic
              achievement.
            </li>
            <li className="bullet-point">
              <strong>Athletic Scholarships:</strong> For students with
              exceptional athletic skills.
            </li>
            <li className="bullet-point">
              <strong>Community Scholarships:</strong> For students involved
              in a certain community.
            </li>
          </ul>

          <ul className="bullet-point-list">
            <p className="chapter-sub-title">
              <strong>Work-Study Programs:</strong> Federal program allowing
              students to earn money for college expenses through part-time
              on-campus jobs.
            </p>
          </ul>

          <ul className="bullet-point-list">
            <p className="chapter-sub-title">
              <strong>Loans:</strong> Borrowed money that must be repaid
              with interest. Federal loans often offer lower interest rates
              than private loans.
            </p>
            <li className="bullet-point">
              <strong>Federal Direct Subsidized Loans:</strong> For
              undergraduate students with financial need. The government
              pays the interest while you’re in school.
            </li>
            <li className="bullet-point">
              <strong>Federal Direct Unsubsidized Loans:</strong> Available
              to all students regardless of need. Interest accrues while
              you’re in school.
            </li>
            <li className="bullet-point">
              <strong>Federal PLUS Loans:</strong> For parents of dependent
              students and for graduate/professional students.
            </li>
            <li className="bullet-point">
              <strong>Private Loans:</strong> From banks or other financial
              institutions. Generally have higher interest rates and less
              flexible repayment options.
            </li>
          </ul>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>How to Apply for Financial Aid</strong>
            </p>
            <p className="chapter-description">
              Applying for financial aid typically involves the following
              steps:
            </p>
          </div>

          <ul className="bullet-point-list">
            <li className="bullet-point">
              <strong>
                FAFSA (Free Application for Federal Student Aid):
              </strong>{" "}
              Submit the FAFSA as early as October 1st each year to maximize
              your eligibility for federal financial aid. The FAFSA
              determines your eligibility for federal grants, work-study,
              and loans.
              <br />
              <b>Website:</b>{" "}
              <a
                className="link"
                href="https://studentaid.gov/h/apply-for-aid/fafsa"
              >
                FAFSA Application
              </a>
            </li>
            <li className="bullet-point">
              <strong>CSS Profile:</strong> Many private colleges require
              the CSS Profile to award their own financial aid. Check each
              college’s requirements and deadlines.
              <br />
              <b>Website:</b>{" "}
              <a
                className="link"
                href="https://cssprofile.collegeboard.org/"
              >
                CSS Profile
              </a>
            </li>
          </ul>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>CSAC (WebGrants 4 Students)</strong>
            </p>
            <p className="chapter-description">
              The California Student Aid Commission (CSAC) offers resources
              to manage financial aid:
            </p>
          </div>

          <ul className="bullet-point-list">
            <li className="bullet-point">
              <strong>California Promise Grant:</strong> Financial
              assistance for eligible California community college students.
              <br />
              Website:{" "}
              <a className="link" href="https://mygrantinfo.csac.ca.gov/">
                California Promise Grant
              </a>
            </li>
          </ul>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Financial Aid for Undocumented Students</strong>
            </p>
            <p className="chapter-description">
              Undocumented students have access to specific financial aid
              programs, such as the California Dream Act:
            </p>
          </div>

          <ul className="bullet-point-list">
            <li className="bullet-point">
              <strong>California Dream Act:</strong> Provides state
              financial aid for eligible undocumented students in
              California.
              <br />
              Website:{" "}
              <a
                className="link"
                href="https://www.csac.ca.gov/undocumented-dreamer-students"
              >
                California Dream Act
              </a>
            </li>
          </ul>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Scholarships</strong>
            </p>
            <p className="chapter-description">
              We highly suggest exploring scholarship opportunities and
              application tips on{" "}
              <Link to="/afford-college/scholarship" className="link">
                Scholarships Page
              </Link>
              . Receiving even one scholarship can change your future.
            </p>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>
                Understanding and Comparing Financial Aid Packages
              </strong>
            </p>
            <p className="chapter-description">
              When you receive admission offers, carefully review and
              compare the financial aid packages:
            </p>
          </div>

          <ul className="bullet-point-list">
            <li className="bullet-point">
              <strong>Total Cost of Attendance:</strong> Look beyond the
              sticker price to understand the full cost.
            </li>
            <li className="bullet-point">
              <strong>Types of Aid:</strong> Compare the breakdown of
              grants, scholarships, loans, and work-study opportunities.
            </li>
            <li className="bullet-point">
              <strong>Loan Terms:</strong> Consider the interest rates and
              repayment terms of any loans.
            </li>
            <li className="bullet-point">
              <strong>Ask Questions:</strong> Contact the financial aid
              offices for clarification or to negotiate your aid package.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FinancialAid;
