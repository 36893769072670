import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import allstem from "../../../assets/header-images/allstem.jpg";

function AllStemWorkOpportunities() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={allstem} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> All STEM Work Opportunities </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>
      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            This page provides a comprehensive overview of various experiential
            learning opportunities for STEM students. Whether you're looking for
            internships, research projects, or volunteer work, you'll find
            valuable information here to help you gain practical experience and
            prepare for a successful career in STEM.
          </p>
          <p id="base-page-introduction-text-main">
            <b>Note:</b> If you are interested in internship in any of the areas
            below
            <a
              className="link"
              href="https://www.smc.edu/student-support/career-services/get-the-job/stem/stem-experiential-opportunities/"
            >
              {" "}
              click here{" "}
            </a>
            for more information.
          </p>
        </div>
      </div>

      <div id="sections-container">
        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>STEM Experiential Learning Opportunities</strong>
            </p>
            <p class="chapter-description">
              Experiential learning provides you, the student, the opportunity to
              expand your knowledge and apply your skills outside of the traditional
              classroom setting to better prepare you for a lifelong career. Here are
              the variety of ways to gain some experience as a STEM student.
            </p>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Opportunities Sorted by STEM Major/Department</strong>
            </p>
            <p class="chapter-description">
              Search for experiential learning opportunities by STEM major/department.
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Automotive Technology:</strong> Explore hands-on
                opportunities in automotive technology to gain practical skills and
                industry knowledge.
              </li>
              <li class="bullet-point">
                <strong>
                  Computer Information Systems (CIS) and Computer Science (CS):
                </strong>
                Discover internships, projects, and research opportunities in CIS and
                CS to enhance your technical expertise.
              </li>
              <li class="bullet-point">
                <strong>
                  Earth Science (Astronomy, Geology, GIS, Sustainable Technologies):
                </strong>
                Engage in fieldwork, research, and internships in various earth
                science disciplines.
              </li>
              <li class="bullet-point">
                <strong>General Science:</strong> Participate in a wide range of
                experiential learning activities across multiple scientific fields.
              </li>
              <li class="bullet-point">
                <strong>
                  Life Science (Biological Sciences and Environmental Science):
                </strong>
                Gain practical experience through lab work, field research, and
                internships in life sciences.
              </li>
              <li class="bullet-point">
                <strong>Math:</strong> Explore opportunities to apply mathematical
                theories and concepts in real-world scenarios.
              </li>
              <li class="bullet-point">
                <strong>
                  Physical Science (Chemistry, Engineering, Physics):
                </strong>
                Participate in research projects, internships, and applied learning
                experiences in physical sciences.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Opportunities Sorted by Career Focus Area</strong>
            </p>
            <p class="chapter-description">Search by Career Focus Area.</p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Education/Teaching:</strong> Pursue teaching assistantships,
                tutoring positions, and educational outreach programs in STEM.
              </li>
              <li class="bullet-point">
                <strong>Healthcare:</strong> Engage in internships, volunteer work,
                and research opportunities within the healthcare industry.
              </li>
              <li class="bullet-point">
                <strong>Non-profit/Government:</strong> Discover internships,
                fellowships, and volunteer positions in non-profit organizations and
                government agencies.
              </li>
              <li class="bullet-point">
                <strong>Research:</strong> Participate in cutting-edge research
                projects, lab work, and academic research programs.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>
                Opportunities Sorted by Applied and Service Learning, Internships, and
                Volunteer Experience
              </strong>
            </p>
            <p class="chapter-description">
              Search by Applied and Service Learning, Internships, and Volunteer
              Experience.
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Applied and Service Learning:</strong> Engage in
                community-based projects and service-learning opportunities that
                apply STEM knowledge to real-world problems.
              </li>
              <li class="bullet-point">
                <strong>Internships:</strong> Gain hands-on experience through
                internships in various STEM fields to enhance your professional skills
                and network.
              </li>
              <li class="bullet-point">
                <strong>Volunteer Work:</strong> Contribute to STEM-related volunteer
                activities and projects to gain experience and give back to the
                community.
              </li>
            </ul>
          </div>
        </div>

        {/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              This page provides an overview of the many experiential learning
              opportunities available to STEM students. By exploring the options
              listed here, you can find opportunities that align with your
              interests and career goals. For more detailed information and to
              find specific programs, internships, and research projects, please
              visit the{" "}
              <a
                className="link"
                href="https://www.smc.edu/student-support/career-services/get-the-job/stem/stem-experiential-opportunities/"
              >
                {" "}
                STEM Experiential Opportunities{" "}
              </a>{" "}
              page. This resource is designed to help you navigate and take
              advantage of the vast array of experiences that will enrich your
              education and prepare you for a successful career in STEM. Embrace
              these opportunities to expand your knowledge, develop your skills,
              and build a professional network that will support your future
              endeavors.
            </p>
          </div>
        </div>
        */}

      </div>
    </div>
  );
}

export default AllStemWorkOpportunities;
