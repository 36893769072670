import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import timeline from "../../../assets/inner-content-images-and-files/HSTimeLine.png";

import hsplanning from "../../../assets/header-images/hsplanning.jpg";

function HighSchool() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={hsplanning} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> High School Planning </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            Preparing for college is a journey that starts early in high school.
            Each year brings new opportunities and challenges, and it's
            important to make the most of them to build a strong foundation for
            your future. This guide provides year-by-year tips to help you
            navigate high school successfully, from freshman year through senior
            year, including the benefits of dual enrollment. By following these
            guidelines, you can enhance your academic and extracurricular
            achievements, making the college application process smoother and
            more successful.
          </p>
        </div>
      </div>

    {/*  <img id="timeline" src={timeline} /> */}



{/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">
              {" "}
              Chapter 5: Preparing for College: From High School to Higher
              Education{" "}
            </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">
        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>
                High School Course Requirements and Recommendations
              </strong>
            </p>
          </div>

          <ul class="bullet-point-list">
            <li class="bullet-point">
              <strong>Balance Core Subjects:</strong> Take a mix of core
              academic subjects (English, Math, Science, History) along with
              challenging electives that align with your interests and
              potential college major.
            </li>
            <li class="bullet-point">
              <strong>Consider Dual Enrollment:</strong> Enroll in dual
              enrollment programs from nearby community colleges to earn
              college credits while still in high school. This can give you
              a head start on meeting college course requirements and
              provide valuable experience in college-level coursework.
              Additionally, you can save tons of money since most community
              colleges courses are free for highschool students most of the
              times.
            </li>

            <li class="bullet-point">
              <strong>Advanced Courses:</strong> Consider Advanced
              Placement (AP) or International Baccalaureate (IB) courses for
              a competitive edge. These courses can provide college credits
              and demonstrate your ability to handle college-level work.
            </li>
            <li class="bullet-point">
              <strong>Specific College Requirements:</strong> Check the
              specific course requirements for your desired major at the
              colleges you're interested in. Some majors may require
              specific high school courses like foreign languages or
              advanced math.
            </li>
          </ul>
        </div>

        <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Developing Essential Skills: Sharpen Your Tools for Success
      </strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Time Management:</strong> Learn to effectively organize your time by using planners, calendars, and productivity apps. Mastering time management will help you balance academics, extracurriculars, and personal commitments, ensuring you stay on top of deadlines and responsibilities.
    </li>
    <li class="bullet-point">
      <strong>Communication:</strong> Strengthen both your written and verbal communication skills. Practice through writing workshops and public speaking events to clearly express your ideas, which will be valuable in academic and professional settings.
    </li>
    <li class="bullet-point">
      <strong>Critical Thinking and Problem Solving:</strong> Focus on building your ability to analyze information, identify solutions, and think creatively. Challenge yourself with difficult academic courses and engage in activities that encourage problem-solving, such as clubs or team projects.
    </li>
    <li class="bullet-point">
      <strong>Leadership:</strong> Take on leadership roles in clubs, sports teams, or community groups. By guiding others and taking responsibility, you'll demonstrate your ability to influence and inspire, skills that will serve you well in both college and your future career.
    </li>
  </ul>
</div>


{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 5: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
          </div>
        </div>

        <div id="ch-base-page-container">
          <div id="ch-base-page-text-container">
            <div id="ch-base-page-title-container">
              <h2 id="ch-base-page-title"> Chapter 6: Standardized Tests </h2>
              <div id="ch-base-page-underline"> </div>
            </div>
          </div>
        </div>

*/}
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Overview of SAT, ACT, AP Tests, and IB Tests
      </strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Standardized Tests:</strong>
    </p>
    <li class="bullet-point">
      <strong>Common Tests:</strong> The SAT and ACT are the primary tests used in U.S. college admissions. Additionally, some colleges may require or recommend SAT Subject Tests, AP exams, or IB exams, particularly if you're applying for competitive programs or majors.
    </li>
    <li class="bullet-point">
      <strong>Testing Policies:</strong> Confirm the testing requirements for each college you're interested in, as some schools have adopted test-optional policies or alternative admissions processes. Always verify if submitting scores is necessary or optional.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Test Preparation Tips and Resources: Conquering the Tests
      </strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Familiarize with Test Format:</strong> Use practice tests available online and in prep books to get comfortable with the test format and question types. Official practice tests from the College Board (for SAT) or ACT.org can be particularly useful in mimicking the real test experience.
    </li>
    <li class="bullet-point">
      <strong>Utilize Test Prep Resources:</strong> Explore a variety of test prep resources, including free options like Khan Academy for SAT prep or ACT Academy. For additional support, consider enrolling in preparation courses or using paid study guides to enhance your readiness.
    </li>
    <li class="bullet-point">
      <strong>Balanced Approach:</strong> While standardized test scores are important, remember that they are just one component of your application. Be sure to focus on strengthening other areas of your application, such as your extracurriculars, essays, and letters of recommendation.
    </li>
  </ul>
</div>


        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>
                Understanding Test Scores and Their Impact on Your
                Application
              </strong>
            </p>
          </div>

          <ul class="bullet-point-list">
            <li class="bullet-point">
              <strong>Role of Test Scores:</strong> Standardized test
              scores help admissions officers compare applicants from
              diverse educational backgrounds. High scores can enhance your
              application and potentially lead to merit-based scholarships
              and honors program invitations.
            </li>
            <li class="bullet-point">
              <strong>Balanced Perspective:</strong> High scores can
              strengthen your application, but a low score doesn't
              necessarily disqualify you. Strong academics, essays, and
              extracurriculars can compensate for lower scores, especially
              at test-optional colleges.
            </li>
          </ul>
        </div>

{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 6: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Preparing for college throughout high school is a process that
              involves careful planning, hard work, and taking advantage of
              available opportunities. By following these year-by-year tips, you
              can build a strong academic and extracurricular foundation, making
              the college application process smoother and more successful.
              Remember, the key to success lies in balancing your academic
              commitments with extracurricular activities, developing essential
              skills, and being proactive in your preparation for standardized
              tests. Utilize the resources available to you, seek guidance from
              counselors and mentors, and stay focused on your goals. Dual
              enrollment can provide a significant head start, and
              extracurricular activities showcase your well-rounded character.
              As you prepare, keep your future aspirations in mind and work
              towards achieving them step by step. Your efforts will pay off,
              leading to a fulfilling college experience and a bright future.
              Embrace each step of the journey, seek support when needed, and
              stay focused on your goals. Good luck on your path to college and
              beyond!
            </p>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default HighSchool;
