import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import mentorship from "../../../assets/header-images/mentorship.jpg";

function MentorshipPrograms() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={mentorship} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Mentorship Programs </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <div id="sub-nav-container">
          <SubNavContainer />
        </div>
      </div>
      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Mentorship programs connect students with experienced professionals,
            alumni, or peers who provide guidance, support, and advice. These
            programs can significantly enhance personal, academic, and career
            development by offering valuable insights and fostering meaningful
            relationships.
          </p>
        </div>
      </div>

      <div id="sections-container">
        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Benefits of Mentorship Programs</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Gaining industry insights and career advice.
              </li>
              <li class="bullet-point">
                Developing professional skills and networking opportunities.
              </li>
              <li class="bullet-point">
                Receiving personalized guidance and support.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Finding Mentorship Programs</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Identifying Opportunities:</strong>
              </p>
              <li class="bullet-point">
                <strong>University Programs:</strong> Many universities offer formal mentorship programs through career services, alumni associations, and academic departments. Check with these offices or clubs for available programs.
              </li>
              <li class="bullet-point">
                <strong>Professional Organizations:</strong> Join professional organizations related to your field of interest. These organizations often have mentorship programs and networking events.
              </li>
              <li class="bullet-point">
                <strong>Online Platforms:</strong> Utilize online platforms like LinkedIn and message Alumni from your university.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Researching Programs:</strong>
              </p>
              <li class="bullet-point">
                <strong>Program Options:</strong> Explore different types of mentorship programs, such as peer mentoring, faculty mentoring, and industry professional mentoring. Consider the format (e.g., one-on-one, group mentoring) and duration (e.g., short-term, long-term).
              </li>
              <li class="bullet-point">
                <strong>Mentor Profiles:</strong> Review profiles of potential mentors to find a good match based on your academic and career goals. Look for mentors with experience and expertise in areas that align with your interests.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Types of Mentorship Programs</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Peer Mentorship:</strong>
              </p>
              <li class="bullet-point">
                <strong>Overview:</strong> Peer mentorship programs pair students with more experienced peers who can provide guidance on academics, campus life, and personal development.
              </li>
              <li class="bullet-point">
                <strong>Benefits:</strong> Peer mentors offer relatable advice, support, and encouragement. These programs foster a sense of community and belonging.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Faculty Mentorship:</strong>
              </p>
              <li class="bullet-point">
                <strong>Overview:</strong> Faculty mentorship programs connect students with professors who provide academic guidance, research opportunities, and career advice.
              </li>
              <li class="bullet-point">
                <strong>Benefits:</strong> Faculty mentors can offer valuable insights into your field of study, help you navigate academic challenges, and support your professional development.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Professional Mentorship:</strong>
              </p>
              <li class="bullet-point">
                <strong>Overview:</strong> Professional mentorship programs match students with industry professionals who can offer career advice, networking opportunities, and industry insights.
              </li>
              <li class="bullet-point">
                <strong>Benefits:</strong> Professional mentors provide real-world experience, help you build a professional network, and guide you in making informed career decisions.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Example Programs</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>UCLA Mentorship Program:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Join a mentorship program at UCLA to receive guidance from alumni and professionals in your field of study.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong> 
                <a class="link" href="https://uclaone.com/">UCLA ONE</a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>CodePath:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Offers resource guides, technical mentorship, virtual workshops, and networking events to prepare college students for a career in tech.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a class="link" href="https://www.codepath.org/career-services">CodePath</a>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>How to Reach Out to a Mentor</strong>
            </p>
            <p class="referral-text">Hello [Mentor Name],</p>
            <p class="referral-text">
              I hope this message finds you well. My name is [Your Name] and I am a [Your Year] year undergraduate student at [Your University] studying computer science. I recently became a mentee of the [Program Name] program and as I was exploring different mentors in various fields, your profile stood out to me. Your long-term experience in the [Industry Name] industry, particularly your long-term commitment at [Mentor’s Company], caught my attention. I am reaching out to you with the hope of gaining insights into your journey in the tech field, especially your time at [Mentor’s Company], which aligns closely with my own aspirations. As a mentee, I am eager to seek advice and guidance from seasoned professionals like yourself. Learning from your experiences would be invaluable to me, and it has sparked my interest in potential opportunities at [Mentor’s Company]. I would be grateful for the opportunity to connect with you, hear your story, and receive any advice you may have for someone aspiring to follow a similar path. Your time and insights are highly appreciated. Thank you for considering my message, and I look forward to the possibility of connecting with you.
            </p>
            <p class="referral-text">Best regards, [Your Name]</p>
          </div>
          <p> 
            Note: Reaching out to someone you don't know and asking them to be your mentor may seem daunting, but receiving responses from even 2 out of 5 people is a success. Many individuals did respond to me using the provided template, demonstrating that it is possible. Take it slow initially, learn about one another, provide value, and build the relationship over 3-4 weeks. The right mentor can truly change your life.
          </p>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Engaging in a Mentorship</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Building the Relationship:</strong>
              </p>
              <li class="bullet-point">
                <strong>Initial Meeting:</strong> Schedule an initial meeting with your mentor to discuss your goals, expectations, and preferred communication methods. Establish a regular meeting schedule that works for both of you.
              </li>
              <li class="bullet-point">
                <strong>Open Communication:</strong> Maintain open and honest communication with your mentor. Share your progress, challenges, and any feedback you may have.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Setting Goals:</strong>
              </p>
              <li class="bullet-point">
                <strong>SMART Goals:</strong> Work with your mentor to set Specific, Measurable, Achievable, Relevant, and Time-bound (SMART) goals. These goals will provide a clear roadmap for your mentorship journey.
              </li>
              <li class="bullet-point">
                <strong>Action Plan:</strong> Develop an action plan with your mentor to achieve your goals. Outline specific steps, resources, and timelines.
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Maximizing the Experience:</strong>
              </p>
              <li class="bullet-point">
                <strong>Active Participation:</strong> Be proactive in your mentorship by seeking advice, asking questions, and taking initiative in discussions and activities.
              </li>
              <li class="bullet-point">
                <strong>Continuous Learning:</strong> Take advantage of the knowledge and experience your mentor offers. Be open to feedback and willing to learn from their insights.
              </li>
            </ul>
          </div>
        </div>

        {/*
        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              Engaging in a mentorship program can be a transformative
              experience that enhances your personal, academic, and career
              development. By connecting with experienced professionals, alumni,
              or peers, you gain valuable insights, build a robust support
              network, and develop the skills needed to achieve your goals.
              Explore the available mentorship programs, reach out to potential
              mentors, and make the most of these opportunities to foster your
              growth and success. Remember, the relationships you build through
              mentorship can have a lasting impact on your journey.
            </p>
          </div>
        </div>
        */}
      </div>
    </div>
  );
}

export default MentorshipPrograms;
