import React from "react";
import "../../css/MainPage/AboutUs.css";

import headerpic from "../../assets/header-images/headerpic.png";
import azaPic from "../../assets/inner-content-images-and-files/azaPic.png";
import willPic from "../../assets/inner-content-images-and-files/willPic.png";
import LinkedInIconAboutUs from "../../assets/Icons/LinkedInIconAboutUs.png";

import newazaPic from "../../assets/inner-content-images-and-files/fulati pic.png";
import newwillyPic from "../../assets/inner-content-images-and-files/willy pic.png";
import yarPic from "../../assets/inner-content-images-and-files/yarPic.png";

import about1 from "../../assets/header-images/about1.jpg";

function AboutUs() {
  return (
    <div id="about-background">
      <div id="about-TOC-face">
        <img id="about-TOC-face-pic" src={about1} />

        <div id="TOC-face-text-container">
          <div id="TOC-text-new-face">
            <p class="TOC-header-text-new"> About Us </p>
          </div>
        </div>
      </div>

      <div id="table-content-container-subs">
        <div id="smaller-toc-cont-subs">
          <h1 id="new-TOC-name-subs">Our Mission</h1>
        </div>

        <p id="new-toc-description-subs">
          {" "}
          At CollegeNav, our mission is to empower students with the resources,
          knowledge, and support they need to confidently navigate the college
          application process. We are dedicated to helping each student achieve
          their academic dreams by providing personalized guidance and tools.
          Our goal is to make higher education accessible and attainable for
          all, ensuring every student can realize their full potential.{" "}
        </p>
      </div>

      {/*
      <div id="about-story-container">
        <div id="about-story-top">
          <div id="about-story-title">Our Mission</div>
          <div id="about-story-title-underline"></div>
          <div id="about-story-text">
            At CollegeNav, our mission is to empower students with the
            resources, knowledge, and support they need to confidently navigate
            the college application process. We are dedicated to helping each
            student achieve their academic dreams by providing personalized
            guidance and tools. Our goal is to make higher education accessible
            and attainable for all, ensuring every student can realize their
            full potential.
          </div>
        </div>
      </div>

 */}

      <div id="team-container">
        <div className="team-member-container">
          <div className="about-pic-container">
            <img
              className="profile-picture"
              src={newazaPic}
              alt="Fulati Aizihaer"
            />
          </div>
          <div className="about-description-container">
            <div className="name">
              <strong>Fulati Aizihaer</strong>{" "}
            </div>
            <div className="about-position">Co-Founder</div>

            <div className="about-text-paragraph">
              Computer Science and Linguistics @ UCLA
            </div>

            <a className="" href="https://www.linkedin.com/in/fulati/">
              <img
                className="linkedin-image"
                src={LinkedInIconAboutUs}
                alt="LinkedIn"
              />
            </a>
          </div>
        </div>

        <div className="team-member-container">
          <div className="about-pic-container">
            <img
              className="profile-picture"
              src={newwillyPic}
              alt="Will Garcia"
            />
          </div>

          <div className="about-description-container">
            <div className="name">
              <strong>Will Garcia</strong>
            </div>

            <div className="about-position">Co-Founder</div>

            <div className="about-text-paragraph">
              Applied Math and Data Science @ UC Berkeley
            </div>

            <a className="" href="https://www.linkedin.com/in/wgarcia25/">
              <img
                className="linkedin-image"
                src={LinkedInIconAboutUs}
                alt="LinkedIn"
              />
            </a>
          </div>
        </div>

        <div className="team-member-container">
          <div className="about-pic-container">
            <img
              className="profile-picture"
              src={yarPic}
              alt="Khashayar Moradpour"
            />
          </div>
          <div className="about-description-container">
            <div className="name">
              <strong>Khashayar Moradpour</strong>{" "}
            </div>
            <div className="about-position">Software Engineer</div>

            <div className="about-text-paragraph">
              Computer Science @ SJSU
            </div>

            <a className="" href="https://www.linkedin.com/in/kmoradpour/">
              <img
                className="linkedin-image"
                src={LinkedInIconAboutUs}
                alt="LinkedIn"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
