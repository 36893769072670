import React from "react";
import { Link } from "react-router-dom";
import "../css/components/Footer.css"; // Assuming you have a CSS file for footer styles

import logo from "../assets/Logo/Logo2White.jpg";

import facebookIcon from "../assets/Icons/facebook-white.png";
import instagramIcon from "../assets/Icons/instagram-white.png";
import tiktokIcon from "../assets/Icons/tiktok-white.png";
import linkedinIcon from "../assets/Icons/linkedin-white.png";
import emailIcon from "../assets/Icons/email-white.png";
import contactusIcon from "../assets/Icons/contactus-white.png";

function Footer() {
  return (
    <div id="Footer">
      <div id="footer-left-container">
        <h1 id="footer-college">CollegeNav</h1>

        <p className="footer-category-title">
          <strong> Empowering Your Path to College Success </strong>
        </p>
      </div>

      <div id="footer-top-container">
        <div className="footer-category-new">
          <Link to="/contact-us" className="footer-category-item">
            <img
              className="footer-category-pic-two"
              src={contactusIcon}
              alt="Contact Us"
            />
          </Link>
          <p className="footer-category-title-new">
            <strong>
              Get In Touch With Us For Any Questions, Feedback, Or Support –
              We're Here To Help!
            </strong>
          </p>
        </div>

        <div className="footer-category-3">
          <p className="footer-category-title-3">
            <strong>Follow Us</strong>
          </p>
          <div className="footer-category-item-container">
            <Link
              to="https://www.linkedin.com/company/college-nav/"
              className="footer-category-item"
            >
              <img
                className="footer-category-pic"
                src={linkedinIcon}
                alt="LinkedIn"
              />
            </Link>

            <Link
              to="https://www.facebook.com/people/Collegenavinfo/61565326201320/"
              className="footer-category-item"
            >
              <img
                className="footer-category-pic"
                src={facebookIcon}
                alt="Meta"
              />
            </Link>

            <Link
              to="https://www.instagram.com/collegenav.info/"
              className="footer-category-item"
            >
              <img
                className="footer-category-pic"
                src={instagramIcon}
                alt="Instagram"
              />
            </Link>

            <Link
              to="https://www.tiktok.com/@collegenav"
              className="footer-category-item"
            >
              <img
                className="footer-category-pic"
                src={tiktokIcon}
                alt="TikTok"
              />
            </Link>
          </div>
        </div>

        <div className="footer-category-3">
          <p className="footer-category-title-3">
            <strong>Email Us</strong>
          </p>
          <p className="footer-category-title-last">
            collegenav.info@gmail.com
          </p>
        </div>
      </div>

      <div id="important-part-container">
        <p class="right-below-bar-footer">
          By using this website, you agree to our Terms of Use and Privacy
          Policy. The information provided is for informational purposes only
          and may not be fully accurate or complete. CollegeNav makes no
          guarantees regarding the accuracy or completeness of the information
          presented.
        </p>

        <div id="new-privacy-bottom-last-container">
          <div id="copyright">
            <p>© 2024 CollegeNav. All Rights Reserved.</p>
          </div>

          <div id="privacy-container">
            <Link to="/privacy-policy" className="privacy-item">
              Privacy Policy
            </Link>
            <p className="privacy-item"> | </p>
            <Link to="/terms-of-service" className="privacy-item">
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
