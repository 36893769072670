import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import '../css/components/SubNavContainer.css';

/**
 * SubNavContainer Component
 * Displays breadcrumb navigation based on the current URL path.
 */
function SubNavContainer() {
  const location = useLocation();
  const path = location.pathname.split('/').filter(Boolean); // Split the pathname into segments

  const mainPaths = {
    "college-prep": "College Prep",
    "afford-college": "Afford College",
    "college": "College Life",
    "programs": "Programs"
  };

  const subPaths = {
    "highschool-roadmap": "High School Roadmap",
    "different-paths": "Exploring Different Paths",
    "majors": "Choosing a Major",
    "college-search": "College Search",
    "high-school": "High School Planning",
    "community-college": "Community College Pathways",
    "activities-and-achievements": "Activities and Achievements",
    "application-process": "Navigating the Application Process",
    "college-essay": "Crafting Your College Essay",
    "letters-of-recommendation": "Securing Letters of Recommendation",
    "international-students": "Guidance for International Students",
    "financial-aid": "Understanding Financial Aid",
    "scholarship": "Scholarships",
    "student-work-opportunities": "Student Employment Opportunities",
    "financial-tracker": "Financial Aid Tracker",
    "before-college": "Preparing for College Life",
    "college-tips": "Essential College Tips",
    "school-benefits": "Maximizing School Benefits",
    "gpa-calculator": "GPA Calculator",
    "stem-internships": "STEM Internship Opportunities",
    "resume": "Crafting Your Resume",
    "research-opportunities": "Research Opportunities",
    "study-abroad": "Study Abroad Programs",
    "mentorship-programs": "Mentorship Programs",
    "tech-conferences": "Tech Conferences",
    "academic-stem-programs": "Academic STEM Programs",
    "all-stem-work-opportunities": "All STEM Work Opportunities",
    "freshmen-sophomore-programs": "Freshmen and Sophomore Programs",
    "apprenticeship": "Apprenticeship Programs",
    "government-programs": "Government Programs",
    "free-learning-tools": "Free Educational Resources",
    "understanding-scholarship": "CH 1 - Understanding Scholarships",
    "scholarship-search": "CH 2 - Preparing for the Scholarship Search",
    "application-tips": "CH 3 - Maximizing Your Chances of Success",
    "crafting-application": "CH 4 - Crafting a Standout Application",
    "scholarship-interview": "CH 5 - Mastering the Scholarship Interview",
    "post-award-tips": "CH 6 - Post-Award Success Strategies"
  };

  // Render breadcrumbs for the current path
  const renderBreadcrumbs = (segments, baseIndex = 0) => {
    return segments.map((segment, index) => {
      const routeTo = `/${segments.slice(0, index + baseIndex + 1).join('/')}`;
      const name = mainPaths[segment] || subPaths[segment];

      if (!name) return null; // Skip segments without a name

      return (
        <React.Fragment key={index}>
          {index > 0 && <p className="sub-nav-slash">/</p>}
          <Link to={routeTo} className="sub-nav-item">
            <strong>{name}</strong>
          </Link>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="sub-nav-container">
      <div className="main-sub-nav">
        {renderBreadcrumbs(path, 0)} {/* Render breadcrumbs */}
      </div>
    </div>
  );
}

export default SubNavContainer;
