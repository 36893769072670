import React from 'react';
import "../../css/Tools/Tools.css";

const ScholarshipList = () => {
  return (
    <div className="tool-container">
      <h1 className="tool-title">Scholarship Search</h1>
      <p className="tool-message">
        Our scholarship search tool is currently a work in progress. In the meantime, please visit the College Board's Scholarship Search page.
      </p>
      <a 
        href="https://bigfuture.collegeboard.org/scholarship-search"
        className="tool-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        Go to College Board's Scholarship Search
      </a>
    </div>
  );
};

export default ScholarshipList;
