import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import Paths1 from "../../../assets/header-images/Paths1.jpg";

function DifferentPaths() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={Paths1} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Exploring Different Paths </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            Before diving into the details of the college application process,
            it's crucial to step back and consider whether pursuing higher
            education is the right decision for you. This foundational decision
            will shape your future career, financial health, and personal
            growth. Here’s a comprehensive approach to help you evaluate if
            college aligns with your goals and circumstances.
          </p>
        </div>
      </div>

      <div id="whole-section-page-container">


        
      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Understanding the Benefits of a College Education
      </strong>
    </p>
    <p class="chapter-description">
      Attending college offers several key advantages that can significantly enhance both your professional and personal life:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Expanded Career Opportunities:</strong> Many professions require a college degree as a basic qualification, which opens doors to a wider range of career paths. Having a degree often makes you more competitive in the job market, increasing your chances of securing desirable positions.
    </li>
    <li class="bullet-point">
      <strong>Potential for Higher Earnings:</strong> College graduates typically earn more than individuals with only a high school diploma, resulting in higher lifetime earnings. This financial advantage can lead to greater economic stability and access to better opportunities.
    </li>
    <li class="bullet-point">
      <strong>Rich Networking Opportunities:</strong> College provides the chance to connect with peers, professors, and alumni who can offer mentorship, guidance, and job opportunities. Building a strong professional network during college can be invaluable throughout your career.
    </li>
    <li class="bullet-point">
      <strong>Development of Key Skills:</strong> College helps you develop critical skills such as problem-solving, critical thinking, and effective communication. These skills are essential in almost every profession and will serve you well in both your personal and professional life.
    </li>
  </ul>
</div>



<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Considering the Alternatives</strong>
    </p>
    <p class="chapter-description">
      College isn’t the only path to a successful career. There are alternative options that can lead to rewarding professions, often with lower costs and quicker entry into the workforce:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Vocational Schools and Technical Training:</strong> These programs provide focused training for careers in fields like healthcare, technology, and the trades, offering a direct path to skilled jobs without requiring a four-year degree.
    </li>
    <li class="bullet-point">
      <strong>Apprenticeships:</strong> Apprenticeships combine hands-on training with classroom instruction, allowing you to earn money while learning a trade like electricians or mechanics, providing a practical route into well-paying careers.
    </li>
    <li class="bullet-point">
      <strong>Entrepreneurship:</strong> Starting your own business can be a rewarding option for those with a viable idea and the determination to pursue it. Entrepreneurship allows you to create your own career path but requires dedication and hard work.
    </li>
    <li class="bullet-point">
      <strong>Immediate Workforce Entry:</strong> Some careers value practical experience over formal education. Entering the workforce directly after high school can help you gain early experience and opportunities for career advancement.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Evaluating Your Career Goals</strong>
    </p>
    <p class="chapter-description">
      Consider your interests, strengths, and career aspirations to determine whether pursuing a college degree is the right path for you:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Interest and Passion Alignment:</strong> Think about the subjects or activities you are most passionate about. Research how these align with different career paths and whether a college education or an alternative route, like vocational training, best supports your goals.
    </li>
    <li class="bullet-point">
      <strong>Career Requirements:</strong> Explore the specific qualifications your chosen career requires, such as degrees, certifications, or specialized training. Consider the time and effort involved in meeting these requirements and how they fit with your long-term goals.
    </li>
    <li class="bullet-point">
      <strong>Professional Guidance:</strong> Reach out to professionals in your field of interest to learn about the educational and career paths they followed. Their insights can help you make an informed decision about whether a college degree is necessary for your career success.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Assessing Financial Implications</strong>
    </p>
    <p class="chapter-description">
      The financial commitment of attending college is significant and requires careful evaluation to ensure it aligns with your long-term goals:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Tuition and Living Expenses:</strong> Assess the full cost of attending college, including tuition, textbooks, housing, and daily living expenses. Compare these costs with your ability to pay through personal savings, financial aid, scholarships, or student loans, and consider how they will impact your financial future.
    </li>
    <li class="bullet-point">
      <strong>Return on Investment (ROI):</strong> Weigh the potential increase in lifetime earnings a degree could provide against the upfront cost of obtaining that degree. Understanding the long-term financial benefits versus the immediate costs can help determine if college is a good investment for you.
    </li>
    <li class="bullet-point">
      <strong>Opportunity Costs:</strong> Consider what you might be giving up by attending college, such as earning an income through full-time work right away. This is particularly important if your chosen field values practical work experience over formal education.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Personal Readiness and Life Circumstances</strong>
    </p>
    <p class="chapter-description">
      Your personal readiness and current life circumstances play a key role in determining whether pursuing a college education is the right decision for you:
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Academic Preparedness:</strong> Take an honest look at your academic strengths and weaknesses to assess whether you're ready to handle the intellectual challenges of college. If you're unsure, consider taking steps to strengthen your skills before committing.
    </li>
    <li class="bullet-point">
      <strong>Life Responsibilities:</strong> Reflect on any personal or family obligations that could impact your ability to dedicate time and energy to college. Balancing responsibilities like work, caregiving, or financial pressures might affect your readiness to focus on full-time education.
    </li>
    <li class="bullet-point">
      <strong>Emotional and Social Maturity:</strong> College life demands a high degree of independence, self-discipline, and resilience. Ensure that you feel emotionally prepared to handle the academic pressures and social adjustments that come with transitioning to college life.
    </li>
  </ul>
</div>


        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>
                Types of Higher Education Institutions: A World of Choices
              </strong>
            </p>
          </div>

          <ul class="bullet-point-list">
            <li class="bullet-point">
              <strong>Community Colleges:</strong> These institutions offer
              two-year associate degree programs, which can focus on academic
              transfer courses or skills-based vocational training. They are an
              affordable entry point into higher education, with smaller class
              sizes and a focus on teaching.
            </li>
            <li class="bullet-point">
              <strong>Universities:</strong> Larger than community colleges,
              universities offer a broad spectrum of undergraduate and graduate
              programs. They may be classified into research universities, which
              emphasize research and offer a wide range of bachelor's, master's,
              and doctoral programs, and teaching universities, which focus
              primarily on undergraduate education.
            </li>
          </ul>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>
                Understanding Degrees: The Stepping Stones to Your Future
              </strong>
            </p>
          </div>

          <ul class="bullet-point-list">
            <li class="bullet-point">
              <strong>Associate’s Degrees (avg 2 years):</strong> Typically
              completed in two years and are available at community colleges.
              Students can choose from an Associate of Arts (AA) or Associate of
              Science (AS) degree, which are designed to prepare students for
              transfer to a four-year university, or an Associate of Applied
              Science (AAS), which prepares students for direct entry into the
              workforce.
            </li>
            <li class="bullet-point">
              <strong>Bachelor’s Degrees (avg 4 years):</strong> Require four
              years of study and are offered in fields like Arts (BA), Science
              (BS), and Fine Arts (BFA). They are foundational for professional
              entry and are prerequisites for advanced studies in various
              disciplines.
            </li>
            <li class="bullet-point">
              <strong>Master’s Degrees (avg 2 years):</strong> Typically require
              two years of study beyond a bachelor's degree. They are offered in
              a wide range of fields and are designed to provide advanced
              knowledge and skills in a specific area of study. Common types of
              master's degrees include Master of Arts (MA), Master of Science
              (MS), Master of Business Administration (MBA), and Master of Fine
              Arts (MFA). These degrees often involve a combination of
              coursework and research, and may require a thesis or capstone
              project.
            </li>
            <li class="bullet-point">
              <strong>PhD (Doctor of Philosophy) Degrees (avg 4-7 years):</strong>{" "}
              Research-intensive and can take between four to seven years to
              complete. They are the highest academic degrees awarded by
              universities and require the completion of original research that
              contributes new knowledge to the field. PhD candidates must pass
              comprehensive exams and defend their dissertation before a panel
              of experts. PhD degrees are available in numerous disciplines,
              including the humanities, sciences, social sciences, and
              engineering, and they prepare graduates for careers in academia,
              research, and specialized professional fields.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DifferentPaths;
