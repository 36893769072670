import React, { useState } from "react";
import "../../css/MainPage/ContactUs.css";
import facebookIcon from "../../assets/Icons/facebook-black.png";
import instagramIcon from "../../assets/Icons/instagram-black.png";
import tiktokIcon from "../../assets/Icons/tiktok-black.png";
import linkedinIcon from "../../assets/Icons/linkedin-black.png";

const CONTACTUS_URL = process.env.REACT_APP_CONTACTUS_URL;
const CONTACTUS_EMAIL_KEY = process.env.REACT_APP_CONTACTUS_EMAIL_KEY;

function ContactUs() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsButtonDisabled(true); // Disable the button when clicked

    const formData = new FormData(event.target);
    formData.append("access_key", CONTACTUS_EMAIL_KEY);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const response = await fetch(CONTACTUS_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });

      const res = await response.json();

      if (res.success) {
        setIsSubmitted(true);
        setErrorMessage(""); // Clear any previous error message
      } else {
        throw new Error(res.message || "Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setErrorMessage(
        "There was an error submitting the form. Please try again later."
      );
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false); // Re-enable the button after 3 seconds
      }, 3000);
    }
  };

  return (
    <div id="contact-body">
      <div className="contact-container">
        {isSubmitted ? (
          <p className="successMessage">
            Thank you for your message! We will get back to you soon.
          </p>
        ) : (
          <>
            <h1 className="title">Contact Us</h1>
            <p className="introText">
              We'd love to hear from you! Whether you have questions, feedback,
              or need assistance, feel free to reach out to us through any of
              the following methods.
            </p>

            <h2 className="subTitle">Get in Touch</h2>
            <p className="contactInfo">
              Email:{" "}
              <a className="link" href="mailto:collegenav.info@gmail.com">
                collegenav.info@gmail.com
              </a>
            </p>

            <h2 className="subTitle">Contact Form</h2>

            <form className="form" onSubmit={onSubmit}>
              <div className="full_name">
                <div className="input-group">
                  <label className="label">
                    First Name <span class="required"> *</span>{" "}
                  </label>
                  <input
                    className="contact_input"
                    type="text"
                    name="first_name"
                    required
                  />
                </div>
                <div className="input-group">
                  <label className="label">
                    Last Name <span class="required"> *</span>
                  </label>
                  <input
                    className="contact_input"
                    type="text"
                    name="last_name"
                    required
                  />
                </div>
              </div>

              <label className="label">
                Email Address <span class="required"> *</span>
              </label>
              <input
                className="contact_input"
                type="email"
                name="email"
                required
              />

              <label className="label">University Name</label>
              <input className="contact_input" type="text" name="university" />

              <label className="label">Major</label>
              <input className="contact_input" type="text" name="major" />

              <label className="label">
                Subject of the Message <span class="required"> *</span>
              </label>
              <input
                className="contact_input"
                type="text"
                name="subject"
                required
              />

              <label className="label">
                Message <span class="required"> *</span>
              </label>
              <textarea className="textarea" name="message" required></textarea>

              <button
                className="contact_button"
                type="submit"
                disabled={isButtonDisabled}
              >
                Send Message
              </button>
              {errorMessage && <p className="error">{errorMessage}</p>}
            </form>

            <h2 className="subTitle">Social Media</h2>
            <p className="introText">Stay connected with us on social media:</p>
            <ul className="socialMediaList">
              <div className="footer-category-item-container">
                <li className="footer-category-item">
                  <a
                    className="socialMediaLink"
                    href="https://www.linkedin.com/company/college-nav/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer-category-pic"
                      src={linkedinIcon}
                      alt="Linked In"
                    />
                  </a>
                </li>
                <li className="footer-category-item">
                  <a
                    className="socialMediaLink"
                    href="https://www.facebook.com/people/Collegenavinfo/61565326201320/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer-category-pic"
                      src={facebookIcon}
                      alt="Facebook"
                    />
                  </a>
                </li>
                <li className="footer-category-item">
                  <a
                    className="socialMediaLink"
                    href="https://www.instagram.com/collegenav.info/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer-category-pic"
                      src={instagramIcon}
                      alt="Instagram"
                    />
                  </a>
                </li>
                <li className="footer-category-item">
                  <a
                    className="socialMediaLink"
                    href="https://www.tiktok.com/@collegenav"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="footer-category-pic"
                      src={tiktokIcon}
                      alt="TikTok"
                    />
                  </a>
                </li>
              </div>
            </ul>

            <h2 className="subTitle">FAQs</h2>
            <p className="introText">
              Check out our{" "}
              <a className="faqLink" href="#">
                FAQs page
              </a>{" "}
              for answers to common questions. If you don't find what you're
              looking for, don't hesitate to reach out.
            </p>

            <h2 className="subTitle">Help Center</h2>
            <p className="introText">
              For immediate assistance, visit our{" "}
              <a className="helpCenterLink" href="#">
                Help Center
              </a>{" "}
              for resources and support.
            </p>

            <p className="introText">We look forward to assisting you!</p>
          </>
        )}
      </div>
    </div>
  );
}

export default ContactUs;
