import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import Roadmap1 from "../../../assets/header-images/Roadmap1.jpg";

function HighSchoolRoadMap() {
  return (
    <div id="newest-background">

      <div id="Ch-face">
        <img id="header-image" src={Roadmap1} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> High School Roadmap </p>
          </div>
        </div>


      </div>


      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            Preparing for college is a journey that starts early in high school.
            Each year brings new opportunities and challenges, and it's
            important to make the most of them to build a strong foundation for
            your future. This guide provides year-by-year tips to help you
            navigate high school successfully, from freshman year through senior
            year, including the benefits of dual enrollment.
          </p>
        </div>
      </div>

      <div id="whole-section-page-container">

        
          

          
      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Prepare for College as a Freshman in High School
      </strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>
          Choose a Challenging but Balanced Course Load:
        </strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Core and Elective Mix: Take a diverse mix of core subjects alongside electives that align with your interests and strengths. This balance will help you excel academically while exploring your passions.
          </li>
          <li class="bullet-point">
            Plan for Advanced Courses: Map out your high school career with an eye on enrolling in Honors, accelerated, or AP courses during your junior and senior years. This early planning will keep your options open.
          </li>
          <li class="bullet-point">
            AP Courses: AP courses are designed to mirror university-level content and offer an excellent opportunity to earn college credit while still in high school, giving you a head start on your degree.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Think About Dual Enrollment:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Get a Head Start: Dual Enrollment enables you to take college-level courses while still in high school, allowing you to earn both high school and college credits. It’s a great way to begin building your academic resume early. Learn more about this option at the bottom of the page.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Meet Core Requirements:</strong> Make sure you're fulfilling your school’s core graduation requirements early:
        <ul class="bullet-point-list">
          <li class="bullet-point">Four years of English</li>
          <li class="bullet-point">
            Three years of social studies, including history and government
          </li>
          <li class="bullet-point">Three years of mathematics</li>
          <li class="bullet-point">Three years of science, including lab-based courses</li>
          <li class="bullet-point">
            Two years of foreign language to enhance global understanding
          </li>
          <li class="bullet-point">
            Two years of Physical Education for overall well-being
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Get Extra Help Where Needed:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Seek Support: Don’t hesitate to reach out for help, whether it’s from teachers, classmates, older students, or professional tutors. There’s no shame in asking for assistance when needed.
          </li>
          <li class="bullet-point">
            Online Resources: Take advantage of free online learning platforms like Khan Academy or Coursera, which offer tutorials on virtually every subject you may need extra support with.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Begin Building Your Extracurriculars:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Join Activities: Engage in clubs, sports teams, or volunteer opportunities that reflect your interests. This is your chance to start building a well-rounded profile and developing leadership skills.
          </li>
          <li class="bullet-point">
            Part-Time Jobs: Working a part-time job demonstrates responsibility and time management, and it counts as a valuable extracurricular activity when applying for college.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Enjoy High School:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Balance Work and Fun: While preparing for college is important, don’t forget to enjoy the high school experience. Balance academics with relaxation and social activities. High school is about learning, growing, and having fun.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

          

          
        

        
          

          
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Prepare for College as a Sophomore in High School
      </strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Check In With Your Guidance Counselor:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Track Your Progress: Meet regularly with your guidance counselor to ensure you're meeting both your high school’s graduation requirements and the necessary prerequisites for college admission.
          </li>
          <li class="bullet-point">
            Plan Ahead: Discuss your course options for the coming years, and make any necessary adjustments to keep your academic goals on track. Early planning can help you stay organized and focused on your long-term goals.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Keep Your Grades and Courses Up:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Maintain Strong Grades: Focus on keeping your grades high. Sophomore year grades will contribute to your overall GPA, which is important for college admissions.
          </li>
          <li class="bullet-point">
            Prepare for Junior and Senior Years: Choose challenging courses now to prepare for advanced classes later on. A strong academic record will open more opportunities for you when applying to college.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Think About Dual Enrollment:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Get Ahead with College Credits: Dual Enrollment allows you to take college-level courses for credit while still in high school. It’s a valuable opportunity to get a head start on college coursework and reduce future academic load. Learn more about it at the bottom of this page.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Keep Building on Your Extracurriculars:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Join Clubs and Activities: Continue to participate in clubs, sports teams, leadership activities, or volunteer work. These activities not only help you build a well-rounded resume but also allow you to explore your interests and develop important skills.
          </li>
          <li class="bullet-point">
            Part-Time Jobs: If possible, consider taking on a part-time job. Jobs teach responsibility and time management, both of which are qualities colleges and future employers value.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Take the PSAT:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Prepare for the SAT: Taking the PSAT is great preparation for the SAT. It helps you become familiar with the format and content of standardized tests and also enters you into the National Merit Scholarship contest.
          </li>
          <li class="bullet-point">
            Website:{" "}
            <a
              className="link"
              href="https://www.collegeboard.org/"
            >
              CollegeBoard
            </a>
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Have an Idea About College:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Explore Interests: Start thinking about what subjects or fields of study interest you most. You don’t have to decide right away, but beginning to explore your options now will help guide your decisions later.
          </li>
          <li class="bullet-point">
            Stay Open-Minded: It’s okay if your interests change over time. Keeping an open mind allows you to discover new possibilities, but having a general direction can be helpful.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Look Into College Costs:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Research Costs: Start researching the potential cost of college, including tuition, room and board, and other expenses. Look up universities and search for cost breakdowns so you can start planning ahead.
          </li>
          <li class="bullet-point">
            Plan Financially: Talk to your family about saving for college if they haven’t already started. Understanding the financial aspect early will help you explore scholarship and financial aid options down the road.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Visit a College:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Get Familiar with College Environment: If possible, visit a nearby college to get a sense of campus life. Walking around a college campus will help you visualize what your future could look like and motivate you to stay focused on your goals.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>
          Begin Preparing for College Entrance Exams and College Applications:
        </strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Learn About Exams: Start familiarizing yourself with the SAT, ACT, and college application processes. Knowing what to expect will reduce stress as you move into junior year.
          </li>
          <li class="bullet-point">
            Prepare Early: Begin studying for these exams and reviewing sample questions to feel more comfortable and confident when test day arrives.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

          
        

       
          

          
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Prepare for College as a Junior in High School
      </strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>
          Keep Your Grades and Extracurricular Activities Up:
        </strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Maintain Strong Grades: Junior year is one of the most important academic years for college admissions. Focus on maintaining or improving your GPA, as it will play a significant role in your college applications.
          </li>
          <li class="bullet-point">
            Build Extracurricular Accomplishments: Continue to actively participate in clubs, sports, leadership roles, and community service. Colleges look for students who not only excel academically but also engage in activities outside the classroom that show commitment and growth.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Take Challenging Classes:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Enroll in Accelerated Courses and AP Classes: Challenging yourself with advanced courses, such as Honors or AP classes, demonstrates your ability to handle rigorous coursework. These classes may also help you earn college credits, reducing the amount of work needed in college.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Think About Dual Enrollment:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Get Ahead with College Units: Dual Enrollment offers you the opportunity to take actual college courses and earn credits while still in high school. This can not only ease your academic load in college but also prepare you for the type of coursework you’ll face in a university setting. Learn more about this opportunity here.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Explore Colleges:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Research Factors: Begin exploring different colleges and universities, considering factors such as school size, location, majors offered, campus culture, and available extracurricular activities. These will all play a role in helping you find the best fit.
          </li>
          <li class="bullet-point">
            Use Search Tools: Leverage online resources like{" "}
            <a
              className="link"
              href="https://bigfuture.collegeboard.org/college-search/filters"
            >
              Big Future's College Search
            </a>{" "}
            to narrow down your college options based on your preferences and needs.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>The "Information About Yourself" Template:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Choose Between SAT and ACT:
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Evaluate Your Strengths: The SAT focuses on reading, writing, and math, while the ACT includes an additional science section. Determine which exam aligns better with your skills and study habits.
              </li>
              <li class="bullet-point">
                Research College Requirements: Some colleges prefer one test over the other, so check the requirements of your target schools before deciding.
              </li>
              <li class="bullet-point">
                Take Practice Tests: Use practice tests to get a feel for both exams and decide which one you are more comfortable with.
              </li>
            </ul>
          </li>
          <li class="bullet-point">
            Plan Your Exam Date:
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Aim for End of Junior Year: Plan to take your chosen exam (SAT or ACT) toward the end of junior year, giving you ample time to study and prepare.
              </li>
              <li class="bullet-point">
                Schedule a Retake if Necessary: If you’re not satisfied with your score, plan to retake the exam early in senior year to improve your results.
              </li>
            </ul>
          </li>
          <li class="bullet-point">
            Prepare for the Exam:
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Use Study Resources: Study regularly using resources like Magoosh, Khan Academy, and other SAT/ACT prep tools to strengthen your knowledge and test-taking skills.
              </li>
              <li class="bullet-point">
                Practice Regularly: Take practice exams and review areas where you need improvement. Consistent practice is key to feeling confident on test day.
              </li>
            </ul>
          </li>
          <li class="bullet-point">
            Useful Links:
            <ul class="bullet-point-list">
              <li class="bullet-point">
                Website:{" "}
                <a
                  className="link"
                  href="https://www.collegeboard.org/"
                >
                  SAT
                </a>
              </li>
              <li class="bullet-point">
                Website:{" "}
                <a className="link" href="https://www.act.org/">
                  ACT
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Start Keeping an Eye Out for Scholarships:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Scholarships Page: Visit the{" "}
            <a className="link" href="/afford-college/scholarship">
              Scholarships page
            </a>{" "}
            on this website for a comprehensive guide on finding and applying for scholarships. Early preparation is crucial for securing financial aid.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Meet With Your Guidance Counselor:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Verify Graduation Requirements: Make sure you're on track to meet all of your high school’s graduation requirements, ensuring there are no surprises during senior year.
          </li>
          <li class="bullet-point">
            Plan for the Final Stretch: Discuss any remaining tasks or courses you need to complete to ensure a smooth transition to graduation and college application season.
          </li>
          <li class="bullet-point">
            Stay Motivated: Junior year is challenging, but you’re almost at the finish line! Stay focused and continue working hard to set yourself up for success.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Start Visiting Colleges:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Visit Colleges Over Summer or During the School Year: Schedule visits to nearby colleges to get a feel for the campus atmosphere, dorms, and facilities. These visits can help you visualize what type of college environment is best for you.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Begin Working on College Applications:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Start Brainstorming: Use your summer to begin brainstorming your college applications, especially potential essay topics. Consider what makes you unique and how you want to present yourself to admissions committees.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

          
       

        
          

          
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>
        Prepare for College as a Senior in High School
      </strong>
    </p>
    <ul class="bullet-point-list">
      <li class="bullet-point">
        <strong>Ask Teachers for Recommendations Early:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Request recommendations over summer to give your teachers plenty of time to craft thoughtful and detailed letters of recommendation. The earlier you ask, the better chance you have of receiving strong, personalized letters.
          </li>
          <li class="bullet-point">
            Provide your teachers with all necessary information, such as your resume, academic achievements, and any specific qualities or accomplishments you'd like them to emphasize in their letter. This helps them write a comprehensive and compelling recommendation.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Retake Your SAT or ACT if Needed:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Study over the summer and plan to retake the exam in early fall if you aim to improve your score. Even a small score improvement can make a big difference in your college applications.
          </li>
          <li class="bullet-point">
            Use resources like Khan Academy and other SAT/ACT prep materials to target areas for improvement and take practice tests to gauge your progress.
          </li>
          <li class="bullet-point">
            Create a study plan that ensures you cover all necessary material before test day, allowing you to go into the exam feeling fully prepared.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Get Your College Applications Done Early:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Aim to submit your applications in late summer or early fall to avoid last-minute stress and ensure all your materials are polished and complete.
          </li>
          <li class="bullet-point">
            Thoroughly review each college's application requirements and deadlines to ensure you don't miss anything important, such as supplemental essays or portfolio submissions.
          </li>
          <li class="bullet-point">
            Use the College Application Page for detailed guidance on how to complete and submit your applications successfully.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Remind Your Recommendation Writers:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Send polite reminders to your recommendation writers a few weeks before the application deadlines to ensure they have time to finalize their letters.
          </li>
          <li class="bullet-point">
            Offer to provide any additional information or materials they might need to complete a strong letter.
          </li>
          <li class="bullet-point">
            Afterward, follow up with a thank-you note to show your appreciation for their support.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Keep an Eye on Application Deadlines:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Create a detailed calendar or spreadsheet to track all college application deadlines, including early decision or early action deadlines if you're applying to those programs.
          </li>
          <li class="bullet-point">
            Set personal deadlines a few days before the actual ones to allow yourself time to review and double-check each application for accuracy and completeness.
          </li>
          <li class="bullet-point">
            Double-check each submission to ensure all required components, like essays and recommendation letters, are included and properly formatted.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Complete Your Scholarship Applications:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Apply for scholarships as early as possible to maximize your chances of receiving financial aid. Many scholarships have early deadlines, so don't wait until the last minute.
          </li>
          <li class="bullet-point">
            Research scholarships that align with your background, academic achievements, and personal interests. Tailor your applications to highlight why you're a strong candidate for each award.
          </li>
          <li class="bullet-point">
            Use the Scholarships Page for a detailed guide on how to find and apply for scholarships effectively.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Fill Out Your FAFSA:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Complete the Free Application for Federal Student Aid (FAFSA) as soon as it becomes available on October 1st. Submitting early helps you maximize your eligibility for financial aid, including grants, scholarships, and loans.
          </li>
          <li class="bullet-point">
            Gather all necessary financial documents, such as tax returns and income statements, ahead of time to streamline the application process.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Visit Colleges You Are Accepted To:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Visit the colleges you've been accepted to so you can make an informed decision about where you want to spend the next four years.
          </li>
          <li class="bullet-point">
            Attend open houses, sit in on classes, and talk to current students to get a sense of each school's academic and social environment.
          </li>
          <li class="bullet-point">
            Take the time to evaluate the programs, facilities, and support services offered at each college to ensure it aligns with your goals and needs.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Review Financial Aid Packages:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Review the financial aid packages from the schools that accepted you, comparing the total cost of attendance and the breakdown of grants, scholarships, loans, and work-study options.
          </li>
          <li class="bullet-point">
            Discuss these options with your family to decide which offer works best for your financial situation and educational goals.
          </li>
          <li class="bullet-point">
            Contact the financial aid office at any school where you need clarification or additional information.
          </li>
        </ul>
      </li>
      <li class="bullet-point">
        <strong>Notify Your Chosen School and Submit the Deposit:</strong>
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Once you've made your decision, notify your chosen school and submit the deposit by the deadline to secure your place in the incoming freshman class.
          </li>
          <li class="bullet-point">
            Politely inform the other schools that you will not be attending, allowing them to offer your spot to another student.
          </li>
          <li class="bullet-point">
            Celebrate your achievement and begin preparing for the exciting journey ahead in college!
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>

         
       

        
          

          
            <div class="main-section">
              <div class="main-text">
                <p class="chapter-title">
                  <strong>Dual Enrollment</strong>
                </p>
                <p id="ch-base-page-text-main">
                  Dual Enrollment allows high school students to take college
                  courses and earn college credits. This can provide a head
                  start on college education and may be a better option than AP
                  classes for some students. It is also a great way to save
                  money and time on your college journey. Learn More About Dual
                  Enrollment:{" "}
                  <a
                    className="link"
                    href="https://www.cccco.edu/About-Us/Chancellors-Office/Divisions/Educational-Services-and-Support/What-we-do/Dual-Enrollment"
                  >
                    Dual Enrollment
                  </a>
                </p>
              </div>
            </div>
          
       


 {/*     

        <div id="toc-conclusion-container">
          <div id="toc-conclusion-text-container">
            <div id="toc-conclusion-title-container">
              <h2 id="toc-conclusion-title">Conclusion</h2>
              <div id="toc-page-conclusion-underline"></div>
            </div>
            <p id="toc-conclusion-text-main">
              Preparing for college throughout high school is a process that
              involves careful planning, hard work, and taking advantage of
              available opportunities. By following these year-by-year tips, you
              can build a strong academic and extracurricular foundation, making
              the college application process smoother and more successful.
              Embrace each step of the journey, seek support when needed, and
              stay focused on your goals. Your efforts will pay off, leading to
              a fulfilling college experience and a bright future.
            </p>
            <div id="toc-conclusion-end-decorator"></div>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default HighSchoolRoadMap;















