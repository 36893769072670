import React from "react";
import { Link } from "react-router-dom";
import "../../css/MainPage/TableOfContentPages.css";

import headerpic from "../../assets/header-images/headerpic.png";
import tools1 from "../../assets/header-images/tools1.jpg";

import assistant from "../../assets/header-images/assitant.jpg";
import tracker from "../../assets/header-images/tracker.jpg";
import gpas from "../../assets/header-images/gpa.jpg";
import earlysearch from "../../assets/header-images/earlysearch.jpg";

import rob1 from "../../assets/header-images/rob1.jpg";

const Tools = () => {
  return (
    <div className="general-page">
      <div id="tool-TOC-face">
        <img id="tool-TOC-face-pic" src={tools1} alt="Header" />
        <div id="TOC-face-text-container">
          <div id="TOC-text-new-face">
            <p className="TOC-header-text">Tools</p>
          </div>
        </div>
      </div>

      <div className="below-header-container">
        <div id="base-page-introduction-container">
          <div id="base-page-introduction-text-container">
            <div id="base-page-introduction-title-container">
              <h2 id="base-page-intro-title">Quick Guide</h2>
            </div>
            <p id="base-page-introduction-text-main">
              This page is designed to provide you with comprehensive
              information about various tools and programs that can enhance your
              college experience and support your academic and professional
              growth. From AI-driven assistance to financial tracking, and from
              research opportunities to government programs, you’ll find
              resources here that will help you succeed in your educational
              journey. Click on each category to explore detailed information
              and learn how you can benefit from these tools.
            </p>
          </div>
        </div>

        <div className="general-toc-container">
          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={rob1}
                alt="Aza Assistant"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class="traffic-box-part-number">Part 1</h3>
              <h2 className="traffic-title">Aza Assistant</h2>
              <p className="traffic-description">
                Aza is your personalized AI assistant, designed to help you with
                a variety of tasks, including writing essays, applying to
                colleges, and tracking your academic progress. Whether you need
                guidance on school recommendations, improving your academic
                profile, or financial aid, Aza is here to provide tailored
                advice based on your personal data.
              </p>
              <div className="service-item">
                <Link to="/tools/ai">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={earlysearch}
                alt="Early Career Search"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class="traffic-box-part-number">Part 2</h3>
              <h2 className="traffic-title">Early Career Search</h2>
              <p className="traffic-description">
                Kickstart your professional journey with our Early Career Search
                Tool. Whether you're looking for internships, apprenticeships,
                or entry-level opportunities, this tool helps you find positions
                that match your skills and career goals.
              </p>
              <span class="hidden-box-text">
                {" "}
                Lorem ipsum dolor sitt,
                sedssssssssssssssssssssssssssssssssssssssssssssss
              </span>
              <div className="service-item">
                <Link to="/tools/early-career-search">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={tracker}
                alt="Financial Tracker"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class="traffic-box-part-number">Part 3</h3>
              <h2 className="traffic-title">Financial Tracker</h2>
              <p className="traffic-description">
                Manage your college expenses effectively with our Financial
                Tracker. This tool allows you to track your family
                contributions, scholarships, loans, and other financial aid, as
                well as your expenses such as tuition, books, and housing. Stay
                on top of your budget and make informed financial decisions as
                you navigate your college journey.
              </p>
              <div className="service-item">
                <Link to="/tools/financial-tracker">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={gpas}
                alt="GPA Calculator"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class="traffic-box-part-number">Part 4</h3>
              <h2 className="traffic-title">GPA Calculator</h2>
              <p className="traffic-description">
                A handy tool for calculating your GPA. Includes detailed
                instructions on how to input your grades and credits to
                accurately determine your grade point average, helping you stay
                on top of your academic performance.
              </p>
              <span class="hidden-box-text">
                {" "}
                Lorem ipsumelit,
                sedssssssssssssssssssssssssssssssssssssssssssssssssssss
              </span>
              <div className="service-item">
                <Link to="/tools/gpa-calculator">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={tools1}
                alt="College Search"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class="traffic-box-part-number">Part 5</h3>
              <h2 className="traffic-title">College Search</h2>
              <p className="traffic-description">
                This tool is currently under development and will be available
                soon. We're working hard to bring you this exciting new feature,
                so stay tuned for updates! Thank you for your patience and
                understanding as we work to enhance your experience.
              </p>
              <span class="hidden-box-text">
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
              </span>
              <div className="service-item">
                <Link to="/tools/college-search">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={tools1}
                alt="Scholarship Search"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class="traffic-box-part-number">Part 6</h3>
              <h2 className="traffic-title">Scholarship Search</h2>
              <p className="traffic-description">
                This tool is currently under development and will be available
                soon. We're working hard to bring you this exciting new feature,
                so stay tuned for updates! Thank you for your patience and
                understanding as we work to enhance your experience.
              </p>
              <span class="hidden-box-text">
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,{" "}
              </span>
              <div className="service-item">
                <Link to="/tools/scholarship-search">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={tools1}
                alt="Virtual College Tour"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class="traffic-box-part-number">Part 7</h3>
              <h2 className="traffic-title">Virtual College Tour</h2>
              <p className="traffic-description">
                This tool is currently under development and will be available
                soon. We're working hard to bring you this exciting new feature,
                so stay tuned for updates! Thank you for your patience and
                understanding as we work to enhance your experience.
              </p>
              <span class="hidden-box-text">
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,{" "}
              </span>
              <div className="service-item">
                <Link to="/tools/virtual-tour">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={tools1}
                alt="International Students Tool"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class="traffic-box-part-number">Part 8</h3>
              <h2 className="traffic-title">International Students Tool</h2>
              <p className="traffic-description">
                This tool is currently under development and will be available
                soon. We're working hard to bring you this exciting new feature,
                so stay tuned for updates! Thank you for your patience and
                understanding as we work to enhance your experience.
              </p>
              <span class="hidden-box-text">
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                asdfasdf asdfadsf
              </span>
              <div className="service-item">
                <Link to="/tools/international-students-tool">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tools;
