import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import maximizing from "../../../assets/header-images/maximizing.jpg";

import "../../../css/SubPage/SubPage.css";

function SchoolBenefits() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={maximizing} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Maximizing School Benefits </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            College life provides many benefits to enhance your academic and
            personal well-being. With workout facilities, student services, and
            wellness programs, your institution supports you throughout your
            journey. Utilize these resources to enrich your experience, foster
            growth, and engage with the community. Explore the sections below to
            learn more about the benefits available to you.
          </p>
        </div>
      </div>

      <div id="sections-container">

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Campus Facilities</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Libraries:</strong> Access to extensive collections of books, journals, and digital resources, along with quiet study spaces. Libraries often provide additional resources such as study rooms, printing services, and access to academic databases that are crucial for research and coursework.
              </li>
              <li class="bullet-point">
                <strong>Sports Facilities:</strong> Gyms, swimming pools, sports fields, and courts for recreational and competitive use. These facilities encourage physical fitness and well-being, offering classes, intramural sports, and equipment for student use.
              </li>
              <li class="bullet-point">
                <strong>Laboratories and Research Centers:</strong> State-of-the-art labs and research centers to support academic and scientific inquiry. These facilities provide students with hands-on experience in their fields of study, fostering innovation and discovery through access to advanced equipment and technology.
              </li>
              <li class="bullet-point">
                <strong>Student Centers:</strong> Spaces for students to relax, socialize, and engage in various activities. Student centers often house dining options, recreational activities, student organizations, and event spaces, creating a hub for campus life and community building.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Student Services</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Academic Advising:</strong> Guidance on course selection, degree requirements, and career planning. Academic advisors help you create a personalized academic plan, navigate challenges, and make informed decisions about your education and career goals.
              </li>
              <li class="bullet-point">
                <strong>Counseling Services:</strong> Support for mental health, stress management, and personal development. Counseling services provide individual and group therapy sessions, workshops, and resources to help students manage stress, overcome personal challenges, and achieve emotional well-being.
              </li>
              <li class="bullet-point">
                <strong>Tutoring and Writing Centers:</strong> Assistance with coursework, writing skills, and exam preparation. These centers offer personalized tutoring sessions, writing workshops, and study groups to help you excel academically and develop strong writing and analytical skills.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Health and Wellness</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Campus Health Clinics:</strong> Access to medical services, including routine check-ups, vaccinations, and urgent care. Health clinics provide essential healthcare services to students, promoting physical health and well-being on campus.
              </li>
              <li class="bullet-point">
                <strong>Counseling and Psychological Services (CAPS):</strong> Mental health support through counseling, workshops, and group therapy. CAPS offers confidential counseling services, crisis intervention, and mental health workshops to support students' emotional and psychological well-being.
              </li>
              <li class="bullet-point">
                <strong>Recreational Activities:</strong> Fitness classes, intramural sports, and wellness programs to promote physical health. Engaging in recreational activities helps reduce stress, improve fitness, and build a sense of community among students.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Diversity & Inclusion Resources</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>Cultural Centers:</strong> Spaces dedicated to celebrating and supporting diverse cultural identities. Cultural centers offer programs, events, and resources that promote cultural awareness, understanding, and inclusion on campus.
              </li>
              <li class="bullet-point">
                <strong>Diversity Programs:</strong> Initiatives and events aimed at promoting inclusivity and equity on campus. These programs address issues of diversity, equity, and inclusion, fostering a campus environment that respects and values all individuals.
              </li>
              <li class="bullet-point">
                <strong>Support Services:</strong> Resources for underrepresented and marginalized groups. Support services provide advocacy, mentorship, and resources to ensure that all students have equal access to educational and social opportunities.
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Financial Benefits</strong>
            </p>
            <ul class="bullet-point-list">
              <li class="bullet-point">
                <strong>CalFresh:</strong> Assistance for eligible students to receive food benefits and access healthy meals. CalFresh helps students facing food insecurity by providing financial assistance to purchase groceries and maintain a nutritious diet.
              </li>
              <li class="bullet-point">
                <strong>Microsoft Office:</strong> Free access to Microsoft Office applications, including Word, Excel, PowerPoint, and OneNote. These tools are essential for coursework, presentations, data analysis, and collaboration.
              </li>
              <li class="bullet-point">
                <strong>Adobe Creative Cloud:</strong> Free access to Adobe applications like Acrobat, Photoshop, and Illustrator. Students can use Adobe Creative Cloud for academic projects, creative endeavors, and developing technical skills in digital media.
              </li>
              <li class="bullet-point">
                <strong>Additional Free Software:</strong> Many universities offer access to a variety of free software to enhance your learning experience and support your academic endeavors. Check your university website to learn more.
              </li>
              <li class="bullet-point">
                <strong>Laptop Grants:</strong> Some schools, like UCLA, provide funds for high-achieving students to purchase laptops. These grants can significantly reduce the financial burden of buying essential technology, allowing you to focus on your studies and coursework.
              </li>
              <li class="bullet-point">
                <strong>Free Bus Pass:</strong> Take advantage of free public transportation passes for students. Many universities offer complimentary bus cards that make commuting to and from campus more convenient and affordable. This benefit can help you save money on transportation costs and explore the surrounding area.
              </li>
              <li class="bullet-point">
                <strong>Handshake:</strong> Platform for finding internships, part-time jobs, and career opportunities. Handshake connects students with potential employers, providing access to job listings, career fairs, and networking events.
              </li>
              <li class="bullet-point">
                <strong>Amazon Student Prime:</strong> Discounts on Amazon Prime membership, including free shipping and access to Prime Video. Amazon Student Prime offers various benefits that can save you money on textbooks, supplies, and entertainment.
              </li>
              <li class="bullet-point">
                <strong>GitHub Education Pack:</strong> Access to GitHub Premium and additional tools for coding and development. The GitHub Education Pack includes free access to professional-grade development tools, enhancing your technical skills and project management capabilities.
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  );
}

export default SchoolBenefits;
