import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer";
import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import conferences from "../../../assets/header-images/conferences.jpg";

function Conferences() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={conferences} alt="Header" />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Tech Conferences </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>
      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Here, you'll find a curated list of essential conferences and events
            tailored for students aspiring to build successful careers. These
            events offer invaluable opportunities for learning, networking,
            mentorship, and career advancement. Whether you’re looking to
            connect with industry professionals, gain insights into the latest
            tech trends, or explore potential job opportunities, these
            conferences are a great place to start.
          </p>
        </div>
      </div>

      <div id="sections-container">
        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Scholarships to Cover Some Conferences</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Google Conference Scholarships:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Google offers conference
                scholarships to help students attend various tech conferences.
                These scholarships provide financial support to ensure that
                students can take full advantage of the opportunities these
                conferences offer, including networking, learning, and career
                advancement. Be sure to check eligibility criteria and
                application deadlines for each conference.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://buildyourfuture.withgoogle.com/scholarships/google-conference-scholarships"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Google Conference Scholarships
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>General Tech Conferences</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>ACM Richard Tapia Conference:</strong>
              </p>
              <li class="bullet-point">
                <strong>When:</strong> September each year
              </li>
              <li class="bullet-point">
                <strong>Focus:</strong> Connecting undergraduate and graduate
                students, faculty, researchers, and professionals in computing
                from all backgrounds and ethnicities.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://tapiaconference.cmd-it.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ACM Richard Tapia Conference
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>ACM SIGGRAPH:</strong>
              </p>
              <li class="bullet-point">
                <strong>When:</strong> August each year
              </li>
              <li class="bullet-point">
                <strong>Focus:</strong> Connecting researchers, artists,
                developers, filmmakers, and other professionals with an
                interest in computer graphics and animation.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://www.siggraph.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ACM SIGGRAPH
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Great Minds in STEM Conference:</strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> Celebrating diversity in STEM fields,
                providing resources, networking, and career opportunities for
                underrepresented groups.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://greatmindsinstem.org/gmis-conference/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Great Minds in STEM Conference
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Conferences for Racial and Ethnic Groups</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>
                  Society of Hispanic Professional Engineers National
                  Convention (SHPE):
                </strong>
              </p>
              <li class="bullet-point">
                <strong>When:</strong> Late October each year
              </li>
              <li class="bullet-point">
                <strong>Focus:</strong> The largest gathering of Hispanics in
                STEM.
              </li>
              <li class="bullet-point">
                <strong>Features:</strong> Includes 2 weeks of professional
                resume review and 5 full days of virtual face-to-face
                interviews for jobs and internships.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://shpe.org/engage/events/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Society of Hispanic Professional Engineers National
                  Convention (SHPE)
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>
                  Association of Latino Professionals For America Conference
                  (ALPFA):
                </strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> Connecting Latino professionals in
                various fields, offering networking, mentorship, and career
                opportunities.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://www.alpfa.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Association of Latino Professionals For America Conference
                  (ALPFA)
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>
                  National Black MBA Association Conference (NBMBAA):
                </strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> Providing resources, networking, and
                career opportunities for Black professionals in business and
                related fields.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://nbmbaaconference.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Black MBA Association Conference (NBMBAA)
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>
                  American Indian Science and Engineering Society Conference
                  (AISES):
                </strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> Connecting American Indian, Alaska
                Native, and Native Hawaiian STEM professionals and students,
                offering networking and career opportunities.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://www.aises.org/news/events/2024-aises-national-conference"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  American Indian Science and Engineering Society Conference
                  (AISES)
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>
                  Society for Advancement of Chicanos/Hispanics & Native
                  Americans in Science National Diversity in STEM Conference
                  (SACNAS):
                </strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> Promoting diversity in STEM by
                connecting Chicanos/Hispanics and Native Americans with
                opportunities for networking, mentorship, and career
                advancement.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://www.sacnas.org/conference"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Society for Advancement of Chicanos/Hispanics & Native
                  Americans in Science National Diversity in STEM Conference
                  (SACNAS)
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>ADCOLOR Conference:</strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> Highlighting diversity in the creative
                industries, offering networking, mentorship, and career
                opportunities.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://adcolor.org/conference/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ADCOLOR Conference
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>AFROTECH Conference:</strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> Bringing together Black professionals
                in tech for networking, mentorship, and career opportunities.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://experience.afrotech.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  AFROTECH Conference
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>
                  National Society of Black Engineers National Convention
                  (NSBE):
                </strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> Providing resources, networking, and
                career opportunities for Black engineers and engineering
                students.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://convention.nsbe.org/welcome/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  National Society of Black Engineers National Convention
                  (NSBE)
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Conferences for Women</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Grace Hopper Celebration:</strong>
              </p>
              <li class="bullet-point">
                <strong>When:</strong> Late September to early October each
                year
              </li>
              <li class="bullet-point">
                <strong>Focus:</strong> The largest gathering of women
                technologists to learn, network, and celebrate their
                achievements.
              </li>
              <li class="bullet-point">
                <strong>Opportunity:</strong> Submit your resume to their
                database up to 4 months before the event, even if you are not
                attending.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://ghc.anitab.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Grace Hopper Celebration
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Society of Women Engineers Annual Conference:</strong>
              </p>
              <li class="bullet-point">
                <strong>When:</strong> Late October to early November each
                year
              </li>
              <li class="bullet-point">
                <strong>Focus:</strong> Helping women engineering students
                transition into the corporate world by providing training,
                guidance, and access to career opportunities both in academia
                and industry.
              </li>
              <li class="bullet-point">
                <strong>Opportunity:</strong> Submit your resume to their
                database, even if you are not attending.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://we24.swe.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Society of Women Engineers Annual Conference
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Latinas in Tech Summit:</strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> Empowering Latinas in tech through
                networking, mentorship, and career opportunities.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://latinasintechsummit.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Latinas in Tech Summit
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Additional Conferences</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>HPAIR:</strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> The Harvard College Project for Asian
                and International Relations is a student-led not-for-profit
                organization associated with the Harvard University Faculty of
                Arts and Sciences. This program is for undergraduate and
                graduate students.
              </li>
              <li class="bullet-point">
                <strong>Best Advice:</strong>
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://www.hpair.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  HPAIR
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Yale Young Global Scholars:</strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> YYGS is an academic enrichment program
                for outstanding high school students from around the world.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://globalscholars.yale.edu/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Yale Young Global Scholars
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>TechGirls:</strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> TechGirls is a U.S.-based summer
                exchange program designed to empower and support young women
                (ages 15-17) from the United States and select countries
                around the world to pursue higher education and careers in
                science, technology, engineering, and math (STEM) fields
                through hands-on skills development training.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://techgirlsglobal.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TechGirls
                </a>
              </li>
            </ul>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Business Today Conference:</strong>
              </p>
              <li class="bullet-point">
                <strong>Focus:</strong> The Business Today Conference brings
                together top business leaders and undergraduate students to
                discuss and explore current business trends and challenges.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  class="link"
                  href="https://businesstoday.org/conference/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Business Today Conference
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Conference Tips</strong>
            </p>
            <ol class="bullet-point-list">
              <li class="bullet-point">Ask questions and show curiosity.</li>
              <li class="bullet-point">Communication is key to success.</li>
              <li class="bullet-point">Be confident in your abilities.</li>
              <li class="bullet-point">
                Network effectively, connect on LinkedIn, and follow up after
                the conference.
              </li>
              <li class="bullet-point">
                Take notes on important points from sessions and
                conversations.
              </li>
              <li class="bullet-point">
                Dress appropriately: wear professional and comfortable attire.
              </li>
            </ol>
          </div>
        </div>

        {/* Conclusion Section */}

        {/*

        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              Attending conferences is an excellent way to enhance your
              knowledge, expand your professional network, and discover new
              career opportunities. These events bring together industry
              leaders, innovators, and aspiring technologists, creating an
              environment rich with learning and growth potential. Take
              advantage of these opportunities to connect with mentors, gain
              insights into the latest trends, and showcase your talents.
              Remember to apply for scholarships and plan ahead to make the most
              of your conference experiences. Embrace the chance to grow both
              personally and professionally as you pave the way for a successful
              career in technology.
            </p>
          </div>
        </div>


*/}
      </div>
    </div>
  );
}

export default Conferences;
