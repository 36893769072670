import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import timeline from "../../../assets/inner-content-images-and-files/CCTimeLine.png";

import ccpathway from "../../../assets/header-images/ccpathway.jpg";

function CommunityCollege() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={ccpathway} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Community College Pathways </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            Navigating the path to a successful community college experience
            involves careful planning and active engagement in both academics
            and extracurricular activities. This guide provides a comprehensive
            overview of the steps to succeed in community college, from getting
            started to transferring to a four-year institution. Follow these
            steps to ensure a smooth and productive journey through community
            college.
          </p>
        </div>
      </div>

  {/*    <img id="timeline" src={timeline} /> */}


{/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title"> Chapter 1: </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">


        
      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Benefits of Starting at Community College</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Lower tuition costs:</strong> Community colleges typically have much lower tuition rates compared to four-year universities, making them a more affordable option for students looking to save on education expenses in the first two years of college.
    </li>
    <li class="bullet-point">
      <strong>Smaller class sizes:</strong> Enjoy more personalized attention from instructors in smaller classes, which can enhance your learning experience and allow for better student-teacher interaction.
    </li>
    <li class="bullet-point">
      <strong>Flexible schedules:</strong> Community colleges often offer flexible scheduling options, including evening and weekend classes, making it easier for students to balance work, family, or other commitments while pursuing their education.
    </li>
    <li class="bullet-point">
      <strong>Support services:</strong> Take advantage of support services like tutoring, academic advising, and counseling, which are often more accessible at community colleges, helping you stay on track academically and personally.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Financial Aid for Community College</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>FAFSA:</strong> Complete the Free Application for Federal Student Aid (FAFSA) to determine your eligibility for federal financial aid, including grants, loans, and work-study opportunities.
    </li>
    <li class="bullet-point">
      <strong>Cal Grant:</strong> If you're a California resident, apply for Cal Grants, which are state-funded financial aid programs specifically for California students attending community colleges or universities.
    </li>
    <li class="bullet-point">
      <strong>Community College Scholarships:</strong> Many community colleges offer their own scholarships and grants. Be sure to research and apply for scholarships specific to your community college to help reduce your tuition costs even further.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Steps to Succeed in Community College</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Academic Planning:</strong>
    </p>

    <li class="bullet-point">
      <strong>Talk to a Counselor:</strong> Schedule regular meetings with your academic counselor to create and update your educational plan (ED plan). This roadmap should cover 2-3 years, helping you stay on track for graduation or transfer.
    </li>
    <li class="bullet-point">
      <strong>Create a Roadmap:</strong> Use resources like 
      <a className="link" href="https://assist.org">
        assist.org
      </a> to understand the specific course and credit requirements needed for transferring to your target university and desired major. This can save time and help ensure a smooth transfer process.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Research Colleges:</strong>
    </p>

    <li class="bullet-point">
      <strong>Research:</strong> Identify universities that offer programs aligned with your academic and career goals. Take into consideration the school’s reputation, available resources, faculty expertise, and overall campus culture when making your choices.
    </li>
    <li class="bullet-point">
      <strong>Transfer Major Requirements:</strong> Review the transfer requirements for your intended major at each school. Be proactive in ensuring that you meet these requirements well before applying.
    </li>
    <li class="bullet-point">
      <strong>Use assist.org:</strong> Explore assist.org to check the transfer requirements and acceptance rates for your major at different universities, helping you target the right schools for your transfer.
    </li>
    <li class="bullet-point">
      <strong>Identify Your Goals:</strong> Make a clear list of the universities you want to transfer to, including their major requirements, transfer prerequisites, and other relevant factors. This will help you focus on meeting those specific criteria.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Extracurriculars:</strong>
    </p>

    <li class="bullet-point">
      <strong>Join Clubs and Activities:</strong> Engage in extracurricular activities such as clubs, sports, and volunteer work. Consider taking on leadership roles or even creating your own club to strengthen your application.
    </li>
    <li class="bullet-point">
      <strong>Make Connections:</strong> Build relationships with fellow students and professors. Networking can provide valuable academic and professional opportunities, including recommendations and career guidance.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Start Writing Your Essay:</strong>
    </p>

    <li class="bullet-point">
      <strong>Begin Early:</strong> Start drafting your transfer application essays during the summer before you plan to apply. Giving yourself plenty of time will allow for revisions and the creation of strong, compelling essays.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Apply for Scholarships:</strong>
    </p>

    <li class="bullet-point">
      <strong>Ongoing Applications:</strong> Keep applying for scholarships throughout the academic year. Applying to multiple scholarships increases your chances of receiving financial aid that can significantly reduce college costs.
    </li>
  </ul>
</div>


        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>
                Preparing for College: From Community College to Higher
                Education
              </strong>
            </p>
          </div>

          <ul class="bullet-point-list">
            <p class="chapter-sub-title">
              <strong>
                Beyond Academics: Showcasing Your Well-Rounded Self:
              </strong>
            </p>

            <li class="bullet-point">
              <strong>Extracurricular Activities:</strong> Join clubs,
              participate in sports, and volunteer in your community. This
              demonstrates time management, teamwork, and passion for
              activities outside of academics.
            </li>
            <li class="bullet-point">
              <strong>Internships and Work Experience:</strong> Gain
              valuable real-world experience and explore potential career
              paths. Look for opportunities in fields related to your
              academic interests.
            </li>
          </ul>

          <ul class="bullet-point-list">
            <p class="chapter-sub-title">
              <strong>
                Developing Essential Skills: Sharpen Your Tools for Success:
              </strong>
            </p>

            <li class="bullet-point">
              <strong>Time Management:</strong> Master the art of
              organizing your schedule. Use planners, calendars, and
              productivity apps to balance academics, extracurriculars, and
              personal commitments.
            </li>
            <li class="bullet-point">
              <strong>Communication:</strong> Improve your writing and
              speaking skills to clearly convey your ideas. Participate in
              writing workshops and public speaking opportunities.
            </li>
            <li class="bullet-point">
              <strong>Critical Thinking and Problem Solving:</strong> Develop your ability to analyze information, identify
              solutions, and think creatively through challenging academic
              courses and extracurricular activities.
            </li>
            <li class="bullet-point">
              <strong>Leadership:</strong> Demonstrate leadership skills
              through roles in clubs, teams, or community organizations,
              reflecting your ability to influence and guide others.
            </li>
          </ul>
        </div>
{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 1: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
          </div>
        </div>
*/}
{/*
        <div id="ch-base-page-container">
          <div id="ch-base-page-text-container">
            <div id="ch-base-page-title-container">
              <h2 id="ch-base-page-title"> Chapter 2: </h2>
              <div id="ch-base-page-underline"> </div>
            </div>
          </div>
        </div>
*/}
        <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Course Planning</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Mix Courses:</strong> Aim for a balanced mix of easy and challenging courses each semester. It’s often helpful to take more of your major preparation courses early, as they tend to be easier at the beginning. As these courses become more demanding, you can save your General Education (GE) courses to take alongside them for a lighter workload.
    </li>
    <li class="bullet-point">
      <strong>Avoid Low Grades:</strong> Strive to avoid receiving a C or lower. If your grade falls below a B, consider dropping the course and taking a W (withdrawal) on your transcript instead, which can be less damaging to your academic record.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Study Groups and Networking</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Create Study Groups:</strong> Form study groups by creating a Discord server or another online platform for each of your classes. This allows for easy communication with classmates, sharing resources, and discussing course material. Being proactive in organizing study groups can also be listed as an extracurricular activity, demonstrating leadership and collaboration skills.
    </li>
    <li class="bullet-point">
      <strong>Select Good Professors:</strong> Use websites like RateMyProfessors to help you choose the best professors, especially for challenging courses. Selecting the right professor can significantly impact your understanding of the material and overall academic experience.
    </li>
  </ul>
</div>


        <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Free Tools and Resources</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Textbook PDFs:</strong> Access free textbooks using online resources, which offers a wide range of academic books in PDF format. This can help you save significantly on textbook costs throughout your college journey.
    </li>
    <li class="bullet-point">
      <strong>College Resources:</strong> Take full advantage of the free software and tools provided by your college, such as access to Microsoft Word, Excel, Adobe Acrobat, and other premium tools. These resources can support your academic work and help you complete assignments more efficiently.
    </li>
  </ul>
</div>


<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Transfer College App</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Assist.org (Community College in CA to CA University):</strong>
    </p>
    <li class="bullet-point">
      Assist.org is an essential tool for California community college students planning to transfer to a California university. It helps you determine which courses are transferable and meet the requirements for your intended major at your target university. Be sure to check it regularly as part of your transfer planning.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>University of California (UC) Application:</strong>
    </p>

    <li class="bullet-point">
      <strong>TAG:</strong> The Transfer Admission Guarantee (TAG) program allows community college students to receive guaranteed admission to certain UC campuses. Learn more about TAG eligibility and requirements through this{" "}
      <a
        className="link"
        href="https://admission.universityofcalifornia.edu/admission-requirements/transfer-requirements/uc-transfer-programs/transfer-admission-guarantee-tag.html"
      >
        Link
      </a>.
    </li>
    <li class="bullet-point">
      <strong>Transfer Statistics by Major:</strong> Review detailed transfer statistics, including acceptance rates and GPAs for each major at the various UC campuses. This information can help you assess your chances of acceptance based on your academic profile. Check the data here:{" "}
      <a
        className="link"
        href="https://www.universityofcalifornia.edu/about-us/information-center/transfers-major"
      >
        Link
      </a>.
    </li>
    <li class="bullet-point">
      <strong>Transfer Pathways Guide (Courses):</strong> The Transfer Pathways Guide outlines the specific courses community college students should take to prepare for a variety of popular majors at UC campuses. It’s a useful resource for planning your coursework to ensure you meet transfer requirements. Explore the guide here:{" "}
      <a
        className="link"
        href="https://pathwaysguide.universityofcalifornia.edu/college-pathways/0/0"
      >
        Link
      </a>.
    </li>
  </ul>
</div>

{/*

        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 2: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Succeeding in community college involves a blend of strategic
              academic planning, active participation in extracurricular
              activities, and continuous preparation for transferring to a
              four-year institution. By following these steps and utilizing
              available resources, you can make the most of your community
              college experience and set yourself up for future success.
            </p>
          </div>
        </div>

*/}

      </div>
    </div>
  );
}

export default CommunityCollege;
