import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Tools/FinancialTracker.css";
import { db, auth } from "../../firebase/firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getUserFinancialData } from "../../firebase/firebaseService"; // Import the service

import headerpic from "../../assets/header-images/headerpic.png";

import tracker from "../../assets/header-images/tracker.jpg";

const FinancialAidForm = ({ financialAid, onFinancialAidChange }) => {
  const orderedFinancialAidKeys = [
    "familyContribution",
    "selfContribution",
    "fafsa",
    "stateGrant",
    "scholarship",
    "loan",
  ];

  return (
    
    <div className="financial-aid-form">
      {orderedFinancialAidKeys.map((key) => (
        <div className="financial-aid-form__group" key={key}>
          <label htmlFor={key} className="financial-aid-form__label">
            {key
              .replace(/([A-Z])/g, " $1")
              .replace(/^./, (str) => str.toUpperCase())}
            :
          </label>
          <input
            type="number"
            id={key}
            name={key}
            value={financialAid[key]}
            onChange={onFinancialAidChange}
            className="financial-aid-form__input"
          />
        </div>
      ))}
    </div>
    
  );
};

const ExpenseForm = ({ expenses, onChange, onAddOther }) => (
  <form className="expense-form">
    {expenses.map((expense, index) => (
      <div className="expense-form__group" key={index}>
        <label className="expense-form__label">{expense.label}:</label>
        {expense.category === "other" && (
          <input
            type="text"
            name={`${expense.category}Description`}
            value={expense.description}
            onChange={(e) => onChange(e, index)}
            placeholder="Specify the expense"
            className="expense-form__input expense-form__input--other"
          />
        )}
        <select
          name={`${expense.category}Frequency`}
          value={expense.frequency}
          onChange={(e) => onChange(e, index)}
          required
          className="expense-form__select"
        >
          <option value="">Select Frequency</option>
          {expense.options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <input
          type="number"
          name={`${expense.category}Amount`}
          value={expense.amount}
          onChange={(e) => onChange(e, index)}
          required
          className="expense-form__input"
        />
      </div>
    ))}
    <button
      type="button"
      className="expense-form__add-other-button"
      onClick={onAddOther}
    >
      Add "Other" Expense
    </button>
    <p className="expense-form__total">
      Total Expenses: $
      {expenses
        .reduce(
          (sum, expense) =>
            sum + (parseFloat(expense.amount) || 0) * expense.multiplier,
          0
        )
        .toFixed(2)}
    </p>
  </form>
);

const FinancialSummary = ({ totalFinancialAid, totalExpenses }) => (
  <div className="financial-summary">
    <h2 className="financial-summary__title">Financial Summary</h2>
    <p className="financial-summary__item">
      Total Financial Aid: ${totalFinancialAid.toFixed(2)}
    </p>
    <p className="financial-summary__item">
      Total Expenses: ${totalExpenses.toFixed(2)}
    </p>
    <p className="financial-summary__item">
      Remaining Balance: ${(totalFinancialAid - totalExpenses).toFixed(2)}
    </p>
  </div>
);

const FinancialTracker = () => {
  const navigate = useNavigate();
  const [financialAid, setFinancialAid] = useState({
    familyContribution: 0,
    selfContribution: 0,
    fafsa: 0,
    stateGrant: 0,
    scholarship: 0,
    loan: 0,
  });

  const [expenses, setExpenses] = useState([
    {
      label: "Tuition and Fees",
      category: "tuition",
      amount: "",
      frequency: "",
      multiplier: 1,
      options: ["Yearly", "Semesterly", "Quarterly"],
    },
    {
      label: "Books and Supplies",
      category: "books",
      amount: "",
      frequency: "",
      multiplier: 1,
      options: ["Yearly", "Semesterly", "Quarterly"],
    },
    {
      label: "Housing",
      category: "housing",
      amount: "",
      frequency: "",
      multiplier: 1,
      options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
    },
    {
      label: "Food",
      category: "food",
      amount: "",
      frequency: "",
      multiplier: 1,
      options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
    },
    {
      label: "Transportation",
      category: "transportation",
      amount: "",
      frequency: "",
      multiplier: 1,
      options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
    },
    {
      label: "Personal / Misc",
      category: "personal",
      amount: "",
      frequency: "",
      multiplier: 1,
      options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
    },
    {
      label: "Health Insurance",
      category: "health",
      amount: "",
      frequency: "",
      multiplier: 1,
      options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
    },
    {
      label: "Other",
      category: "other",
      description: "",
      amount: "",
      frequency: "",
      multiplier: 1,
      options: ["One-time", "Per Month", "Yearly", "Semesterly", "Quarterly"],
    },
  ]);

  const [isHoldingReset, setIsHoldingReset] = useState(false);
  const [holdTimeout, setHoldTimeout] = useState(null);
  const [showSavePrompt, setShowSavePrompt] = useState(false);

  useEffect(() => {
    const checkUserAndFetchData = async () => {
      if (!auth.currentUser) {
        navigate("/login-prompt"); // Redirect to login prompt if user is not authenticated
        return;
      }

      try {
        const financialData = await getUserFinancialData();
        if (financialData) {
          setFinancialAid(financialData.financialAid || {});
          setExpenses(financialData.expenses || []);
        }
      } catch (error) {
        console.error("Error fetching financial data:", error);
      }
    };

    checkUserAndFetchData();
  }, [navigate]);

  const saveFinancialData = async () => {
    try {
      const docRef = doc(db, "financialTrackers", auth.currentUser.uid);
      await setDoc(docRef, { financialAid, expenses });
      console.log("Financial data saved successfully");
      setShowSavePrompt(false);
    } catch (error) {
      console.error("Error saving financial data:", error);
    }
  };

  const resetAllFields = () => {
    setFinancialAid({
      familyContribution: 0,
      selfContribution: 0,
      fafsa: 0,
      stateGrant: 0,
      scholarship: 0,
      loan: 0,
    });
    setExpenses([
      {
        label: "Tuition and Fees",
        category: "tuition",
        amount: "",
        frequency: "",
        multiplier: 1,
        options: ["Yearly", "Semesterly", "Quarterly"],
      },
      {
        label: "Books and Supplies",
        category: "books",
        amount: "",
        frequency: "",
        multiplier: 1,
        options: ["Yearly", "Semesterly", "Quarterly"],
      },
      {
        label: "Housing",
        category: "housing",
        amount: "",
        frequency: "",
        multiplier: 1,
        options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
      },
      {
        label: "Food",
        category: "food",
        amount: "",
        frequency: "",
        multiplier: 1,
        options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
      },
      {
        label: "Transportation",
        category: "transportation",
        amount: "",
        frequency: "",
        multiplier: 1,
        options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
      },
      {
        label: "Personal / Misc",
        category: "personal",
        amount: "",
        frequency: "",
        multiplier: 1,
        options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
      },
      {
        label: "Health Insurance",
        category: "health",
        amount: "",
        frequency: "",
        multiplier: 1,
        options: ["Per Month", "Yearly", "Semesterly", "Quarterly"],
      },
      {
        label: "Other",
        category: "other",
        description: "",
        amount: "",
        frequency: "",
        multiplier: 1,
        options: ["One-time", "Per Month", "Yearly", "Semesterly", "Quarterly"],
      },
    ]);
    setShowSavePrompt(true);
    console.log("All fields have been reset");
  };

  const handleHoldStart = () => {
    setIsHoldingReset(true);
    const timeout = setTimeout(() => {
      resetAllFields();
      setIsHoldingReset(false);
    }, 500); // .5 - second hold to reset
    setHoldTimeout(timeout);
  };

  const handleHoldEnd = () => {
    setIsHoldingReset(false);
    clearTimeout(holdTimeout);
  };

  const handleFinancialAidChange = (e) => {
    const { name, value } = e.target;
    setFinancialAid((prev) => ({ ...prev, [name]: parseFloat(value) || 0 }));
    setShowSavePrompt(true);
  };

  const handleExpenseChange = (e, index) => {
    const { name, value } = e.target;
    const updatedExpenses = [...expenses];
    const expense = updatedExpenses[index];

    if (name.includes("Amount")) {
      expense.amount = value;
    } else if (name.includes("Frequency")) {
      expense.frequency = value;
      switch (value) {
        case "Yearly":
          expense.multiplier = 1;
          break;
        case "Per Month":
          expense.multiplier = 12;
          break;
        case "Quarterly":
          expense.multiplier = 3;
          break;
        case "Semesterly":
          expense.multiplier = 2;
          break;
        case "One-time":
          expense.multiplier = 1;
          break;
        default:
          expense.multiplier = 1;
      }
    } else if (name.includes("Description")) {
      expense.description = value;
    }

    setExpenses(updatedExpenses);
    setShowSavePrompt(true);
  };

  const handleAddOtherExpense = () => {
    setExpenses([
      ...expenses,
      {
        label: "Other",
        category: "other",
        description: "",
        amount: "",
        frequency: "",
        multiplier: 1,
        options: ["One-time", "Per Month", "Yearly", "Semesterly", "Quarterly"],
      },
    ]);
    setShowSavePrompt(true);
  };

  const totalFinancialAid = Object.values(financialAid).reduce(
    (sum, value) => sum + value,
    0
  );

  const totalExpenses = expenses.reduce(
    (sum, expense) =>
      sum + (parseFloat(expense.amount) || 0) * expense.multiplier,
    0
  );

  return (
    <div id = "financial-tracker-background">
      {/* Add the HTML content here */}
      <div id="Ch-face">
        <img id="header-image" src={tracker} alt="Header" />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p className="TOC-header-text"> Financial Tracker </p>
          </div>
        </div>
      </div>

      {/* Financial Tracker Content */}
      <div className="financial-tracker">
        <div className="form-container">
          <section className="financial-aid">
            <h2 className="financial-aid__title">Financial Aid</h2>
            <FinancialAidForm
              financialAid={financialAid}
              onFinancialAidChange={handleFinancialAidChange}
            />
            <p className="financial-aid__total">
              Total Financial Aid: ${totalFinancialAid.toFixed(2)}
            </p>
          </section>

          <section className="expense-tracker">
            <h2 className="expense-tracker__title">Fees and Expenses</h2>
            <ExpenseForm
              expenses={expenses}
              onChange={handleExpenseChange}
              onAddOther={handleAddOtherExpense}
            />
          </section>
        </div>

        <FinancialSummary
          totalFinancialAid={totalFinancialAid}
          totalExpenses={totalExpenses}
        />

        <div className="button-group">
          <button className="save-button" onClick={saveFinancialData}>
            Save Changes
          </button>
          <button
            className={`reset-button ${isHoldingReset ? "resetting" : ""}`}
            onMouseDown={handleHoldStart}
            onMouseUp={handleHoldEnd}
            onMouseLeave={handleHoldEnd}
          >
            Reset All Fields
          </button>
        </div>

        {showSavePrompt && (
          <p className="save-prompt">
            You have unsaved changes. Please save your changes.
          </p>
        )}
      </div>
    </div>
    
  );
};

export default FinancialTracker;
