import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import essaycraft1 from "../../../assets/header-images/essaycraft1.jpg";

function CollegeEssay() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={essaycraft1} />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Crafting Your College Essay </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            This chapter equips you with the tools to craft a winning
            application that grabs the admission committee's attention and
            highlights your unique qualities.
          </p>
        </div>
      </div>
      {/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">
              {" "}
              Chapter 9: Crafting a standout Application - Writing Your College
              Essay: Beyond grades and scores{" "}
            </h2>
            <div id="ch-base-page-underline"> </div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">

      <div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Importance of the College Essay</strong>
    </p>
    <p class="chapter-description">
      The college essay is a key element of your application, providing a unique opportunity to showcase your voice, personality, and the qualities that make you stand out as a candidate. It allows admissions officers to get to know you on a personal level and can often be a deciding factor in admissions decisions.
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Unique Voice:</strong> The essay is your chance to share your story and let your personality shine through. Use this opportunity to communicate aspects of yourself that may not be evident in other parts of your application, such as your passions, values, and experiences.
    </li>
    <li class="bullet-point">
      <strong>Deciding Factor:</strong> In competitive admissions processes, where many applicants may have similar academic profiles, a compelling and well-written essay can be what sets you apart. A strong essay can leave a lasting impression on admissions officers and elevate your application.
    </li>
    <li class="bullet-point">
      <strong>Be Specific, Not Generic:</strong> Avoid writing a generic essay that could apply to anyone. Admissions officers read thousands of essays, so focus on telling your own unique story. Highlight specific experiences, challenges, or goals that reflect who you are and what makes you a good fit for the school. Personal and authentic writing is more memorable and impactful.
    </li>
  </ul>
</div>

       
<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Tips for Writing and Revising Your Essay</strong>
    </p>
    <p class="chapter-description">
      Crafting a strong college essay requires time and dedication. Students should begin early, allow for multiple drafts, and seek constructive feedback from trusted teachers or mentors. A well-written essay should be personal, authentic, and reflective, offering admissions committees insight into your growth, individuality, and perspective.
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Start Early:</strong> Give yourself plenty of time to brainstorm, write, and revise. Starting early allows you to explore different topics, refine your ideas, and produce multiple drafts. Seeking feedback from teachers or counselors early on ensures that your essay is on the right track.
    </li>
    <li class="bullet-point">
      <strong>Be Specific and Personal:</strong> While activities like volunteering at a library or community center are common, what makes your experience unique is the specific lessons or personal growth you gained. Focus on how these experiences shaped you and avoid generalities. Be personal, share your insights, and let your authentic voice come through.
    </li>
    <li class="bullet-point">
      <strong>Demonstrate, Don’t Just Tell:</strong> Instead of merely stating your qualities or achievements, use storytelling and detailed examples to demonstrate them. Show admissions committees how your actions reflect your character, leadership, or growth, allowing them to see your potential as a valuable part of their community.
    </li>
    <li class="bullet-point">
      <strong>Proofread and Edit:</strong> After completing several drafts, carefully review your essay for clarity, flow, and grammatical accuracy. Make sure it adheres to the word count. Seek feedback from mentors or teachers to catch errors you might have missed and ensure that your final essay is polished and impactful.
    </li>
  </ul>
</div>
 

<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Examples of Successful College Essays</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <li class="bullet-point">
      <strong>Read Examples:</strong> Take time to explore examples of successful college essays, either online or in college prep books. These essays can offer insight into what makes an essay stand out and how other students effectively communicated their personal stories.
    </li>
    <li class="bullet-point">
      <strong>Analyze Structure:</strong> When reading these essays, pay attention to their structure, themes, and voice. Notice how the authors organize their thoughts, how they introduce personal anecdotes, and how they maintain a consistent tone throughout the essay. This analysis can help you understand what techniques work well in a college essay.
    </li>
    <li class="bullet-point">
      <strong>Find Inspiration:</strong> Use these examples as a source of inspiration, but remember not to replicate someone else’s work. The goal is to develop your own unique voice and tell your personal story. Authenticity is key to making a strong impression, so use the examples to spark ideas and craft an essay that is distinctly yours.
    </li>
  </ul>
</div>
  

<div class="main-section">
  <div class="main-text">
    <p class="chapter-title">
      <strong>Steps to Craft a Compelling College Essay</strong>
    </p>
  </div>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Start Early and Manage Time Wisely:</strong>
    </p>

    <li class="bullet-point">
      <strong>Begin Early:</strong> Give yourself plenty of time to develop your essay. Starting early allows you to brainstorm, draft, and revise your work without the stress of last-minute deadlines. A thoughtful, polished essay takes time, so begin as soon as possible.
    </li>
    <li class="bullet-point">
      <strong>Exceed Word Count Initially:</strong> When drafting your essay, aim to write more than the required word count. This gives you ample content to work with and makes it easier to refine and cut down during the editing process, ensuring you keep only the most important and impactful content.
    </li>
  </ul>

  <ul class="bullet-point-list">
    <p class="chapter-sub-title">
      <strong>Strong Essay Prompts:</strong>
    </p>

    <li class="bullet-point">
      <strong>Leadership Experience:</strong> Reflect on a time when you took on a leadership role. Describe the challenges you faced, the decisions you made, and the qualities you developed during this experience. Highlight how these experiences have shaped you as a leader.
    </li>

    <li class="bullet-point">
      <strong>Challenges/Obstacles:</strong> Share a personal challenge or significant obstacle you have overcome. Explain how this experience shaped your resilience and determination, and what you learned from it. Admissions officers value stories that demonstrate growth and perseverance.
    </li>

    <li class="bullet-point">
      <strong>Community Involvement:</strong> Discuss how being involved in your community, extracurricular activities, or volunteer work has influenced your perspective and aspirations. This is an opportunity to showcase how your contributions have impacted others and how these experiences have shaped your future goals.
    </li>

    <li class="bullet-point">
      <strong>Personal Statement:</strong> In your personal statement, share your background, personal interests, and your educational and career goals. Use this essay to give admissions officers a better understanding of who you are as a person and what drives your ambitions.
    </li>

    <li class="bullet-point">
      <strong>Academic Goals:</strong> Outline your academic interests and explain how the university will help you achieve your academic and professional aspirations. Be specific about how the school's programs, resources, or faculty align with your goals.
    </li>
  </ul>
</div>
  

<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>Writing Essays That Win</strong>
    </p>
    {/* IMAGE HERE */}
    <ul className="bullet-point-list">
      <p>
        <strong>Draft 1:</strong> Start by reading the prompt carefully and writing freely about anything related to the topic. Aim to write at least 1.5 to 2 times the required word count so you have plenty of material to refine later.
      </p>
      <li className="bullet-point">
        <strong>Utilize ChatGPT or Gemini for Enhancement:</strong>
        <ul>
          <li>
            <b>Note:</b> While tools like ChatGPT can help improve your essay, ensure your voice and originality remain intact. Use these tools to enhance, not replace, your writing.
          </li>
          <li>
            <strong>How to Leverage ChatGPT or Gemini:</strong>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                Copy your draft and paste it into ChatGPT.
              </li>
              <li className="bullet-point">
                Include the scholarship essay prompt and desired word count to help guide ChatGPT’s suggestions.
              </li>
              <li className="bullet-point">
                <strong>Commands:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    Paste your essay and add:
                    <br /><br />
                    "Using the text above, answer the scholarship essay prompt"
                    <br /><br />
                    [Insert scholarship essay prompt] in [Insert word count].
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                ChatGPT will provide suggestions for clarity, flow, and conciseness. Always review its suggestions to ensure they align with your style and voice.
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <hr />
      <br />

      <p>
        <strong>Draft 2 - Refine and Focus:</strong> After your initial draft, carefully review and cut unnecessary information. Focus on refining key details and streamlining your essay for better coherence and impact.
      </p>
      <li className="bullet-point">
        <strong>Utilize ChatGPT for Enhancement:</strong>
        <ul>
          <li>
            Once you're happy with the content, you can use ChatGPT to make final enhancements. For instance, ask it to "polish the essay" while keeping the tone natural and human.
            <ul>
              <li className="bullet-point">
                If the language becomes too complex, request ChatGPT to "simplify the tone" or "make it sound human" for a more conversational style.
              </li>
            </ul>
          </li>
        </ul>
      </li>

      <br />
      <hr />
      <br />

      <p>
        <strong>Final Review:</strong> Conduct a final, thorough review to ensure your essay addresses the scholarship prompt effectively and presents your best work.
      </p>
      <li className="bullet-point">
        <strong>Seek Feedback from ChatGPT:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            Open a new chat and ask ChatGPT to rate your essay out of 10 based on its alignment with the prompt. Aim for a rating of 8 or higher.
          </li>
          <li className="bullet-point">
            <strong>Commands:</strong> Start a fresh chat and use:
            <ul className="bullet-point-list">
              <li className="bullet-point">
                "Please review the essay below and provide feedback: [Insert your essay]."
              </li>
              <li className="bullet-point">
                "Does this essay address the prompt: [Insert prompt]?"
              </li>
              <li className="bullet-point">
                "Please rate this essay out of 10."
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li className="bullet-point">
        <strong>Seek Feedback from Others:</strong>
        <ul className="bullet-point-list">
          <li className="bullet-point">
            Ask a teacher, counselor, or mentor to review your essay and offer feedback on structure, content, and overall impression.
          </li>
        </ul>
      </li>
      <li className="bullet-point">
        <strong>Request Essay Review from Us:</strong>
        <ul>
          <li>
            We offer detailed essay reviews. Submit your essay for in-depth feedback on how well it addresses the prompt and ways to refine it further for submission.
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>


{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 9: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Crafting a standout college essay involves thoughtful preparation,
              personal reflection, and continuous revision. By starting early,
              seeking feedback, and utilizing resources like AI tools and
              trusted mentors, you can create a compelling narrative that
              showcases your unique qualities and enhances your college
              application.
            </p>
          </div>
        </div>
*/}

      </div>
    </div>
  );
}

export default CollegeEssay;
