import React, { useState } from 'react';
import { doPassWordReset } from "../../firebase/auth";
import '../../css/components/auth.css';

/**
 * ResetPassword Component
 * Allows users to reset their password by sending a reset email.
 */
const ResetPassword = () => {
    // State variables for form inputs and UI state
    const [email, setEmail] = useState(''); // Email input state
    const [isSending, setIsSending] = useState(false); // Sending email state
    const [message, setMessage] = useState(''); // Success message state
    const [error, setError] = useState(''); // Error message state

    // Handle form submission and send password reset email
    const onSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        setIsSending(true); // Set sending state to true
        setMessage(''); // Clear previous success message
        setError(''); // Clear previous error message

        try {
            await doPassWordReset(email); // Attempt to send password reset email
            setMessage('Password reset email sent.'); // Set success message
        } catch (error) {
            setError(error.message); // Set error message
        } finally {
            setIsSending(false); // Reset sending state
        }
    };

    return (
        <main className="auth-page-reset">
            <div className="auth-container-reset">
                <div className="auth-header-reset">
                    <div className="auth-title-reset">
                        <h3>Reset Your Password</h3> {/* Page title */}
                    </div>
                </div>
                <form onSubmit={onSubmit} className="auth-form-reset">
                    <div className="form-group-reset">
                        <label>Email</label>
                        <input
                            type="email"
                            autoComplete="email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} // Update email state
                        />
                    </div>
                    {message && <span className="success-message-reset">{message}</span>} {/* Display success message */}
                    {error && <span className="error-message-reset">{error}</span>} {/* Display error message */}
                    <button
                        type="submit"
                        disabled={isSending}
                        className={`submit-button-reset ${isSending ? 'disabled-button-reset' : ''}`}
                    >
                        {isSending ? 'Sending...' : 'Send Reset Email'} {/* Button text */}
                    </button>
                </form>
            </div>
        </main>
    );
};

export default ResetPassword;
