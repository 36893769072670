import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/components/LoginPrompt.css';

const LoginPrompt = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <div className="login-prompt-container">
      <h2 className="login-prompt-title">Access Restricted</h2>
      <p className="login-prompt-message">
        Please sign in to access this feature.
      </p>
      <button className="login-button" onClick={handleLoginClick}>
        Sign In
      </button>
    </div>
  );
};

export default LoginPrompt;
