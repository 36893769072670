import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer
import headerpic from "../../../assets/header-images/headerpic.png";
import collegesearch1 from "../../../assets/header-images/collegesearch1.jpg";

function CollegeSearch() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={collegesearch1} alt="Header" />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> College Search </p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title">Introduction</h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Choosing the right college is a critical step in your educational
            journey. This chapter will guide you through the process of
            researching colleges, considering essential factors, and utilizing
            various resources to find the best fit for your academic and
            personal needs.
          </p>
        </div>
      </div>
{/*
      <div id="ch-base-page-container">
        <div id="ch-base-page-text-container">
          <div id="ch-base-page-title-container">
            <h2 id="ch-base-page-title">
              Chapter 4: Researching Colleges: Finding Your Perfect Fit
            </h2>
            <div id="ch-base-page-underline"></div>
          </div>
        </div>
      </div>
*/}

      <div id="whole-section-page-container">



      <div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>How to Choose the Right College</strong>
    </p>
    <p className="chapter-description">
      Choosing the right college requires careful consideration of various factors to ensure the school aligns with your academic, personal, and career goals. With so many options available, crafting a balanced and thoughtful college list can be overwhelming. While well-known universities often come to mind, smaller colleges may provide unique advantages. Instead of focusing solely on the number of applications you submit, aim to apply to schools where you can present strong applications and have a solid chance of being accepted. This approach improves your odds of success when decisions are made. Here are some key factors to consider as you navigate this process:
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Academic Programs:</strong>
    </p>
    <li className="bullet-point">
      Ensure the college offers the major you're interested in, along with any specializations that align with your goals. It’s also important to look for strong departments or programs in areas you may want to explore further.
    </li>
    <li className="bullet-point">
      Research the faculty’s expertise and academic background. Explore opportunities for research, internships, or hands-on learning that could complement your academic experience and better prepare you for your future career.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Campus Culture:</strong>
    </p>
    <li className="bullet-point">
      Think about whether you thrive in a large, bustling research university or a smaller, close-knit liberal arts environment. The size of the school can greatly influence your overall experience, from class sizes to available resources.
    </li>
    <li className="bullet-point">
      Consider the setting of the school—whether you prefer the pace and opportunities of an urban city, the quiet nature of a suburban town, or the peacefulness of a rural area. The location will play a big role in your day-to-day life.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Public vs. Private:</strong>
    </p>
    <li className="bullet-point">
      Public colleges typically offer lower tuition, especially for in-state residents, and often have larger and more diverse student populations. This can provide a broader range of perspectives and opportunities.
    </li>
    <li className="bullet-point">
      Private colleges may provide smaller class sizes, more individualized attention from professors, and sometimes more generous financial aid packages based on merit or need. Weigh these factors based on what matters most to you.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Student Services:</strong>
    </p>
    <li className="bullet-point">
      Look into the resources offered by the school to support your academic success and personal well-being, such as tutoring, career counseling, mental health services, and disability accommodations.
    </li>
    <li className="bullet-point">
      Investigate the availability of extracurricular activities, internships, and study abroad programs. A well-rounded experience outside of the classroom can greatly enrich your college years.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Financial Aid and Scholarships:</strong>
    </p>
    <li className="bullet-point">
      Consider the types of financial aid the school typically offers, including grants, loans, and work-study programs. Some colleges may be more generous than others, so make sure to research all your options.
    </li>
    <li className="bullet-point">
      Research scholarships that are available based on your academic achievements, talents, or personal background. These can significantly reduce the overall cost of attendance.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Location:</strong>
    </p>
    <li className="bullet-point">
      Consider how far away the college is from your home, as well as the climate and nearby amenities. Being close to home might offer comfort and convenience, while being farther away could provide new opportunities for growth and independence.
    </li>
    <li className="bullet-point">
      Reflect on whether you want to stay in a familiar area or explore a new region for your college experience. A change in environment can offer new perspectives and experiences, but it’s important to choose what feels right for you.
    </li>
  </ul>
</div>



<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>
        Utilizing College Fairs, Visits, and Virtual Tours
      </strong>
    </p>
    <p className="chapter-description">
      Exploring colleges through various resources can give you valuable insights and help you make a more informed decision about where to apply and attend:
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>College Websites:</strong>
    </p>
    <li className="bullet-point">
      Access a wealth of information about academic programs, student life, admissions requirements, and campus facilities. Many college websites also offer virtual tours, giving you a detailed look at the campus from anywhere in the world.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>College Fairs and Admissions Events:</strong>
    </p>
    <li className="bullet-point">
      Attend college fairs and admissions events to interact directly with admissions representatives and current students. These events provide a great opportunity to ask questions and gain insights into campus culture, academic offerings, and student support services.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>College Visits:</strong>
    </p>
    <li className="bullet-point">
      If possible, visit colleges in person to experience the campus atmosphere firsthand. Campus visits allow you to explore facilities, attend information sessions, and ask questions directly to staff and students, giving you a deeper understanding of life at the school.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Virtual Tours:</strong>
    </p>
    <li className="bullet-point">
      Explore campuses from home using platforms like{" "}
      <a
        className="link"
        href="https://www.youvisit.com/virtual-reality-360-experience"
      >
        You Visit
      </a>, which offers immersive virtual tours of many universities. This is a convenient way to get a feel for campus life if an in-person visit isn't possible.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>College Review Websites:</strong>
    </p>
    <li className="bullet-point">
      Read reviews from current students and alumni on sites like Niche and Unigo to get a student perspective on academics, social life, and campus culture. While reviews can be helpful, remember to consider them as one piece of your research, alongside more formal resources.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Online Research (Quora, Reddit):</strong>
    </p>
    <li className="bullet-point">
      Join discussions on platforms like Quora and Reddit, where current students and alumni share their experiences and answer specific questions about various colleges. These communities can offer candid, real-world perspectives and help you gather diverse opinions on your college options.
    </li>
  </ul>
</div>


<div className="main-section">
  <div className="main-text">
    <p className="chapter-title">
      <strong>How to Create Your Own List</strong>
    </p>
    <p className="chapter-description">
      In addition to the strategies mentioned earlier, consider using these additional resources to help you create a well-rounded college list:
    </p>
  </div>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Academic Factors:</strong>
    </p>
    <li className="bullet-point">
      Academics are often the most important factor in college admissions. Strong academic performance can open more doors and increase your chances of acceptance at competitive schools.
    </li>
    <li className="bullet-point">
      Research the average SAT/ACT scores and GPA of admitted students for each college on your list. This will help you gauge how your academic credentials compare to those of other applicants.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Acceptance Rate:</strong>
    </p>
    <li className="bullet-point">
      Look at each college’s acceptance rate to understand how selective it is. A lower acceptance rate typically indicates a more competitive school, so balance your list to include schools with varying levels of selectivity.
    </li>
    <li className="bullet-point">
      Strive to have a mix of colleges with different acceptance rates, including some more competitive schools and others with higher acceptance rates, to give yourself a range of options.
    </li>
  </ul>

  <ul className="bullet-point-list">
    <p className="chapter-sub-title">
      <strong>Crafting Your College List:</strong>
    </p>
    <li className="bullet-point">
      When creating your college list, categorize schools into three groups: safeties, targets, and reaches. A balanced list increases your chances of getting into a school that aligns with your academic profile and personal preferences.
    </li>

    <ul className="bullet-point-list">
      <li className="bullet-point">
        <strong>Safeties: 40%</strong> - These are schools where your academic qualifications, such as GPA and standardized test scores, exceed the average admitted student's profile. You have a high probability of being accepted.
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Safeties ensure you have secure options and are great for maximizing financial aid and scholarship opportunities, as your credentials will stand out.
          </li>
          <li class="bullet-point">
            Consider applying to at least a couple of safety schools for peace of mind and financial security.
          </li>
        </ul>
      </li>

      <li class="bullet-point">
        <strong>Targets: 40%</strong> - These are schools where your academic credentials closely match the average profile of admitted students. You have a reasonable chance of getting in.
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Target schools are ideal because they align well with both your academic profile and personal preferences.
          </li>
          <li class="bullet-point">
            Apply to several target schools to boost your chances of receiving favorable admissions decisions.
          </li>
        </ul>
      </li>

      <li class="bullet-point">
        <strong>Reaches: 20%</strong> - These are highly competitive schools where your academic credentials may be below the average admitted student. Acceptance is less certain.
        <ul class="bullet-point-list">
          <li class="bullet-point">
            Reach schools often have lower acceptance rates and higher academic standards, making them more selective.
          </li>
          <li class="bullet-point">
            If these are your dream schools or offer unique programs that excite you, it’s worth applying despite the lower odds.
          </li>
          <li class="bullet-point">
            Include a few reach schools on your list to challenge yourself and aim high—just remember to balance them with more attainable options.
          </li>
        </ul>
      </li>
    </ul>
  </ul>
</div>


        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Best College Search Tools</strong>
            </p>
            <p className="chapter-description">
              To help you get started on creating a shortlist of favorite
              schools, here are some of the best college search tools
              available. These resources provide comprehensive information
              on colleges and universities, helping you make informed
              decisions based on your preferences and goals.
            </p>
          </div>
          <ul className="bullet-point-list">
            <li className="bullet-point">
              <strong>Niche.com:</strong> Offers in-depth reviews and
              rankings of colleges based on various criteria such as
              academics, campus life, and student reviews. It provides a
              personalized search experience, allowing you to filter schools
              based on your interests and priorities.
            </li>
            <li className="bullet-point">
              <strong>CollegeVine.com:</strong> Provides a college
              recommendation engine that matches you with schools based on
              your profile and preferences. It also offers insights into
              your chances of admission, personalized essay guidance, and
              financial aid calculators.
            </li>
            <li className="bullet-point">
              <strong>College Scorecard:</strong> A tool from the U.S.
              Department of Education that offers data on college costs,
              graduation rates, and post-college earnings. It allows you to
              compare schools based on key metrics and find institutions
              that align with your financial and academic goals.
            </li>
            <li className="bullet-point">
              <strong>The Princeton Review:</strong> Known for its extensive
              college rankings, reviews, and test preparation resources. It
              provides detailed profiles of colleges, student feedback, and
              expert advice on the college application process.
            </li>
            <li className="bullet-point">
              <strong>TuitionTracker:</strong> A valuable tool for
              understanding the cost of college. It provides information on
              tuition, fees, and financial aid options at various
              institutions, helping you make financially informed decisions.
            </li>
          </ul>
          <p className="chapter-description">
            Once you’ve figured out what you want in a college, these search
            tools will allow you to find schools that best match your
            preferences. Use these resources to explore different
            institutions, compare their offerings, and create a well-rounded
            shortlist of colleges to apply to.
          </p>
        </div>



{/*
        <div id="base-page-conclusion-container">
          <div id="base-page-conclusion-text-container">
            <div id="base-page-conclusion-title-container">
              <h2 id="base-page-conclusion-title">Ch 4: Conclusion</h2>
              <div id="base-page-conclusion-underline"></div>
            </div>
            <p id="base-page-conclusion-text-main">
              Researching colleges is a comprehensive process that involves
              evaluating various factors and utilizing multiple resources. By
              considering academic programs, campus culture, student services,
              financial aid, and location, you can find a college that fits your
              needs and goals. Use college fairs, visits, virtual tours, and
              online research to gather diverse perspectives. Remember to look
              beyond rankings and explore hidden gems that may provide an
              excellent fit for your educational and personal growth.
            </p>
          </div>
        </div>
*/}



      </div>
    </div>
  );
}

export default CollegeSearch;
