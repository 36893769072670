import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function PostAwardSuccess() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />

        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 6</p>
            <p id = "sub-scholarship-ch-header-text">Post-Award Success Strategies</p>
            <p id = "sub-scholarship-ch-text-two">How to Maximize the Benefits</p>
          </div>
        </div>
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            This chapter discovers how to effectively manage your scholarship
            awards, from expressing gratitude to maximizing the financial
            benefits.
          </p>
        </div>
      </div>

      <div id="whole-section-page-container">
        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Expressing Gratitude</strong>
            </p>
            <p className="chapter-description">
              Upon receiving notification of winning a scholarship, promptly
              respond with gratitude and follow any instructions provided
              regarding fund disbursement.
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Prompt Response:</strong> Send a thank-you email or
                letter as soon as you receive the award notification.
              </li>
              <li className="bullet-point">
                <strong>Follow Instructions:</strong> Carefully read and
                follow any instructions provided by the scholarship
                committee regarding next steps and fund disbursement.
              </li>
              <li className="bullet-point">
                <strong>Maintain Professionalism:</strong> Keep your
                communication professional and appreciative.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Effective Management of Scholarship Funds</strong>
            </p>
            <p className="chapter-description">
              Understand the process for receiving and managing scholarship
              funds, whether through direct deposit, checks to yourself, or
              your school.
            </p>
            <ul className="bullet-point-list">
              <strong>Receiving Scholarship Funds:</strong>
              <ul className="bullet-point-list">
                <li className="bullet-point">
                  <strong>Direct Deposit to You:</strong> If the scholarship
                  check is made out to you, deposit it into your own bank
                  account. <b>Note:</b> Some sources say you DO NOT have to
                  report all scholarships to the school to avoid any
                  complications with your financial aid package.
                </li>
                <li className="bullet-point">
                  <strong>Check Made Out to Your School:</strong> Visit the
                  financial aid office to deposit the check.
                </li>
                <li className="bullet-point">
                  <strong>Direct Deposit to the School:</strong> Monitor
                  updates on your financial aid portal to confirm receipt
                  without worrying about additional steps.
                </li>
              </ul>

              <strong>Tips for Managing Funds:</strong>
              <ul className="bullet-point-list">
                <li className="bullet-point">
                  <strong>Budget Wisely:</strong> Create a budget to ensure
                  your scholarship funds are used effectively throughout the
                  academic year.
                </li>
                <li className="bullet-point">
                  <strong>Track Expenses:</strong> Keep a record of how you
                  use the funds to ensure compliance with any scholarship
                  stipulations.
                </li>
              </ul>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Appeal for Cost of Attendance Increase</strong>
            </p>
            <p className="chapter-description">
              If awarded multiple scholarships, consider appealing for a
              budget increase to prevent reductions in financial aid
              packages.
            </p>
            <ul className="bullet-point-list">
              <strong>Steps to Appeal:</strong>
              <ul className="bullet-point-list">
                <li className="bullet-point">
                  <strong>Request a Budget Increase:</strong> Visit your
                  financial aid office and request an increase in your cost
                  of attendance budget.
                </li>
                <li className="bullet-point">
                  <strong>Prevent Reductions:</strong> Explain that this is
                  to prevent reductions in your financial aid package due to
                  excessive scholarships.
                </li>
                <li className="bullet-point">
                  <strong>Understand the Limits:</strong> Be aware that
                  there may be limits to the increase you can receive, but
                  appealing can safeguard both your FAFSA grant and the
                  scholarship amount.
                </li>
              </ul>

              <strong>Tips for Successful Appeals:</strong>
              <ul className="bullet-point-list">
                <li className="bullet-point">
                  <strong>Prepare Documentation:</strong> Have all relevant
                  documents and scholarship award letters ready.
                </li>
                <li className="bullet-point">
                  <strong>Communicate Clearly:</strong> Clearly explain your
                  situation and the need for a budget increase.
                </li>
                <li className="bullet-point">
                  <strong>Follow Up:</strong> Stay in touch with the
                  financial aid office to monitor the status of your appeal.
                </li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostAwardSuccess;
