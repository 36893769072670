import React from "react";
import { Link } from "react-router-dom";
import "../../css/MainPage/TableOfContentPages.css";

import headerpic from "../../assets/header-images/headerpic.png";
import prog1 from "../../assets/header-images/prog1.jpg";

import research from "../../assets/header-images/research.jpg";
import abroad from "../../assets/header-images/abroad.jpg";
import mentorship from "../../assets/header-images/mentorship.jpg";
import conferences from "../../assets/header-images/conferences.jpg";
import academicstem from "../../assets/header-images/academicstem.jpg";
import allstem from "../../assets/header-images/allstem.jpg";
import freshmen from "../../assets/header-images/freshmen.jpg";
import apprentice from "../../assets/header-images/apprentice.jpg";
import government from "../../assets/header-images/government.jpg";
import educationalres from "../../assets/header-images/educationalres.jpg";

const Programs = () => {
  return (
    <div className="general-page">
      <div id="prog-TOC-face">
        <img id="prog-TOC-face-pic" src={prog1} />
        <div id="TOC-face-text-container">
          <div id="TOC-text-new-face">
            <p className="TOC-header-text">Programs</p>
          </div>
        </div>
      </div>

      <div className="below-header-container">

        <div id="base-page-introduction-container">
          <div id="base-page-introduction-text-container">
            <div id="base-page-introduction-title-container">
              <h2 id="base-page-intro-title">Quick Guide</h2>
              
            </div>
            <p id="base-page-introduction-text-main">
              This page is designed to provide you with comprehensive
              information about various programs that can enhance your college
              experience and support your academic and professional growth. From
              research opportunities and study abroad programs to mentorship and
              internships, you'll find a wealth of resources to help you succeed
              in your chosen field. Click on each category to explore detailed
              information and learn how you can benefit from these resources.
            </p>
          </div>
        </div>

        <div className="general-toc-container">
          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={research} alt="Research Opportunities" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 1</h3>
              <h2 className="traffic-title">Research Opportunities</h2>
              <p className="traffic-description">
                Explore a wide range of research opportunities that allow students
                to work alongside experts, gain valuable hands-on experience, and
                contribute to cutting-edge discoveries.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed</span>
              <div className="service-item">
                <Link to="/programs/research-opportunities">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={abroad} alt="Study Abroad Programs" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 2</h3>
              <h2 className="traffic-title">Study Abroad Programs</h2>
              <p className="traffic-description">
                Experience education from a global perspective by studying abroad.
                Explore different cultures, learn new languages, and gain unique
                insights that will enrich your academic and personal life.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing esdf</span>
              <div className="service-item">
                <Link to="/programs/study-abroad">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={mentorship} alt="Mentorship Programs" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 3</h3>
              <h2 className="traffic-title">Mentorship Programs</h2>
              <p className="traffic-description">
                Connect with experienced professionals who can guide you through
                your academic and career journey. Mentorship programs provide
                personalized advice, support, and networking opportunities.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing esdf</span>
              <div className="service-item">
                <Link to="/programs/mentorship-programs">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={conferences} alt="Tech Conferences" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 4</h3>
              <h2 className="traffic-title">Tech Conferences</h2>
              <p className="traffic-description">
                Stay updated with the latest trends and advancements in technology.
                Attend conferences to network with industry leaders, learn from
                experts, and discover new career opportunities.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing esdf</span>
              <div className="service-item">
                <Link to="/programs/tech-conferences">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={academicstem} alt="Academic STEM Programs" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 5</h3>
              <h2 className="traffic-title">Academic STEM Programs</h2>
              <p className="traffic-description">
                Participate in specialized programs designed to enhance your
                knowledge and skills in science, technology, engineering, and
                mathematics. These programs offer hands-on experience, workshops,
                and professional development.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor susmod tempor. </span>
              <div className="service-item">
                <Link to="/programs/academic-stem-programs">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={allstem} alt="All STEM Work Opportunities" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 6</h3>
              <h2 className="traffic-title">All STEM Work Opportunities</h2>
              <p className="traffic-description">
                Explore a variety of work opportunities in STEM fields, including
                internships, co-ops, and full-time positions. Gain practical
                experience and build a professional network to advance your career.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing esdf</span>
              <div className="service-item">
                <Link to="/programs/all-stem-work-opportunities">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={freshmen} alt="Freshman and Sophomore Programs" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 7</h3>
              <h2 className="traffic-title">Freshman and Sophomore Programs</h2>
              <p className="traffic-description">
                Discover programs specifically designed for freshmen and sophomore
                students to help them build a strong foundation in their academic
                and professional journeys. These programs provide early exposure
                to research, industry practices, and professional development
                opportunities.
              </p>
              <div className="service-item">
                <Link to="/programs/freshmen-sophomore-programs">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={apprentice} alt="Apprenticeship Programs" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 8</h3>
              <h2 className="traffic-title">Apprenticeship Programs</h2>
              <p className="traffic-description">
                Gain practical, hands-on experience through apprenticeship programs
                that offer on-the-job training, mentorship, and professional
                development. These programs are designed to bridge the gap between
                education and employment, providing a pathway to a successful career.
              </p>
              <div className="service-item">
                <Link to="/programs/apprenticeship">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={government} alt="Government Programs" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 9</h3>
              <h2 className="traffic-title">Government Programs</h2>
              <p className="traffic-description">
                Take advantage of opportunities provided by government agencies,
                including internships, scholarships, and training programs. These
                programs offer valuable experience and professional development.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sitt, sed</span>
              <div className="service-item">
                <Link to="/programs/government-programs">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img src={educationalres} alt="Free Educational Resources" className="traffic-box-image" />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 10</h3>
              <h2 className="traffic-title">Free Educational Resources</h2>
              <p className="traffic-description">
                Access a wealth of free educational resources to enhance your
                learning experience. These tools provide courses, tutorials, and
                certifications to help you build skills and advance your knowledge.
              </p>
              <div className="service-item">
                <Link to="/programs/free-learning-tools">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
