import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../css/Tools/Ai.css";
import {
  getUserProfileData,
  getUserFinancialData,
} from "../../firebase/firebaseService"; // Import the service
import { auth } from "../../firebase/firebase";
import headerpic from "../../assets/header-images/headerpic.png";

import assistant from "../../assets/header-images/assitant.jpg";
import rob1 from "../../assets/header-images/rob1.jpg";

const API_KEY = process.env.REACT_APP_API_KEY;

const systemMessage = {
  role: "system",
  content:
    "You are Aza, an assistant specialized in helping students with college and scholarship essays, applications, and related tasks. Provide insightful, structured guidance on writing and improving essays, and give detailed feedback on application content. Refuse any unrelated requests.",
};

function ChatbotModal() {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([
    {
      message:
        "Hello, I'm Aza, your personalized essay and application assistant! I'm here to help you with your college and scholarship essays and applications. Ask me anything, or type 'HELP' to get started.",
      direction: "incoming",
      sender: "Aza",
    },
  ]);
  const [isTyping, setIsTyping] = useState(false);
  const [userData, setUserData] = useState(null);
  const [financialData, setFinancialData] = useState(null);

  useEffect(() => {
    const checkUserAndFetchData = async () => {
      if (!auth.currentUser) {
        navigate("/login-prompt"); // Redirect to login prompt if user is not authenticated
        return;
      }

      try {
        const userProfileData = await getUserProfileData();
        setUserData(userProfileData);

        const userFinancialData = await getUserFinancialData();
        setFinancialData(userFinancialData);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    checkUserAndFetchData();
  }, [navigate]);

  const handleSend = async (message) => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);

    setIsTyping(true);

    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    let apiMessages = chatMessages.map((messageObject) => {
      let role = messageObject.sender === "Aza" ? "assistant" : "user";
      return { role: role, content: messageObject.message };
    });

    // Define individual data points from userData and financialData
    const individualDataPoints = {
      university:
        userData?.university || "University information is not available.",
      graduationYear:
        userData?.graduationYear || "Graduation year is not available.",
      educationalStatus:
        userData?.educationalStatus || "Educational status is not available.",
      major: userData?.major || "Major information is not available.",
      careerGoal: userData?.careerGoal || "Career goal is not available.",
      location: userData?.location
        ? `${userData.location.city}, ${userData.location.state}, ${userData.location.zipCode}`
        : "Location information is not available.",
      gpa: userData?.gpa || "GPA is not available.",
      testScores:
        userData?.testScores
          ?.map((test) => `${test.testName}: ${test.score}`)
          .join(", ") || "Test scores are not available.",
      activities:
        userData?.activities
          ?.map(
            (activity) => `${activity.activityName}: ${activity.description}`
          )
          .join(", ") || "Activities are not available.",
      householdIncome:
        userData?.householdIncome || "Household income is not available.",
      familyContribution:
        financialData?.financialAid?.familyContribution ||
        "Family contribution data is not available.",
      selfContribution:
        financialData?.financialAid?.selfContribution ||
        "Self-contribution data is not available.",
      fafsa:
        financialData?.financialAid?.fafsa || "FAFSA data is not available.",
      stateGrant:
        financialData?.financialAid?.stateGrant ||
        "State grant data is not available.",
      scholarship:
        financialData?.financialAid?.scholarship ||
        "Scholarship data is not available.",
      loan: financialData?.financialAid?.loan || "Loan data is not available.",
      tuitionFees:
        financialData?.expenses?.find((exp) => exp.category === "tuition")
          ?.amount || "Tuition and fees data is not available.",
      booksSupplies:
        financialData?.expenses?.find((exp) => exp.category === "books")
          ?.amount || "Books and supplies data is not available.",
      housing:
        financialData?.expenses?.find((exp) => exp.category === "housing")
          ?.amount || "Housing data is not available.",
      food:
        financialData?.expenses?.find((exp) => exp.category === "food")
          ?.amount || "Food data is not available.",
      transportation:
        financialData?.expenses?.find(
          (exp) => exp.category === "transportation"
        )?.amount || "Transportation data is not available.",
      personalMisc:
        financialData?.expenses?.find((exp) => exp.category === "personal")
          ?.amount || "Personal/Misc data is not available.",
      healthInsurance:
        financialData?.expenses?.find((exp) => exp.category === "health")
          ?.amount || "Health insurance data is not available.",
      otherExpenses:
        financialData?.expenses?.find((exp) => exp.category === "other")
          ?.amount || "Other expenses data is not available.",
    };

    // Generate dynamic content based on what might be needed
    const systemDetails = `User's profile and financial information you might need:
      University: ${individualDataPoints.university}
      Graduation Year: ${individualDataPoints.graduationYear}
      Educational Status: ${individualDataPoints.educationalStatus}
      Major: ${individualDataPoints.major}
      Career Goal: ${individualDataPoints.careerGoal}
      Location: ${individualDataPoints.location}
      GPA: ${individualDataPoints.gpa}
      Test Scores: ${individualDataPoints.testScores}
      Activities: ${individualDataPoints.activities}
      Household Income: ${individualDataPoints.householdIncome}
      Family Contribution: ${individualDataPoints.familyContribution}
      Self Contribution: ${individualDataPoints.selfContribution}
      FAFSA: ${individualDataPoints.fafsa}
      State Grant: ${individualDataPoints.stateGrant}
      Scholarship: ${individualDataPoints.scholarship}
      Loan: ${individualDataPoints.loan}
      Tuition and Fees: ${individualDataPoints.tuitionFees}
      Books and Supplies: ${individualDataPoints.booksSupplies}
      Housing: ${individualDataPoints.housing}
      Food: ${individualDataPoints.food}
      Transportation: ${individualDataPoints.transportation}
      Personal/Misc: ${individualDataPoints.personalMisc}
      Health Insurance: ${individualDataPoints.healthInsurance}
      Other Expenses: ${individualDataPoints.otherExpenses}`;

    // Process request to ChatGPT with the system details included
    const apiRequestBody = {
      model: "gpt-4o-mini",
      messages: [
        systemMessage,
        ...apiMessages,
        {
          role: "system",
          content: systemDetails,
        },
      ],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then((data) => data.json())
      .then((data) => {
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            direction: "incoming",
            sender: "Aza",
          },
        ]);
        setIsTyping(false);
      });
  }

  return (
    <>
      <div id="aza-body">
        <div id="Ch-face">
          <img id="header-image" src={rob1} alt="Header" />
          <div id="TOC-face-text-container">
            <div id="TOC-face-text">
              <p className="TOC-header-text"> Aza Assistant </p>
            </div>
          </div>
        </div>

        <div className="outer-container"> {/* Outermost encapsulating div */}
          <div className="right-side"> {/* Right side div */}
            <div className="chat-window-container"> {/* Encapsulate chat window and input container */}
              <div className="chat-window">
                <div className="message-list">
                  {messages.map((message, i) => (
                    <div
                      key={i}
                      className={`message ${
                        message.direction === "outgoing"
                          ? "user-message"
                          : "bot-message"
                      }`}
                    >
                      <div className="message-text">{message.message}</div>
                    </div>
                  ))}
                  {isTyping && (
                    <div className="typing-indicator">Aza is typing...</div>
                  )}
                </div>
              </div>
              <div className="input-container">
                <input
                  className="input-field"
                  placeholder="Type your message here..."
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSend(e.target.value);
                      e.target.value = "";
                    }
                  }}
                />
                <button
                  className="send-button"
                  onClick={() => {
                    const input = document.querySelector(".input-field");
                    handleSend(input.value);
                    input.value = "";
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChatbotModal;
