import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../../components/SubNavContainer"; // Importing SubNavContainer

import "../../../../css/SubPage/AffordCollege/ScholarshipGuide.css";

import headerpic from "../../../../assets/header-images/headerpic.png";
import scholarship from "../../../../assets/header-images/scholarship.jpg";

function UnderstandScholarship() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={scholarship} />


        <div id="sub-scholarship-text-container">
          <div id="sub-scholarship-text"> 
            <p id = "sub-scholarship-chapter"> Chapter 1</p>
            <p id = "sub-scholarship-ch-header-text">Understanding Scholarships</p>
            <p id = "sub-scholarship-ch-text-two">Your Key to Educational Freedom</p>
          </div>
        </div>

        
      </div>

      <div id="sub-nav-container">
        <SubNavContainer />
      </div>

      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>

          <p id="base-page-introduction-text-main">
            This chapter dives into the world of scholarships, exploring their
            importance and the variety of opportunities available.
          </p>
        </div>
      </div>

      <div id="whole-section-page-container">
        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>What is a Scholarship? Why is it important?</strong>
            </p>
            <p className="chapter-description">
              Scholarships are financial awards designed to support students
              based on various criteria such as academic achievement, financial
              need, or special talents. They are pivotal in:
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Reducing Financial Burden:</strong> Scholarships
                alleviate the cost of tuition, books, and living expenses,
                making higher education more accessible.
              </li>
              <li className="bullet-point">
                <strong>Preventing Student Debt:</strong> By providing
                financial support, scholarships help students avoid
                substantial student loans and the associated debt.
              </li>
              <li className="bullet-point">
                <strong>Encouraging Academic Excellence:</strong> Many
                scholarships are merit-based, motivating students to strive
                for academic and extracurricular achievements.
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>Types of Scholarships</strong>
            </p>
            <p className="chapter-description">
              Scholarships come in various types, each with specific
              criteria and benefits:
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Merit-based Scholarships:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    <strong>Criteria:</strong> Academic excellence,
                    leadership qualities, and extracurricular involvement.
                  </li>
                  <li className="bullet-point">
                    <strong>Examples:</strong> National Merit Scholarship,
                    Rhodes Scholarship.
                  </li>
                  <li className="bullet-point">
                    <strong>Application Tips:</strong> Maintain a high GPA,
                    participate in leadership roles, and engage in community
                    service.
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Need-based Scholarships:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    <strong>Criteria:</strong> Demonstration of financial
                    need, typically assessed through forms like the FAFSA.
                  </li>
                  <li className="bullet-point">
                    <strong>Examples:</strong> Federal Pell Grant, State
                    Pell Grant, institutional need-based awards.
                  </li>
                  <li className="bullet-point">
                    <strong>Application Tips:</strong> Accurately complete
                    financial aid forms, provide necessary documentation,
                    and explore both federal and institutional options.
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Additional Categories:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    <strong>Ethnicity-based Scholarships:</strong> Designed
                    to support students from specific ethnic backgrounds
                    (e.g., Hispanic Scholarship Fund).
                  </li>
                  <li className="bullet-point">
                    <strong>Field of Study Scholarships:</strong> Targeted
                    at students pursuing specific majors (e.g., STEM
                    scholarships).
                  </li>
                  <li className="bullet-point">
                    <strong>Skill-based Scholarships:</strong> Awarded for
                    unique talents (e.g., athletic scholarships, art
                    scholarships).
                  </li>
                  <li className="bullet-point">
                    <strong>Community Involvement Scholarships:</strong>
                    Recognize students who actively contribute to their
                    communities.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div className="main-section">
          <div className="main-text">
            <p className="chapter-title">
              <strong>The Value Beyond Financial Aid</strong>
            </p>
            <p className="chapter-description">
              Scholarships offer more than just financial assistance. Some
              additional benefits and opportunities include:
            </p>
            <ul className="bullet-point-list">
              <li className="bullet-point">
                <strong>Mentorship Programs:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    <strong>Description:</strong> Many scholarships include
                    access to mentors who are industry professionals.
                  </li>
                  <li className="bullet-point">
                    <strong>Benefits:</strong> Guidance in academic and
                    career paths, advice on navigating the professional
                    world.
                  </li>
                  <li className="bullet-point">
                    <strong>Examples:</strong> Gates Millennium Scholars,
                    Coca-Cola Scholars Program.
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Networking Opportunities:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    <strong>Description:</strong> Scholarship programs often
                    host events and conferences.
                  </li>
                  <li className="bullet-point">
                    <strong>Benefits:</strong> Building connections with
                    peers, alumni, and industry leaders.
                  </li>
                  <li className="bullet-point">
                    <strong>Examples:</strong> Jack Kent Cooke Foundation,
                    Fulbright Program.
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Career Development Workshops:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    <strong>Description:</strong> Workshops and seminars to
                    enhance career readiness.
                  </li>
                  <li className="bullet-point">
                    <strong>Benefits:</strong> Resume building, interview
                    preparation, and job search strategies.
                  </li>
                  <li className="bullet-point">
                    <strong>Examples:</strong> Microsoft Scholarship
                    Program, Google Scholarships.
                  </li>
                </ul>
              </li>
              <li className="bullet-point">
                <strong>Internship Opportunities:</strong>
                <ul className="bullet-point-list">
                  <li className="bullet-point">
                    <strong>Description:</strong> Some scholarships provide
                    direct pathways to internships.
                  </li>
                  <li className="bullet-point">
                    <strong>Benefits:</strong> Gaining hands-on work
                    experience, improving employability.
                  </li>
                  <li className="bullet-point">
                    <strong>Examples:</strong> NASA Scholarship Programs,
                    IBM Scholarships.
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnderstandScholarship;
