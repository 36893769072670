import React, { useState } from "react";
import { Navigate, Link } from "react-router-dom";
import {
  doSignInWithEmailAndPassword,
  doSignInWithGoogle,
  doSendEmailVerification,
} from "../../firebase/auth";
import { useAuth } from "../../context/authContext";
import "../../css/components/auth.css";
import login1 from "../../assets/header-images/login1.jpg"; // Import the image

/**
 * Login Component
 * Allows users to log in with email/password or Google account.
 */
const Login = () => {
  const { userLoggedIn } = useAuth(); // Get userLoggedIn state

  // State variables for form inputs and UI state
  const [email, setEmail] = useState(""); // Email input state
  const [password, setPassword] = useState(""); // Password input state
  const [isSigningIn, setIsSigningIn] = useState(false); // Signing in state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  // Handle email/password login
  const onSubmit = async (e) => {
    e.preventDefault(); // Prevent form default behavior
    if (!isSigningIn) {
      setIsSigningIn(true); // Set signing in state
      try {
        await doSignInWithEmailAndPassword(email, password); // Sign in with email/password
        doSendEmailVerification(); // Send email verification
      } catch (error) {
        setErrorMessage(error.message); // Set error message
        setIsSigningIn(false); // Reset signing in state
      }
    }
  };

  // Handle Google sign in
  const onGoogleSignIn = async (e) => {
    e.preventDefault(); // Prevent form default behavior
    if (!isSigningIn) {
      setIsSigningIn(true); // Set signing in state
      try {
        await doSignInWithGoogle(); // Sign in with Google
      } catch (error) {
        setErrorMessage(error.message); // Set error message
        setIsSigningIn(false); // Reset signing in state
      }
    }
  };

  return (
    <div className="login-page">
      {userLoggedIn && <Navigate to={"/profile"} replace={true} />}{" "}
      {/* Redirect if logged in */}
      <div className="login-container">
        <div className="login-left">
          <main className="auth-main">
            <div className="auth-container">
              <div className="auth-header">
                <div className="auth-title">
                  <h3>Log in</h3> {/* Page title */}
                </div>
              </div>
              <form onSubmit={onSubmit} className="auth-form">
                <div className="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} // Update email state
                  />
                </div>

                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} // Update password state
                  />
                </div>

                {errorMessage && (
                  <span className="error-message">
                    Invalid login credentials
                  </span> /* Display error */
                )}

                <button
                  type="submit"
                  disabled={isSigningIn}
                  className={`submit-button ${
                    isSigningIn ? "disabled-button" : ""
                  }`}
                >
                  {isSigningIn ? "Signing In..." : "Sign In"} {/* Button text */}
                </button>
              </form>
              <p className="auth-footer">
                Don't have an account?{" "}
                <Link to={"/signup"} className="link">
                  Sign up
                </Link>
              </p>
              <p className="auth-footer">
                <Link to={"/reset-password"} className="link">
                  Forgot password?
                </Link>
              </p>
              <div className="separator">
                <div className="separator-line"></div>
                <div className="separator-text">OR</div>
                <div className="separator-line"></div>
              </div>
              <button
                disabled={isSigningIn}
                onClick={onGoogleSignIn}
                className={`google-button ${
                  isSigningIn ? "disabled-button" : ""
                }`}
              >
                <svg
                  className="google-icon"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_17_40)">
                    <path
                      d="M47.532 24.5528C47.532 22.9214 47.3997 21.2811 47.1175 19.6761H24.48V28.9181H37.4434C36.9055 31.8988 35.177 34.5356 32.6461 36.2111V42.2078H40.3801C44.9217 38.0278 47.532 31.8547 47.532 24.5528Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M24.48 48.0016C30.9529 48.0016 36.4116 45.8764 40.3888 42.2078L32.6549 36.2111C30.5031 37.675 27.7252 38.5039 24.4888 38.5039C18.2275 38.5039 12.9187 34.2798 11.0139 28.6006H3.03296V34.7825C7.10718 42.8868 15.4056 48.0016 24.48 48.0016Z"
                      fill="#34A853"
                    />
                    <path
                      d="M11.0051 28.6006C9.99973 25.6199 9.99973 22.3922 11.0051 19.4115V13.2296H3.03298C-0.371021 20.0112 -0.371021 28.0009 3.03298 34.7825L11.0051 28.6006Z"
                      fill="#FBBC04"
                    />
                    <path
                      d="M24.48 9.49932C27.9016 9.44641 31.2086 10.7339 33.6866 13.0973L40.5387 6.24523C36.2 2.17101 30.4414 -0.068932 24.48 0.00161733C15.4055 0.00161733 7.10718 5.11644 3.03296 13.2296L11.005 19.4115C12.901 13.7235 18.2187 9.49932 24.48 9.49932Z"
                      fill="#EA4335"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_17_40">
                      <rect width="48" height="48" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                {isSigningIn ? "Signing In..." : "Continue with Google"}{" "}
                {/* Button text */}
              </button>
            </div>
          </main>
        </div>
        <div className="login-right">
          <img
            src={login1}  // Use the imported image
            alt="Login Visual"
            className="login-image-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
