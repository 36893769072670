import React from "react";
import { Link } from "react-router-dom";
import SubNavContainer from "../../../components/SubNavContainer"; // Importing SubNavContainer

import headerpic from "../../../assets/header-images/headerpic.png";
import "../../../css/SubPage/SubPage.css";

import academicstem from "../../../assets/header-images/academicstem.jpg";

function AcademicStemPrograms() {
  return (
    <div id="newest-background">
      <div id="Ch-face">
        <img id="header-image" src={academicstem} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text"> Academic STEM Programs </p>
          </div>
        </div>
      </div>
      <div id="sub-nav-container">
        <SubNavContainer />
      </div>
      <div id="base-page-introduction-container">
        <div id="base-page-introduction-text-container">
          <div id="base-page-introduction-title-container">
            <h2 id="base-page-intro-title"> Introduction </h2>
            
          </div>
          <p id="base-page-introduction-text-main">
            Academic STEM programs offer students the opportunity to gain
            hands-on experience, develop technical skills, and build
            professional networks in the fields of science, technology,
            engineering, and mathematics. These programs are designed to
            complement academic learning and provide real-world applications of
            STEM concepts.
          </p>
        </div>
      </div>

      <div id="sections-container">
        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Program Listings</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Breakthrough Tech:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Focuses on artificial
                intelligence, offering training, internships, and networking
                opportunities.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> Participants receive hands-on
                experience in AI projects, access to industry experts, and
                opportunities for career advancement.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  href="https://www.breakthroughtech.org/programs/the-ai-program/"
                  class="link"
                >
                  Breakthrough Tech
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Google Summer of Code:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> A global program that brings
                more student developers into open source software development.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> Students work on open source
                projects under the guidance of mentors from around the world.
                The program runs over the summer and culminates in a final
                project presentation.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://summerofcode.withgoogle.com/" class="link">
                  Google Summer of Code
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Snap Academy (Transfer students only):</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Supports underrepresented
                students in gaining skills and experience in the tech
                industry.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> The program offers training,
                mentorship, and internship opportunities at Snap Inc.,
                specifically targeting transfer students.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://snap.com/" class="link">
                  Snap Academy
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>MLH Fellowship:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> A 12-week internship alternative
                for software engineers.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> Students collaborate on open source
                projects and receive mentorship from experienced developers
                through Major League Hacking.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://fellowship.mlh.io/" class="link">
                  MLH Fellowship
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Purdue Data Science For All:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> A 12-week immersive program at
                Purdue focused on developing expertise in data science through
                hands-on projects and industry collaboration.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> Students engage in real-world data
                challenges, guided by experienced mentors, while learning
                cutting-edge techniques in data analysis, machine learning,
                and visualization.
              </li>

              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://datamine.purdue.edu/" class="link">
                  Data Science For All
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Netflix X Formation:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Collaboration with Formation to
                provide training and development opportunities.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> Focuses on preparing
                underrepresented talent for careers in the tech industry
                through structured training programs.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://formation.dev/partners/netflix" class="link">
                  Netflix X Formation
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Goldman Sachs Possibilities Summits:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Offers students opportunities to
                learn about the financial industry and career possibilities at
                Goldman Sachs.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> The program includes networking,
                mentorship, and professional development sessions.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  href="https://www.goldmansachs.com/careers/students/programs/americas/possibilities-summits.html"
                  class="link"
                >
                  Goldman Sachs Possibilities Summits
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Morgan Stanley Early Insights Program:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Provides early-stage career
                development opportunities.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> The program includes training
                sessions, networking events, and mentoring opportunities aimed
                at underrepresented groups in finance and technology.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  href="https://www.morganstanley.com/people-opportunities/na-early-insight-faq"
                  class="link"
                >
                  Morgan Stanley Early Insights Program
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>LA Tech IT Career Academy:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Provides comprehensive training
                in information technology, along with internships and
                networking opportunities.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> Participants gain hands-on
                experience in IT projects, access to industry professionals,
                and pathways for career advancement.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  href="https://la-tech.org/la-tech-it-career-academy"
                  class="link"
                >
                  LA Tech IT Career Academy
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Impact Labs Fellowship:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> A fellowship program designed to
                empower and support tech talent to build impactful solutions.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> Participants work on real-world
                projects, receive mentorship from industry experts, and gain
                access to a network of professionals and resources to
                accelerate their careers and ventures.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://www.impactlabs.io/fellowship" class="link">
                  Impact Labs Fellowship
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>CUNY Tech Prep:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> An advanced tech training
                program for students at the City University of New York
                (CUNY).
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> The program focuses on full-stack
                JavaScript development, providing students with the skills
                needed to succeed in the tech industry. Participants also
                benefit from career coaching and job placement support.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://cunytechprep.org/" class="link">
                  CUNY Tech Prep
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="main-section">
          <div class="main-text">
            <p class="chapter-title">
              <strong>Minorities Specific Programs</strong>
            </p>
            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Google Tech Exchange:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Aims to provide computer science
                students from Historically Black Colleges and Universities
                (HBCUs) and Hispanic-Serving Institutions (HSIs) with
                opportunities to learn from Google engineers.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> The program includes coursework,
                projects, and mentorship to help students develop technical
                skills and industry knowledge.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a
                  href="https://buildyourfuture.withgoogle.com/programs/tech-exchange"
                  class="link"
                >
                  Google Tech Exchange
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>MLT Career Prep Program:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Designed to help high-achieving
                African American, Latino, and Native American students prepare
                for career success.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> The program includes personalized
                coaching, professional development workshops, and networking
                opportunities.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://mlt.org/career-prep/" class="link">
                  MLT Career Prep Program
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>Code 2040:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Aims to activate, connect, and
                mobilize the largest racial equity community in tech.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> Offers fellowships, internships, and
                professional development opportunities for Black and Latinx
                students.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://programs.code2040.org/" class="link">
                  Code 2040
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>HBC 20x20:</strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Provides fellowship
                opportunities for students from HBCUs.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> The program focuses on career
                development, internships, and professional networking.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://theapplication.org/fellowships" class="link">
                  HBC 20x20
                </a>
              </li>
            </ul>

            <ul class="bullet-point-list">
              <p class="chapter-sub-title">
                <strong>
                  SHPE: Society of Hispanic Professional Engineers:
                </strong>
              </p>
              <li class="bullet-point">
                <strong>Description:</strong> Supports Hispanic students and
                professionals in STEM fields.
              </li>
              <li class="bullet-point">
                <strong>Details:</strong> Offers scholarships, professional
                development, and networking opportunities.
              </li>
              <li class="bullet-point">
                <strong>Website:</strong>
                <a href="https://shpe.org/" class="link">
                  SHPE
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/*

        <div id="conclusion-section">
          <div id="conclusion-text-container">
            <div id="conclusion-title-container">
              <h2 id="conclusion-title">Conclusion</h2>
              <div id="conclusion-underline"></div>
            </div>
            <p id="conclusion-text">
              Participating in academic STEM programs can be a pivotal step in
              your educational and professional journey. These programs provide
              practical experience, industry insights, and valuable networking
              opportunities that can significantly enhance your career
              prospects. By engaging in these opportunities, you can develop the
              skills and confidence needed to succeed in the STEM fields.
              Explore the programs listed here, take advantage of the resources
              available, and pave the way for a bright and successful future in
              STEM.
            </p>
          </div>
        </div>

*/}
      </div>
    </div>
  );
}

export default AcademicStemPrograms;
