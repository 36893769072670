import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/authContext";

// PrivateRoute component to protect routes from unauthorized access
const PrivateRoute = () => {
  const { userLoggedIn } = useAuth(); // Get the authentication status from the Auth context

  // If the user is logged in, render the Outlet component to display the nested route
  // Otherwise, redirect the user to the login page
  return userLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
