import React from "react";
import "../css/components/PrivacyPolicy&TermsOfUse.css";

import headerpic from "../assets/header-images/headerpic.png";
import conditions from "../assets/header-images/conditions.jpg";

const TermsOfService = () => {
  const lastUpdated = "August 2, 2024"; // Update this date as needed

  return (
    <>
    <div class = "privacy-background-page">
      <div id="Ch-face">
        <img id="header-image" src={conditions} alt="Terms of Service" />

        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p class="TOC-header-text">Terms of Service</p>
          </div>
        </div>
      </div>

      <div className="terms-of-service-container">
        <h1 className="terms-of-service-title">Terms of Service</h1>
        <p className="terms-of-service-date">Last Updated: {lastUpdated}</p>
        <p className="terms-of-service-intro">
          Welcome to CollegeNav. By accessing or using our website, you agree to
          comply with and be bound by these Terms of Service. Please read them
          carefully. If you do not agree with these terms, please do not use our
          website.
        </p>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">1. Acceptance of Terms</h2>
          <p className="terms-of-service-text">
            By using CollegeNav, you agree to be bound by these Terms of Service
            and all applicable laws and regulations. If you do not agree to
            these terms, you are prohibited from using or accessing this site.
          </p>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">2. Description of Services</h2>
          <p className="terms-of-service-text">
            CollegeNav provides a platform to assist users in navigating the
            college journey, including resources for college preparation,
            application guidance, and more. We reserve the right to modify or
            discontinue our services at any time without notice.
          </p>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">3. User Accounts</h2>
          <ul className="terms-of-service-list">
            <li className="terms-of-service-item">
              <strong>Registration:</strong> To access certain features, you may
              need to create an account. You agree to provide accurate and
              complete information during registration and to update your
              information to keep it accurate and complete.
            </li>
            <li className="terms-of-service-item">
              <strong>Account Security:</strong> You are responsible for
              maintaining the confidentiality of your account information,
              including your password. You agree to notify us immediately of any
              unauthorized use of your account.
            </li>
            <li className="terms-of-service-item">
              <strong>Account Termination:</strong> We reserve the right to
              terminate or suspend your account at any time if you violate these
              terms or engage in any conduct that we determine to be
              inappropriate or harmful.
            </li>
          </ul>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">4. User Conduct</h2>
          <ul className="terms-of-service-list">
            <li className="terms-of-service-item">
              <strong>Prohibited Activities:</strong> You agree not to engage in
              any activity that is illegal, harmful, or infringes on the rights
              of others. This includes, but is not limited to, uploading harmful
              content, spamming, hacking, or impersonating others.
            </li>
            <li className="terms-of-service-item">
              <strong>Content Ownership:</strong> You retain ownership of any
              content you submit to CollegeNav, but you grant us a
              non-exclusive, worldwide, royalty-free license to use, reproduce,
              and distribute your content in connection with our services.
            </li>
            <li className="terms-of-service-item">
              <strong>Monitoring and Enforcement:</strong> We have the right to
              monitor your use of our services and to enforce these terms,
              including removing content and terminating accounts.
            </li>
          </ul>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">5. Intellectual Property</h2>
          <p className="terms-of-service-text">
            All content on CollegeNav, including text, graphics, logos, and
            software, is the property of CollegeNav or its content suppliers and
            is protected by intellectual property laws. You may not use any
            content without our express written permission.
          </p>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">6. Privacy</h2>
          <p className="terms-of-service-text">
            Your use of CollegeNav is also governed by our Privacy Policy, which
            outlines how we collect, use, and protect your personal information.
            By using our services, you consent to our collection and use of your
            information as described in the Privacy Policy.
          </p>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">
            7. Disclaimers and Limitation of Liability
          </h2>
          <ul className="terms-of-service-list">
            <li className="terms-of-service-item">
              <strong>Disclaimer of Warranties:</strong> CollegeNav is provided
              "as is" and "as available." We make no warranties, express or
              implied, regarding the availability, accuracy, or reliability of
              our services.
            </li>
            <li className="terms-of-service-item">
              <strong>Limitation of Liability:</strong> In no event shall
              CollegeNav be liable for any indirect, incidental, special, or
              consequential damages arising out of or in connection with your
              use of our services.
            </li>
          </ul>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">8. Indemnification</h2>
          <p className="terms-of-service-text">
            You agree to indemnify and hold harmless CollegeNav, its affiliates,
            and their respective officers, directors, employees, and agents from
            any claims, damages, losses, liabilities, and expenses arising out
            of or related to your use of our services or violation of these
            terms.
          </p>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">9. Governing Law</h2>
          <p className="terms-of-service-text">
            These Terms of Service shall be governed by and construed in
            accordance with the laws of the state in which CollegeNav operates,
            without regard to its conflict of law principles.
          </p>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">10. Changes to Terms of Service</h2>
          <p className="terms-of-service-text">
            We reserve the right to modify these Terms of Service at any time.
            We will notify you of any changes by posting the new terms on our
            website. Your continued use of our services after any changes
            indicates your acceptance of the updated Terms of Service.
          </p>
        </section>

        <section className="terms-of-service-section">
          <h2 className="terms-of-service-heading">11. Contact Us</h2>
          <p className="terms-of-service-text">
            If you have any questions or concerns about these Terms of Service,
            please contact us at{" "}
            <a
              href="mailto:collegenav.info@gmail.com"
              className="terms-of-service-email"
            >
              collegenav.info@gmail.com
            </a>
            .
          </p>
        </section>
      </div>
      </div>
    </>
  );
};

export default TermsOfService;
