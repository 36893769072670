import React, { useState } from "react";
import { database } from "../../firebase/firebase";
import { ref, set, push } from "firebase/database";
import "../../css/components/comments/CommentForm.css";
import {Filter} from "bad-words";

const filter = new Filter();

const CommentForm = ({ parentCommentId, onReplySubmit, path }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (filter.isProfane(name) || filter.isProfane(email) || filter.isProfane(comment)) {
      setErrorMessage("Your submission contains inappropriate language. Please revise and try again.");
      return;
    }

    setErrorMessage("");

    if (parentCommentId) {
      onReplySubmit(parentCommentId, name, email, comment);
    } else {
      const newCommentKey = push(ref(database, `comments/${path}`)).key;
      set(ref(database, `comments/${path}/${newCommentKey}`), {
        name,
        email,
        comment,
        timestamp: new Date().toISOString(),
      });
    }

    setName("");
    setEmail("");
    setComment("");
  };

  const handleNameChange = (e) => {
    const value = e.target.value;
    setName(value);
    if (filter.isProfane(value)) {
      e.target.setCustomValidity("Please remove any inappropriate language.");
    } else {
      e.target.setCustomValidity("");
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (filter.isProfane(value)) {
      e.target.setCustomValidity("Please remove any inappropriate language.");
    } else {
      e.target.setCustomValidity("");
    }
  };

  const handleCommentChange = (e) => {
    const value = e.target.value;
    setComment(value);
    if (filter.isProfane(value)) {
      e.target.setCustomValidity("Please remove any inappropriate language.");
    } else {
      e.target.setCustomValidity("");
    }
  };

  return (

    <div id = "comment-body">
    <div id="respond" className="comment-respond">
      <h3 id="reply-title" className="comment-reply-title">
        {parentCommentId ? "Leave a Reply" : "Leave a Comment"}
      </h3>

      <p className="comment-instructions">
        Your email address will not be published. Required fields are marked <span className="required">*</span>
      </p>


      <form onSubmit={handleSubmit} className="comment-form inputs--alt">

        <div className="form-row">
          <div className="form-group half-width">
            <label htmlFor="author">Name <span className="required">*</span></label>
            <input
              id="author"
              name="author"
              type="text"
              placeholder="Your name"
              aria-required="true"
              value={name}
              onChange={(e) => [setName(e.target.value), handleNameChange(e)]}
            />
          </div>
          <div className="form-group half-width">
            <label htmlFor="email">Email <span className="required">*</span></label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="your@email.com"
              aria-required="true"
              value={email}
              onChange={(e) => [setEmail(e.target.value), handleEmailChange(e)]}
            />
          </div>
        </div>

            <div class = "test-for-comment-part">
              <div className="form-group">
                <label htmlFor="comment">Comment</label>
                <textarea
                  id="comment"
                  name="comment"
                  placeholder="Your comment..."
                  value={comment}
                  onChange={(e) => [setComment(e.target.value), handleCommentChange(e)]}
                ></textarea>
              </div>
            </div>



              


        <p className="form-submit">
          <button name="submit" type="submit" id="submit" className="submit">
            Post Comment
          </button>
        </p>
        {errorMessage && (
          <p className="error-message">{errorMessage}</p>
        )}
      </form>
    </div>
    </div>
  );
};

export default CommentForm;
