import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * useScrollToTop Hook
 * Scrolls the window to the top whenever the pathname changes.
 */
const useScrollToTop = () => {
  const { pathname } = useLocation(); // Get the current pathname

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when pathname changes
  }, [pathname]);
};

export default useScrollToTop;
