import React from "react";
import { Link } from "react-router-dom";
import "../../css/MainPage/TableOfContentPages.css";

import headerpic from "../../assets/header-images/headerpic.png";
import afford1 from "../../assets/header-images/afford1.jpg";

import understanding from "../../assets/header-images/understanding.jpg";
import scholarship from "../../assets/header-images/scholarship.jpg";
import employment from "../../assets/header-images/employment.jpg";

const AffordCollege = () => {
  return (
    <div className="general-page">
      <div id="afford-TOC-face">
        <img id="afford-TOC-face-pic" src={afford1} />
        <div id="TOC-face-text-container">
          <div id="TOC-text-new-face">
            <p className="TOC-header-text">Afford College</p>
          </div>
        </div>
      </div>

      <div className="below-header-container">

        <div id="base-page-introduction-container">
          <div id="base-page-introduction-text-container">
            <div id="base-page-introduction-title-container">
              <h2 id="base-page-intro-title">Quick Guide</h2>
              
            </div>
            <p id="base-page-introduction-text-main">
              Navigating the financial aspects of college can be challenging,
              but with the right resources and information, you can make it more
              manageable. This section provides comprehensive details on
              financial aid, scholarships, and work opportunities to help you
              afford your college education. Explore the different types of
              support available and learn how to make the most of these
              opportunities. Below is an overview of the different financial
              support options available. Click on each category to explore
              detailed information and learn how you can benefit from these
              resources.
            </p>
          </div>
        </div>

        <div className="general-toc-container">
          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={understanding}
                alt="Understanding Financial Aid"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 1</h3>
              <h2 className="traffic-title">Understanding Financial Aid</h2>
              <p className="traffic-description">
                Explore various types of financial aid, including grants,
                scholarships, loans, and work-study programs. Learn how to apply
                for financial aid, manage student loans, and find resources for
                undocumented students.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing esdf</span>
              <div className="service-item">
                <Link to="/afford-college/financial-aid">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={scholarship}
                alt="Scholarships"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 2</h3>
              <h2 className="traffic-title">Scholarships</h2>
              <p className="traffic-description">
                Discover scholarship opportunities, application tips, and guides
                to help you win scholarships. Learn how to create compelling
                applications, secure strong recommendation letters, and prepare
                for scholarship interviews.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing elr. </span>
              <div className="service-item">
                <Link to="/afford-college/scholarship">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>

          <div className="traffic-box-container">
            <div className="traffic-box-image-container">
              <img
                src={employment}
                alt="Student Employment Opportunities"
                className="traffic-box-image"
              />
            </div>
            <div className="traffic-box">
              <h3 class = "traffic-box-part-number">Part 3</h3>
              <h2 className="traffic-title">Student Employment Opportunities</h2>
              <p className="traffic-description">
                Find various work opportunities and side hustles to earn money
                while studying. Explore options like research positions,
                work-study programs, side hustles, and more.
              </p>
              <span class = "hidden-box-text"> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed</span>
              <div className="service-item">
                <Link to="/afford-college/student-work-opportunities">
                  <button className="service-item-button">Begin</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffordCollege;
