import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../css/Tools/GPACalculator.css";
import headerpic from "../../assets/header-images/headerpic.png";
import SubNavContainer from "../../components/SubNavContainer"; // Importing SubNavContainer

import gpas from "../../assets/header-images/gpa.jpg";

/**
 * GPACalculator Component
 * Allows users to calculate GPA based on course details.
 */
function GPACalculator() {
  // Initial courses state
  const initialCourses = [
    { name: "Math", grade: "A", units: "4" },
    { name: "English", grade: "B-", units: "5" },
    { name: "Psychology", grade: "B+", units: "3" },
    { name: "", grade: "", units: "" }, // The only row we want to show on 480px
  ];

  const [courses, setCourses] = useState(initialCourses); // Courses state
  const [gpa, setGpa] = useState(0.0); // GPA state
  const [totalCredits, setTotalCredits] = useState(0); // Total credits state
  const [error, setError] = useState(""); // Error message state
  const [showGradeTable, setShowGradeTable] = useState(false); // Grade table visibility state

  // Handle course input change
  const handleCourseChange = (index, event) => {
    const newCourses = [...courses];
    newCourses[index][event.target.name] = event.target.value;
    setCourses(newCourses);
  };

  // Add new course
  const addCourse = () => {
    setCourses([
      ...courses,
      { name: "", grade: "", units: "" },
    ]);
  };

  // Remove course
  const removeCourse = (index) => {
    const newCourses = [...courses];
    newCourses.splice(index, 1);
    setCourses(newCourses);
  };

  // Convert percentage grade to letter grade
  const convertGradeToLetter = (grade) => {
    if (grade >= 97) return "A+";
    if (grade >= 93) return "A";
    if (grade >= 90) return "A-";
    if (grade >= 87) return "B+";
    if (grade >= 83) return "B";
    if (grade >= 80) return "B-";
    if (grade >= 77) return "C+";
    if (grade >= 73) return "C";
    if (grade >= 70) return "C-";
    if (grade >= 67) return "D+";
    if (grade >= 65) return "D";
    if (grade >= 60) return "D-";
    return "F";
  };

  // Get GPA points from grade
  const getGpaPoints = (grade) => {
    const letterGrades = {
      "A+": 4.0,
      A: 4.0,
      "A-": 3.7,
      "B+": 3.3,
      B: 3.0,
      "B-": 2.7,
      "C+": 2.3,
      C: 2.0,
      "C-": 1.7,
      "D+": 1.3,
      D: 1.0,
      "D-": 0.7,
      F: 0.0,
    };

    if (typeof grade === "string") {
      return letterGrades[grade.toUpperCase()] || 0.0;
    } else if (typeof grade === "number") {
      const letterGrade = convertGradeToLetter(grade);
      return letterGrades[letterGrade] || 0.0;
    }

    return 0.0;
  };

  // Calculate GPA
  const calculateGPA = () => {
    let totalPoints = 0;
    let totalUnits = 0;
    let isValid = true;

    // Validate inputs
    for (const course of courses) {
      const grade = course.grade.trim();
      const units = course.units.trim();

      if ((grade && !units) || (!grade && units)) {
        setError("Both grade and units are required if one is filled.");
        isValid = false;
        break;
      }
    }

    if (!isValid) return;

    setError("");
    courses.forEach((course) => {
      const grade = course.grade.trim();
      const units = parseFloat(course.units.trim());
      if (grade && !isNaN(units)) {
        totalPoints += getGpaPoints(isNaN(grade) ? grade : parseFloat(grade)) * units;
        totalUnits += units;
      }
    });

    const gpa = totalPoints / totalUnits;
    setGpa(totalUnits > 0 ? gpa.toFixed(2) : 0.0);
    setTotalCredits(totalUnits);
  };

  // Toggle grade conversion table visibility
  const toggleGradeTable = () => {
    setShowGradeTable(!showGradeTable);
  };

  return (
    <div id="gpa-page-background">
      <div id="Ch-face">
        <img id="header-image" src={gpas} />
        <div id="TOC-face-text-container">
          <div id="TOC-face-text">
            <p className="TOC-header-text"> GPA Calculator </p>
          </div>
        </div>
      </div>

      <div className="gpa-calculator">
        <h1 className="gpa-calculator-title">GPA Calculator</h1>
        <p className="gpa-calculator-intro">
          Welcome to the GPA Calculator! Use this tool to calculate your GPA by
          entering your course details below. You can also add additional
          courses if needed. Click 'Calculate GPA' to see your results.
        </p>
        <div className="course-list">
          <div className="course-item">
            <div className="input-group">
              <label className="input-label"> <span class = "hide-on-480"> Course Name </span></label>
            </div>
            <div className="input-group">
              <label className="input-label"><span class = "hide-on-480"> Grade </span></label>
            </div>
            <div className="input-group">
              <label className="input-label"><span class = "hide-on-480"> Units </span></label>
            </div>
            <div className="input-group">
              <label className="input-label">
                <span className="hide-on-480">Remove</span>
              </label>
            </div>
          </div>
          {courses.map((course, index) => (
            <div
              key={index}
              className={`course-item ${index < 3 ? "hide-on-480" : ""}`}
            >
              <div className="input-group">
                <input
                  className="course-name input"
                  type="text"
                  name="name"
                  placeholder="Course Name (optional)"
                  value={course.name}
                  onChange={(e) => handleCourseChange(index, e)} // Update course name
                />
              </div>
              <div className="input-group">
                <input
                  className="course-grade input"
                  type="text"
                  name="grade"
                  placeholder="Grade (letter or %)"
                  value={course.grade}
                  onChange={(e) => handleCourseChange(index, e)} // Update course grade
                />
              </div>
              <div className="input-group">
                <input
                  className="course-units input"
                  type="number"
                  name="units"
                  placeholder="Units"
                  value={course.units}
                  onChange={(e) => handleCourseChange(index, e)} // Update course units
                />
              </div>
              <div className="input-group">
                <button
                  className="remove-course-button"
                  onClick={() => removeCourse(index)} // Remove course
                >
                  X
                </button>
              </div>
            </div>
          ))}
        </div>
        {error && <div className="error-message">{error}</div>}{" "}
        {/* Display error message */}
        <button onClick={addCourse} className="add-course-button">
          Add Course
        </button>
        <button onClick={calculateGPA} className="calculate-gpa-button">
          Calculate GPA
        </button>
        <button
          onClick={toggleGradeTable}
          className="toggle-grade-table-button"
        >
          Grade Conversion Table
        </button>
        {showGradeTable && (
          <div className="grade-table">
            <h3 className="grade-table-title">Grade Conversion Table</h3>
            <table className="grade-table-content">
              <thead>
                <tr className="grade-table-row">
                  <th className="grade-table-header">Percentage</th>
                  <th className="grade-table-header">Letter Grade</th>
                  <th className="grade-table-header">GPA</th>
                </tr>
              </thead>
              <tbody>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">97-100</td>
                  <td className="grade-table-cell">A+</td>
                  <td className="grade-table-cell">4.0</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">93-96</td>
                  <td className="grade-table-cell">A</td>
                  <td className="grade-table-cell">4.0</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">90-92</td>
                  <td className="grade-table-cell">A-</td>
                  <td className="grade-table-cell">3.7</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">87-89</td>
                  <td className="grade-table-cell">B+</td>
                  <td className="grade-table-cell">3.3</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">83-86</td>
                  <td className="grade-table-cell">B</td>
                  <td className="grade-table-cell">3.0</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">80-82</td>
                  <td className="grade-table-cell">B-</td>
                  <td className="grade-table-cell">2.7</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">77-79</td>
                  <td className="grade-table-cell">C+</td>
                  <td className="grade-table-cell">2.3</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">73-76</td>
                  <td className="grade-table-cell">C</td>
                  <td className="grade-table-cell">2.0</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">70-72</td>
                  <td className="grade-table-cell">C-</td>
                  <td className="grade-table-cell">1.7</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">67-69</td>
                  <td className="grade-table-cell">D+</td>
                  <td className="grade-table-cell">1.3</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">65-66</td>
                  <td className="grade-table-cell">D</td>
                  <td className="grade-table-cell">1.0</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">60-64</td>
                  <td className="grade-table-cell">D-</td>
                  <td className="grade-table-cell">0.7</td>
                </tr>
                <tr className="grade-table-row">
                  <td className="grade-table-cell">Below 60</td>
                  <td className="grade-table-cell">F</td>
                  <td className="grade-table-cell">0.0</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <div className="gpa-result">
          <h2 className="gpa-result-gpa">GPA: {gpa}</h2>
          <h2 className="gpa-result-credits">Total Credits: {totalCredits}</h2>
        </div>
      </div>
    </div>
  );
}

export default GPACalculator;
